<template>
  <create-edit-obj
      ref="createEditObj"
      :prefix="prefix"
      :id="id"
      :object-api="objectApi"
      :object-name="tabName"
      :dataListPartner="dataList"
      @Refreshes="Refreshes"
      @save="save"
      :rtnURL="rtnURL"
      type="active"
    ></create-edit-obj>
</template>

<script>
import createEditObj from "./create-edit-obj.vue";
export default {
    components:{createEditObj},
    data(){
        return {
            dataList:[],
            tabName:'',
            objectApi:'',
            id:'',
            prefix:'',
            objId:'',
            rtnURL:''
        }
    },
    created(){
        let params=this.$route.query
        this.tabName=params.tabName
        this.objectApi=params.objectApi
        this.id=params.id
        this.prefix=params.prefix
        this.objId=params.objId
        this.rtnURL=params.rtnURL
        // 
    },
    mounted(){
        
        this.edit()
    },
    methods:{
        Refreshes(){
            // 
        },
        save(){
            // 
        },
        edit(){
            this.$refs.createEditObj.objId = this.objId;
                this.$refs.createEditObj.edit();
        }
    }

}
</script>

<style>

</style>