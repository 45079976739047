import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

// 提交待审批
export function submitApproval(args) {
    return axios.post('approval/submitApproval', args)
}

// 获取资产BOM列表-高含宇
export function getAssetBOMList(args) {
    return axios.post('ManageAssetBOM/getAssetBOMList', args)
}
// 复制资产BOM接口-高含宇
export function copyAssetBOM(args) {
    return axios.post('ManageAssetBOM/copyAssetBOM', args)
}
// 删除资产BOM接口-高含宇
export function deleteAssetBOM(args) {
    return axios.post('ManageAssetBOM/deleteAssetBOM', args)
}
// 转移资产BOM所有人接口-高含宇
export function transferAssetBOMOwner(args) {
    return axios.post('ManageAssetBOM/transferAssetBOMOwner', args)
}
// 获取所有非当前资产子资产的资产列表接口-高含宇
export function findAssetList(args) {
    return axios.post('ManageAssetBOM/findAssetList', args)
}
// 迁移资产BOM接口-高含宇
export function transferAssetBOM(args) {
    return axios.post('ManageAssetBOM/transferAssetBOM', args)
}
//获取订单产品列表-高含宇
export function getOrderItem(args) {
    return axios.post('AssetBOMProduct/getOrderItem', args)
}
//获取产品选项列表-高含宇
export function getProductandPoption(args) {
    return axios.post('AssetBOMProduct/getProductandPoption', args)
}
//产品转资产BOM-高含宇
export function productToBom(args) {
    return axios.post('AssetBOMProduct/productToBom', args)
}
//订单转资产BOM
export function orderItemToBOM(args) {
    return axios.post('AssetBOMProduct/orderItemToBOM', args)
}

// 打印标签
// 判断对象启用了哪些码的规则 - 赵继绪
export function getEnableRules(args) {
    return axios.post('assetCode/getEnableRules', args)
}
// 打印二维码、条形码-赵继绪
export function printCode(args) {
    return axios.post('assetCode/printCode', args)
}
// 打印二维码、条形码-赵继绪
export function checkPrintCode(args) {
    return axios.post('assetCode/checkData', args)
}

// 获取对象记录权限
export function getPermissionById(args) {
    return axios.post('objectdetail/getPermissionById', args)
}

//获取对象权限
export function getObjectPermission(args) {
    return axios.post('objectdetail/getObjectPermission', args)
}

// 删除记录
export function deleteBom(args) {
    return axios.post('objectdetail/delete', args)
}

// 删除记录
export function del(data) {
    return axios.post('/objectdetail/delete', data)
}

// 获取资产BOM管理权限 --- 赵晨宇
export function isOpenAsset(args) {
    return axios.post('ManageAssetBOM/getManageAssetBOMPermission', args)
}

//产品转资产BOM判断权限
export function productToBomPerm(args) {
    return axios.post('AssetBOMProduct/productToBomPerm', args)
}

//文件-附件添加Dropbox类型文件
export function getSaveDropbox(data) {
    return axios.post('/file/saveDropbox', data)
}
//联系人满意度评价相关列表返回评价
export function getContactSatisfactionEvaluation(data) {
    return axios.post('/ServiceEvaluation/getContactSatisfactionEvaluation', data)
}

//客户满意度评价相关列表返回评价
export function getAccountSatisfactionEvaluation(data) {
    return axios.post('/ServiceEvaluation/getAccountSatisfactionEvaluation', data)
}
//保存伙伴云用户
export function getPartnerSavePartnerUser(data) {
    return axios.post('/partner/savePartnerUser', data)
}
// 看板视图展示
export function GetViewKanbanDisplay(data) {
    return axios.post('/viewKanban/display', data)
}
// 看板视图设置
export function GetViewKanbanEdit(data) {
    return axios.post('/viewKanban/edit', data)
}
// 保存看板视图设置
export function GetViewKanbanSave(data) {
    return axios.post('/viewKanban/save', data)
}

// 获取发票列表
export function showInvoices(data) {
    return axios.post('/transaction/showInvoices', data)
}
// 获取用户信息
export function findDetail(data) {
    return axios.post('/stock/findDetail', data)
}
// 根据报表id查询图表数据
export function getChartDataByReportForObjectDetail(data) {
    return axios.post('/chart/getChartDataByReportForObjectDetail', data)
}
// 新增股权关系
export function save(data) {
    return axios.post('/stock/save', data)
}
// 轮班-获取论班记录-高含宇
export function getShiftRecord(args) {
    return axios.post('shift/getShiftRecord', args)
}
// 轮班-获取全部服务区域-高含宇
export function getAllAreas(args) {
    return axios.post('shift/getAllServiceTerritory', args)
}
// 获取图表列表
export function getViewChartList(data) {
    return axios.post('/viewChart/list', data)
}

// 根据对象id获取图表中的仪表板列表
export function getDashboardListByObjId(data) {
    return axios.post('/dashboard/getDashboardListByObjId', data)
}

// 保存视图图表
export function getViewChartSave(data) {
    return axios.post('/viewChart/save', data)
}
// 删除发布列表人员
export function deleteShareRule(data) {
    return axios.post("/share/deleteShareRule", data);
}
// 获取共享规则列表
export function getShareRuleList(data) {
    return axios.post("/share/getShareRuleList", data);
}
// 保存公告发布范围
export function saveShareRule(data) {
    return axios.post("/share/saveShareRule", data);
}

// 搜索用户组
export function searchGroup(data) {
    return axios.post("/share/searchGroup", data);
}
//获取相关列表对象信息
export function GetRelationList(data) {
    return axios.post('/objectdetail/getRelationList', data)
}
//文件-附件添加
export function Bind(data) {
    return axios.post('/file/bind', data)
}
//影响业务机会
export function GetEffectOpp(data) {
    return axios.post('/campaign/getEffectOpp', data)
}
//获取相关列表数据及按钮和字段信息
export function GetDetailRelatedItems(data) {
    return axios.post('/objectdetail/getDetailRelatedItems', data)
}

// 新增图表页
export function getViewChartNew(data) {
    return axios.post('/viewChart/new', data)
}
// 获取视图列表
export function GetViewList(data) {
    return axios.post('/view/list/getViewList', data)
}
// 图表编辑页
export function getViewChartEdit(data) {
    return axios.post('/viewChart/edit', data)
}

// 图表展示数据接口
export function getViewChartDisplay(data) {
    return axios.post('/viewChart/display', data)
}

// 删除图表
export function deleteViewChart(data) {
    return axios.post('/viewChart/delete', data)
}
// 判断全部订单是否为同一个客户
export function isSameCustomer(data) {
    return axios.post('/generateInvoiceNew/isSameCustomer', data)
}
// 查询订单币种
export function findOrderCurrency(data) {
    return axios.post('/generateInvoiceNew/findOrderCurrency', data)
}
// 修改订单开票状态
export function updateOrderStatus(data) {
    return axios.post('/generateInvoiceNew/updateOrderStatus', data)
}

// 获取视图列表表头
export function getViewListHeader(data) {
    return axios.post('/view/list/getViewListHeader', data)
}

// 获取视图列表记录
export function getViewListData(data) {
    return axios.post('/view/list/getViewListAjax', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

// 添加市场活动成员
export function addMember(data) {
    return axios.post('/campaign/addMember', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 获取视图列表页面信息
export function getViewListRecently(data) {
    return axios.post('/view/list/getViewListRecently', data)
}

// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}

// 获取新建事件相关项
export function getDialigItem(data) {
    return axios.post('activity/getQuickActivityPage', data)
}

// 获取查找数据
export function getLookupData(data) {
    return axios.post('/lookup/getLookupData', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}

// 获取对象详细信息
export function getDetail(data) {
    return axios.post('/objectdetail/getDetail', data)
}

// 获取产品关联的税
export function listRelatedTax(data) {
    return axios.post('/tax/listRelatedTax', data)
}

// 主从录入获取税种信息
export function ListTax(data) {
    return axios.post('/tax/listTax', data)
}

// 查询所选产品关联的价格手册条目价格
export function relatedProductPrice(data) {
    return axios.post('/master/relatedProductPrice', data)
}

// 查询动态布局规则
export function getDynamicLayoutListDetail(data) {
    return axios.post('/objectdetail/getDynamicLayoutListDetail', data)
}

// 添加或更新对象记录
export function saveObjectInfo(data) {
    return axios.post('/objectInfo/save', data)
}

// 主从录入保存
export function saveMaster(data) {
    return axios.post('/master/saveMaster', data)
}

// 查询主录入数据
export function masterRecordDetailInfo(data) {
    return axios.post('/master/masterRecordDetailInfo', data)
}

// 主从录入表头
export function getMasterInfo(data) {
    return axios.post('/master/getMasterInfo', data)
}

// 查找带值
export function getLookupRelatedFieldValue(data) {
    return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

//新建事件前检查重复
export function checkEventConflict(data) {
    return axios.post('/objectInfo/checkEventConflict', data)
}

//产品详情页上价格手册条目查询
export function queryProductPricebookItems(data) {
    return axios.post('/pricebook/queryProductPricebookItems', data)
}

//是否隐藏添加价格手册按钮
export function hideAddPricebookButton(data) {
    return axios.post('pricebook/hideAddPricebookButton', data)
}

// 自定义按钮
export function openCall(data) {
    return axios.post('/openCall/common', data)
}

//富文本图片上传
export function fileCKUploadImg(data) {
    return axios.post('/file/CKUploadImg', data)
}

//价格手册
export function getEnablePriceBook(data) {
    return axios.get('/transaction/getEnablePriceBook', data)
}
//根据价格手册搜索产品
export function searchProducts(data) {
    return axios.post('/master/searchProducts', data)
}
// 获取视图详细信息
export function getFieldList(data) {
    return axios.post('/field/getFieldList', data)
}
export function searchProductsQ(data) {
    return axios.post('/quotePdt/searchProducts', data)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 获取币种信息
export function GetCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
}
// 作为草稿编辑
export function asDraftEdit(data) {
    return axios.post("/knowledgeArticleOperate/asDraftEdit", data);
}