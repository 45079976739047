import { render, staticRenderFns } from "./create-edit-obj.vue?vue&type=template&id=122e8dad&scoped=true&"
import script from "./create-edit-obj.vue?vue&type=script&lang=js&"
export * from "./create-edit-obj.vue?vue&type=script&lang=js&"
import style0 from "./create-edit-obj.vue?vue&type=style&index=0&id=122e8dad&prod&lang=scss&"
import style1 from "./create-edit-obj.vue?vue&type=style&index=1&id=122e8dad&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "122e8dad",
  null
  
)

export default component.exports