<!--
 * @Author: 张亚欣
 * @Date: 2021-07-05 16:14:38
 * @LastEditTime: 2022-05-16 15:07:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: 新建、编辑标准对象
-->
<template>
  <div class="create-edit-obj">
    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <!-- 主从录入弹窗组件 -->
    <!-- style="height: 91%"：不能直接设置高度，样式有问题 -->
    <el-dialog
      :title="$t('label.quickbooks.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :close-on-click-modal="false"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="checked"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        :vendorid="vendorid"
        @setFieldReltaion="setFieldReltaion"
        @changeSelect="changeSlaveSelect"
      />
    </el-dialog>

    <!-- 选择记录类型弹窗 -->
    <el-dialog
      :title="recordTypeDialogAttr.title"
      :visible.sync="recordTypeDialogAttr.isShow"
      top="15%"
      :close-on-click-modal="false"
      :width="recordTypeDialogAttr.dialogWidth"
      :modal-append-to-body="false"
      append-to-body
    >
      <div v-if="recordOptions.length > 1">
        <span>
          <!-- 选择记录类型 -->
          {{ $t("label_tabpage_selrecordtypez") }}：
        </span>
        <!-- 请选择 -->
        <el-select
          v-model="recordType"
          filterable
          :placeholder="$t('label_tabpage_pleaseSelectz')"
        >
          <el-option
            v-for="item in recordOptions"
            :key="item.val"
            :label="item.key"
            :value="item.val"
          >
          </el-option>
        </el-select>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="cancelRecordTypeDialog">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button size="mini" type="primary" @click="next">
          <!-- 下一步 -->
          {{ $t("label.nextstep") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 新增/修改记录 -->
    <!-- 全屏展示 -->
    <el-dialog
      :title="dialogAttr.title"
      :width="dialogAttr.dialogWidth"
      :visible.sync="dialogAttr.isShow"
      :close-on-click-modal="false"
      :before-close="cancel"
      :show-close="false"
      :fullscreen="true"
      top="3%"
      :modal-append-to-body="false"
      append-to-body
      custom-class="create-edit-dialog"
    >
      <!-- 布局切换按钮 -->
      <ul class="changeColumnBtnGroup">
        <li
          @click="changeColumns('twoColumns')"
          style="border-right: 1px solid #dedcda"
        >
          <svg class="icon setColumnsIcon" aria-hidden="true">
            <use
              :fill="detailMode === 'twoColumns' ? '#006dcc' : '#f5f5f5'"
              href="#icon-baocunbuju"
            ></use>
          </svg>
        </li>
        <li @click="changeColumns('responsiveLayout')">
          <svg
            v-if="detailMode === 'responsiveLayout'"
            class="icon setColumnsIcon autoColumnsIcon"
            aria-hidden="true"
          >
            <use href="#icon-autoColumns-active"></use>
          </svg>
          <svg
            v-else
            class="icon setColumnsIcon autoColumnsIcon"
            aria-hidden="true"
          >
            <use href="#icon-autoColumns"></use>
          </svg>
        </li>
      </ul>
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0',
        }"
        class="detailContentBox"
      >
        <div style="text-align: center" v-if="!showBaseForm">
          <i class="el-icon-loading" style="font-size: 30px"> </i>
        </div>
        <base-form
          ref="baseForm"
          class="baseForm"
          v-if="showBaseForm"
          :id="id"
          :layoutId="layoutId"
          :prefix="prefix"
          :special-objectapi="objectApi"
          :objectApi="objectApi"
          :form-attr="formAttr"
          :operation-type="dialogAttr.type"
          :columnNum="columnNum"
          :oneColumnWidth="oneColumnWidth"
          :twoColumnWidth="twoColumnWidth"
          :option-list="optionList"
          :status-icon="statusIcon"
          :autoRefresh="autoRefresh"
          :contactes="contactes"
          :isOrderPage="isOrderPage"
          @addFile="addFile"
          @changeCurrency="changeCurrency"
          @notZhucongfield="notZhucongfield"
          @changeSelect="changeSelect"
          @changeMR="changeMR"
          @control="control"
          @lookupValue="lookupValue"
          @handleAction="handleAction"
          @remoteMethod="remoteMethod"
          @newSearchableField="newSearchableField"
          @addFieldOption="addFieldOption"
          @fwbContent="fwbContent"
          @ruleProcessing="ruleProcessing"
          @changeCurrencyEdit="changeCurrencyEdit"
          :isClientPoolEditStatus="isClientPoolEditStatus"
          :relativeRecordId="relativeRecordId"
        >
        </base-form>

        <!-- 潜在客户和个案时展示分配 -->
        <div
          v-if="
            showBaseForm &&
            (objectApi === 'Lead' ||
              objectApi === 'cloudcclead' ||
              objectApi === 'cloudcccase')
          "
        >
          <p class="groupTitle">
            <!-- 可选 -->
            {{ $t("label.assign") }}
          </p>
          <el-checkbox v-model="validRule" style="margin-bottom: 20px">
            <!-- 使用有效的分配规则进行分配 -->
            {{ $t("label.assignment.layout.field.isassigned") }}
          </el-checkbox>
        </div>
        <!-- 从对象信息批量新增 -->
        <div
          v-show="isaddShow"
          :style="`overflow: auto;${
            isaddShow &&
            (this.objectApi === 'quote' ||
              this.objectApi === 'cloudccorder' ||
              this.objectApi === 'Invoices' ||
              this.objectApi === 'cloudccpurchaseorder') &&
            this.dataLists.length > 0
              ? 'min-height:' + 450 * this.dataLists.length - 1 + 'px;'
              : ''
          } `"
        >
          <div
            v-for="(ite, ind) in subordinateFields"
            :key="ind"
            :devid="ite.relatedId"
            :style="
              ind === subordinateFields.length - 1 && isZhuCong
                ? 'margin-bottom: 160px'
                : ''
            "
          >
            <!-- 从记录名称 -->
            <div class="relatedListName">
              {{ ite.relatedListName }}
              <!-- {{this.$store.state.channel.languge || this.$store.state.userInfoObj.currencyCode }} -->
              <span
                class="currencyBox"
                v-if="
                  ite.objectApi === 'quotedetail' ||
                  ite.objectApi === 'cloudccorderitem' ||
                  ite.objectApi === 'InvoicesItems' ||
                  ite.objectApi === 'purchaseorderitem'
                "
                >(<b>{{ $t("label.currency") }}</b
                >：{{ currencyVal }})</span
              >
              <div
                class="pricBox"
                v-if="
                  ite.objectApi === 'quotedetail' ||
                  ite.objectApi === 'cloudccorderitem' ||
                  ite.objectApi === 'InvoicesItems' ||
                  ite.objectApi === 'purchaseorderitem'
                "
              >
                <span style="margin-right: 10px">{{
                  $t("lable.opppdt.selPbk.pricebook")
                }}</span>
                <el-select
                  :disabled="isOrderPage"
                  v-model="pricSelect"
                  @change="pricChang"
                >
                  <el-option
                    v-for="item in pricList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 从记录表格 -->
            <div
              class="al"
              :ref="ite.objectApi"
              :style="{ overflow: isZhuCong ? '' : 'auto' }"
            >
              <div ref="allUl">
                <!-- 从记录表头 -->
                <ul class="listulFir" ref="firul">
                  <li
                    class="firstLi"
                    style="
                      line-height: 40px;
                      color: #2a2a2a;
                      justify-content: left;
                    "
                    :style="{ width: isZhuCong ? '6%' : '80px' }"
                  >
                    <!-- 操作 -->
                    &nbsp;&nbsp;{{ $t("label_partner_all_operate") }}
                  </li>
                  <li
                    class="secondLi"
                    ref="oli"
                    v-for="item in subordinateFields[ind].fieldList"
                    :key="item.id"
                    v-show="item.name !== 'ratetype'"
                    style="justify-content: left"
                    :style="{ width: zhucongWidth(item.name) }"
                  >
                    &nbsp;&nbsp;<span
                      style="color: red"
                      v-show="item.required === '1' && item.readonly === '0'"
                      >*</span
                    >
                    {{ item.label }}
                  </li>
                </ul>
                <!-- 从记录表格主体 -->
                <ul
                  ref="ulist"
                  class="listulSec"
                  :key="num"
                  v-for="(data, num) in dataLists[ind]"
                >
                  <li
                    class="firstLi"
                    :style="{ width: isZhuCong ? '6%' : '80px' }"
                  >
                    <span
                      @click="deleteUl(num, ind)"
                      style="line-height: 40px; cursor: pointer"
                    >
                      <!-- 删除 -->
                      {{ $t("component_setup_tabs_label_delete") }}
                    </span>
                  </li>
                  <li
                    class="secondLi"
                    ref="olisec"
                    style="position: relative"
                    :style="{ width: zhucongWidth(item.name) }"
                    v-for="item in subordinateFields[ind].fieldList"
                    v-show="item.name !== 'ratetype'"
                    :key="item.id"
                  >
                    <div
                      class="uploadFile"
                      @click="openUpload(ite.relatedApiname, item, num)"
                      v-if="
                        item.type === 'FL' &&
                        fileList[ite.relatedApiname][num] &&
                        fileList[ite.relatedApiname][num][item.name].length ===
                          0
                      "
                    >
                      <i class="el-icon-circle-plus-outline"></i>
                      <span>{{ $t("label.chat.file.upload.info") }}</span>
                    </div>
                    <div
                      class="fileContent"
                      v-if="
                        item.type === 'FL' &&
                        fileList[ite.relatedApiname][num] &&
                        fileList[ite.relatedApiname][num][item.name].length !==
                          0
                      "
                    >
                      <div
                        class="fileItem"
                        v-for="(fileItem, fileIndex) in fileList[
                          ite.relatedApiname
                        ][num][item.name]"
                        :key="fileIndex"
                      >
                        <div class="itemFir">
                          <div class="itemTitle">
                            <svg
                              style="width: 15px; height: 15px"
                              class="icon"
                              aria-hidden="true"
                            >
                              <use
                                :href="allIcon[fileItem.type] || defaultIcon"
                              ></use>
                            </svg>
                            <div class="name">{{ fileItem.name }}</div>
                            <div class="houzhui">.{{ fileItem.type }}</div>
                            <svg
                              @click="
                                deleteFile(
                                  ite.relatedApiname,
                                  item.name,
                                  fileIndex,
                                  num
                                )
                              "
                              class="delIcon"
                              aria-hidden="true"
                            >
                              <use href="#icon-system_delete_ic"></use>
                            </svg>
                          </div>
                        </div>
                        <div
                          class="itemSec"
                          v-if="
                            fileIndex ===
                              fileList[ite.relatedApiname][num][item.name]
                                .length -
                                1 &&
                            fileList[ite.relatedApiname][num][item.name]
                              .length !== Number(item.defaultvalue)
                          "
                          @click="openUpload(ite.relatedApiname, item, num)"
                        >
                          <i
                            style="width: 15px; height: 15px"
                            class="el-icon-circle-plus-outline"
                          ></i>
                          <span>{{ $t("label.chat.file.upload.info") }}</span>
                        </div>
                        <!-- <div class="el-icon-delete" style="cursor:pointer;margin:3px 10px 0px 0px;" @click="deleteFile(ite.relatedApiname,item.name,fileIndex,num)"></div> -->
                      </div>
                    </div>
                    <!-- 地理定位字段 -->
                    <el-button
                      class="icon big_map"
                      aria-hidden="true"
                      v-if="item.name === 'dldw'"
                      :disabled="item.readonly === '1' ? true : false"
                      type="primary"
                      @click="bigMapBtn(data, num, ind)"
                    >
                      <!-- 显示地图 -->
                      {{ $t("c24") }}
                    </el-button>
                    <EditableCell
                      v-if="
                        item.type !== 'FL' &&
                        item.name !== 'dldw' &&
                        item.name !== 'ratetype'
                      "
                      class="editableCell"
                      :showDiy="item.show"
                      :field="item.name"
                      :field-name="item.name"
                      :field-id="item.id"
                      :object-api="ite.objectApi"
                      :related-list-name="ite.relatedListName"
                      :input-type="item.fieldType"
                      :min="
                        item.valueInterval
                          ? item.valueInterval.min
                          : 0 - Math.pow(10, 18) + 1
                      "
                      :max="
                        item.valueInterval
                          ? item.valueInterval.max
                          : Math.pow(10, 18) - 1
                      "
                      :precision="
                        item.decimalPlaces ? Number(item.decimalPlaces) : 0
                      "
                      :schemefieldLength="
                        item.length ? Number(item.length) : 10
                      "
                      :change-events="item.changeEvents"
                      :line-index="num"
                      :required="item.required === '1' && item.readonly === '0'"
                      :subordinate-index="ind"
                      :value="
                        dataLists[ind].length > 0 &&
                        dataLists[ind][num] &&
                        dataLists[ind][num][item.name]
                          ? dataLists[ind][num][item.name]
                          : editableCellValue
                      "
                      :options="dataLists[ind][num][`${item.name}Options`]"
                      :productid="productid"
                      :taxValue="data"
                      :editFlg="{ type: dialogAttr.type, order: isOrderPage }"
                      :tipShow="tipShow[num]"
                      :addmanualData="searchmanualData"
                      :radioDataP="
                        dataLists[ind][num].radioData != undefined
                          ? dataLists[ind][num].radioData
                          : {}
                      "
                      @switchShow="switchShow"
                      :disabled="
                        item.disabled || (item.readonly === '1' ? true : false)
                      "
                      @editValueChange="editValueChange"
                      @lookupValue="relatedLookupValue"
                      @control="relatedControl"
                      @addmanualInit="addmanualInit"
                      @remoteSearch="
                        remoteSearch(item.name, item, num, ind, ite.object)
                      "
                      @remoteSearchPric="
                        remoteSearchPric(item.name, item, num, ind, ite.object)
                      "
                      @remoteSearchCount="
                        remoteSearchCount(item.name, item, num, ind, ite.object)
                      "
                      @editValueChangeTax="editValueChangeTax"
                      @editValueZhekouChange="editValueZhekouChange"
                      ref="EditableCell"
                      @selectPric="selectPric"
                      @remoteMethodCell="remoteMethodCell"
                    >
                    </EditableCell>
                  </li>
                </ul>
              </div>
            </div>
            <div style="padding: 16px 0">
              <el-button
                type="primary"
                size="mini"
                v-show="
                  !(objectApi === 'Invoices' && orderToInvoiceId.length !== 0)
                "
                @click="addRelatedData(ind)"
                :disabled="addRelatedDisabled"
              >
                {{ $t("label.batchadd.newbatchpage.button.add") }}
              </el-button>
            </div>
            <div style="padding: 45px 0" v-if="isZhuCong"></div>
          </div>
        </div>
        <!-- 主对象统计信息  -->
        <div
          v-if="
            isaddShow &&
            (this.objectApi === 'quote' ||
              this.objectApi === 'cloudccorder' ||
              this.objectApi === 'Invoices' ||
              this.objectApi === 'cloudccpurchaseorder') &&
            this.dataLists.length > 0
          "
          class="mainObjCount"
        >
          <div v-for="(item, index) in mainObjFields" :key="index">
            <MainObjCell
              :cellData="item"
              :tipMainShow="tipMainShow"
              :radioDataP="item.radioData != undefined ? item.radioData : {}"
              :taxListP="mainObjData"
              @tipMainShowMe="tipMainShowMe"
              @mainObjCount="changMainData"
            ></MainObjCell>
          </div>
        </div>
        <!-- 只在发票对象下新建回款时显示 -->
        <div v-show="isReceivable">
          <invoice
            ref="invoiceSpecial"
            :customId="customId"
            :tobepaidamount="invoiceInfo.tobepaidamount"
            @invoiceList="getInvoiceList"
          />
        </div>
      </div>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="cancel">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button
          size="mini"
          v-if="
            (dialogAttr.type === 'NEW' || dialogAttr.type === 'COPY') &&
            !isConsoleNewContact
          "
          v-preventReClick
          @click="save('saveAndRenew')"
          :loading="isSaveBtnLoadin"
        >
          <!-- 保存并新建 -->
          {{ $t("label.saveandnew") }}
        </el-button>
        <el-button
          size="mini"
          v-if="saveandapproval && !isConsoleNewContact"
          v-preventReClick
          :loading="isSaveBtnLoadin"
          @click="save('saveandapproval')"
        >
          <!-- 提交待审批 -->
          {{ $t("label.submitforapproval") }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          v-preventReClick
          :loading="isSaveBtnLoadin"
          @click="save"
        >
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 字段重复提示 -->
    <el-dialog
      :title="duplicateFieldDialogAttr.title"
      :visible.sync="duplicateFieldDialogAttr.isShow"
      top="15%"
      :close-on-click-modal="false"
      :modal="true"
      :width="duplicateFieldDialogAttr.dialogWidth"
      :modal-append-to-body="false"
      class="dialog"
      append-to-body
    >
      <p>
        与<a :href="duplicateHref">{{ duplicate_name }}</a
        >中{{ duplicate_labelname }}重复
      </p>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="duplicateFieldDialogAttr.isShow = false">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 添加文件 -->
    <add-files
      ref="addFile"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisible"
      @addSubmit="addWorkSubmit"
      @close="addWorkClose"
    >
    </add-files>
    <!-- 提交待审批弹窗 -->
    <nextApproval
      ref="nextApprovalref"
      @approvalSuccess="approvalSuccess"
    ></nextApproval>
    <div>
      <!--  '无法提交待审批'  -->
      <el-dialog
        :title="$t('message.approval.cannotsubmit')"
        :visible.sync="failedDialog"
        width="30%"
      >
        <div style="padding-bottom: 10px">
          <p>{{ errInfo }}</p>
          <p v-for="(item, index) in errSubmitList" :key="index">
            <span>{{ index + 1 }}. </span>
            {{ item }}
          </p>
        </div>
      </el-dialog>
    </div>

    <!-- 新建事件冲突提示弹窗 -->
    <el-dialog
      v-if="createEventDialogObj.isShowEventDialog"
      :visible="createEventDialogObj.isShowEventDialog"
      :title="$t('label_vue_create_edit_obj_create_event_dialog_title')"
      top="15%"
      width="30%"
      :show-close="false"
      append-to-body
    >
      <div class="content_wrapper">
        <div class="font_weight content_wrapper_title">
          {{ $t("label_vue_create_edit_obj_create_event_dialog_desc") }}
        </div>
        <div
          v-for="(item, index) in createEventDialogObj.dataArr"
          :key="index"
          class="overflow_ellipsis"
        >
          {{ item.begintime }} - {{ item.endtime }}
          {{
            $t("label_vue_create_edit_obj_create_event_dialog_desc_deal_field")
          }}
          {{ item.subject }}
        </div>
        <!-- 分页 -->
        <div class="page_up">
          <el-pagination
            layout="prev, pager, next"
            :page-size="createEventDialogObj.pagesize"
            :pager-count="5"
            :total="createEventDialogObj.totalCount"
            @prev-click="clickPage"
            @next-click="clickPage"
            @current-change="clickPage"
          >
          </el-pagination>
          <!-- 底部提示 -->
        </div>
        <div class="font_weight">
          {{ $t("label_vue_create_edit_obj_create_event_dialog_desc_alert") }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="mini"
          @click="continueCreateEvent"
          :loading="createEventDialogObj.checkEventdialogBtnLoading"
        >
          <!-- 继续创建 -->
          {{ $t("label.notification.event.overlapEvent.continue") }}
        </el-button>
        <el-button
          size="mini"
          @click="
            () => {
              createEventDialogObj.isShowEventDialog = false;
              isSaveBtnLoadin = false;
            }
          "
        >
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
      </span>
    </el-dialog>
    <add-files
      ref="addFileSecond"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisibleSecond"
      @addSubmit="addWorkSubmitSecond"
      @close="addWorkCloseSecond"
      :originNum="fileInfo.length"
    >
    </add-files>
    <el-dialog
      title=""
      :width="mapWidth"
      :top="mapTop"
      :visible.sync="bigMapDialog"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
      custom-class="big-view-box"
    >
      <el-button-group class="btn_map" v-if="mapTop === '6%'">
        <!-- 高德地图 谷歌地图-->
        <el-button
          :style="{
            background: isMap ? '#006DCC' : '',
            color: isMap ? '#fff' : '#000',
          }"
          @click="mapBtnChange('gd')"
          >{{ $t("label.gaode.map") }}</el-button
        >
        <el-button
          :style="{
            background: !isMap ? '#006DCC' : '',
            color: !isMap ? '#fff' : '#000',
          }"
          @click="mapBtnChange('gg')"
          >{{ $t("label.google.map") }}</el-button
        >
      </el-button-group>
      <!-- 高德/谷歌地图 -->
      <map-group
        ref="mapGroup"
        :mapkey="mapkey"
        :pointValue="clearItemArr.gdPointValue"
        :ggPointValue="clearItemArr.ggPointValue"
        :lineIndex="lineIndex"
        :isInfoMap="isInfoMap"
        :isMap="isMap"
        @chooseLocation="chooseLocation"
        @clearLocation="clearLocation"
        @bigClose="bigClose"
      ></map-group>
    </el-dialog>
    <!-- 业务机会/报价单/订单/发票/采购订单切换币种提示 -->
    <el-dialog
      :title="$t('label_popup_infoz')"
      width="40%"
      top="5%"
      :visible.sync="showIntro"
      append-to-body
      :close-on-click-modal="false"
      @close="cancelChange"
    >
      <div style="font-size: 16px; font-weight: bold; word-break: break-word">
        {{ $t("c146") }}
      </div>
      <span slot="footer">
        <el-button @click="cancelChange" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmChange" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 业务机会/报价单/订单/发票/采购订单切换价格手册提示 -->
    <el-dialog
      :title="$t('label_popup_infoz')"
      width="40%"
      top="5%"
      :visible.sync="pricIntro"
      append-to-body
    >
      <div style="font-size: 16px; font-weight: bold; word-break: break-word">
        {{ $t("c731") }}
        <!-- 更改已选取的价格手册，将删除全部现有的产品。是否更改为新的价格手册？ -->
      </div>
      <span slot="footer">
        <el-button @click="cancelPricChange" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmPricChange" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('lable.opppdt.selPbk.title')"
      :top="'10%'"
      :visible.sync="pricVisible"
      width="40%"
      :before-close="handlePricClose"
    >
      <div stytle="margin: 18px;">
        请为{{
          this.dialogAttr.title
        }}选择价格手册，您只能从单个价格手册添加产品。更改已选取的价格手册，将删除全部现有的产品。
      </div>
      <el-form
        :model="pricFrom"
        ref="pricFrom"
        label-width="200px"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('lable.opppdt.selPbk.title')"
          prop="pricSelect"
          required
        >
          <el-select v-model="pricFrom.pricSelect" :clearable="true">
            <el-option
              v-for="item in pricList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="
            () => {
              this.pricVisible = false;
            }
          "
        >
          取消
        </el-button>
        <el-button type="primary" size="mini" @click="pricNext">
          下一步
        </el-button>
      </span>
    </el-dialog>
    <!-- 添加价格手册 -->
    <ProductFilter
      ref="productFilter"
      :selectPrice="selectPrice"
      :addmanualForm="addmanualForm"
      :prefix="prefix"
      :realObjId="realObjId"
      :pricSelect="pricSelect"
      :currencyVal="currencyVal"
      :searchProductsId="searchProductsId"
      :optionst_one="optionst_one"
      @selectPric="selectPric"
      @remoteSearchs="remoteSearchs"
      @remoteMethodCell="remoteMethodCell"
    ></ProductFilter>
  </div>
</template>

<script>
import { AddFiles } from "@/components/index";
import * as createApi from "./api.js";
import INPUTTYPE from "@/config/enumCode/inputType";
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";
import Bus from "@/mixin/bus.js";
import VueCookies from "vue-cookies";
import { submitApproval } from "./api.js";
import { getPartnerSavePartnerUser, asDraftEdit } from "./api.js";
import detailSendCard from "@/utils/robotMessage.js";
export default {
  components: {
    BaseForm: () => import("@/components/Form/index"),
    EditableCell: () => import("@/components/TablePanel/EditableCell"),
    AddFiles,
    nextApproval: () => import("@/views/approval/components/approval-dialog"),
    MainObjCell: () =>
      import("@/views/commonObjects/components/mainObjCell.vue"),
    Invoice: () => import("@/views/commonObjects/components/invoices.vue"),
    MapGroup: () => import("@/components/Map/index.vue"),
    ProductFilter: () => import("./productFilter/productFilter.vue"),
  },
  props: {
    // 保存后跳转地址
    rtnURL: {
      type: String,
      default: "",
    },
    // 是否是列表页编辑(列表页编辑保存不跳详情)
    isTableEdit: Boolean,
    // 订单列表选中的数据
    checkedLists: {
      type: Array,
      default: () => [],
    },
    // 订单生成发票
    isOrderPage: {
      type: Boolean,
      default: false,
    },
    // 发票详情页  客户  合同  订单信息
    invoiceInfo: {
      type: Object,
      default: () => ({}),
    },
    // 订单详情页  客户  订单信息
    orderInfo: {
      type: Object,
      default: () => ({}),
    },
    // 发票详情关联的客户id
    customId: {
      type: String,
      default: "",
    },
    // 是否是发票对象下的新建回款
    isReceivable: {
      type: Boolean,
      default: false,
    },
    // 主页传过来的数据名称
    homeNweName: {
      type: String,
      default: "",
    },
    // 主页传过来的id
    homeNweId: {
      type: String,
      default: "",
    },
    // 主页传过来的潜在客户和联系人名称
    homeContactName: {
      type: String,
      default: "",
    },
    // 主页传过来的数据相关项类型
    homeNweObjid: {
      type: String,
      default: "",
    },
    // 主页新建名称相关项是否可以编辑
    standardHomePage: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 对象Api（路由传递）
    objectApi: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    objectName: {
      type: String,
      default: "",
    },
    bigTitle: {
      type: Array,
    },
    objectApies: {
      type: String,
      default: "",
    },
    relevantListFlag: {
      type: Boolean,
      default: true,
    },
    // 相关记录id
    relativeRecordId: {
      type: String,
      default: "",
    },
    relationFieldId: {
      type: String,
      default: "",
    },
    recordIds: {
      type: String,
      default: "",
    },
    dataListPartner: {
      type: Array,
    },
    // 公海池页面标记
    pageFlag: {
      type: String,
      default: "",
    },
    objectNames: {
      type: String,
    },
    isActive: {
      type: String,
    },
    // objid
    realObjId: {
      type: String,
      default: "",
    },
    // 当前选中的公海池对象
    currentClientPoolSelectObj: {
      type: Object,
      default: () => ({}),
    },
    // 公海池的编辑状态【在create-eit-obj组件】
    isClientPoolEditStatus: {
      type: Boolean,
      default: false,
    },
    // type为active时为快速新建
    type: {
      type: String,
      default: "",
    },
    // 企业微信联系人转联系人或潜在客户专用
    ewId: {
      type: String,
      default: "",
    },
    ewData: {
      type: Object,
      default: () => {},
    },
    // 控制台新建联系人隐藏按钮
    isConsoleNewContact: {
      type: Boolean,
      default: false,
    },
    // 企业微信联系人转联系人或潜在客户专用
    vistorId: {
      type: String,
      default: "",
    },
    phoneemail: {
      type: String,
      default: "",
    },
    orderNumber: {
      //甘特图新建-排序字段
      type: Number,
    },
    //订单发票新建客户地址时发票订单id
    orderInvoiceId: {
      type: String,
      default: "",
    },
    //订单发票新建客户地址时发票订单api
    orderInvoiceApi: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      clientPoolOwner: {}, // 新建公海池所有人回显信息
      pricRules: [],
      searchProductsId: "",
      pricOldSelect: "",
      pricIntro: false,
      //产品弹框数据
      addmanualData: [],
      //查找搜索组件数据
      searchmanualData: [],
      addmanualForm: {
        list: [
          {
            fieldId: "",
            op: "",
            val: [
              {
                value: "",
              },
            ],
            type: 1,
            id: 1,
            inputType: "input",
            optionst_two: [],
          },
        ],
      },
      defaultManual: "",
      pricFrom: {
        pricSelect: "",
      },
      rules: {
        pricSelect: [
          {
            required: true,
            message: this.$i18n.t("label.product.pricebooks.ples"),
            trigger: "blur",
          },
        ],
      },
      pricSelect: "",
      pricVisible: false,
      layoutId: "", // 动态布局id
      zhuCongLength: 0,
      nullElement: null,
      subordinateObjIndex: 0,
      showIntro: false,
      dataJson: [], //订单生成发票从录入数据备份
      tipMainShow: {
        zhekouFlg: false,
        taxFlg: false,
      },
      tipShow: [],
      invociesData: 0,
      timerS: null,
      invoiceList: [],
      productid: "", //主从录入当前产品id
      mainObjData: {
        radioData: {}, // 折扣
        taxCheckList: [], //选择税种
        adjustment: 0, //调整
      },
      checkedList: [],
      mainObjFields: [],
      defaultIcon: FILEICON.default, // 文件缺省图标
      allIcon: FILEICON, // 文件图标
      dialogAddVisibleSecond: false,
      createEventDialogObj: {
        isShowEventDialog: false, //是否显示dialog 冲突提示弹窗
        dataArr: [], //后台返回的错误信息
        page: 1,
        pagesize: 10,
        totalCount: 0, //总条数
        checkEventdialogBtnLoading: false, //按钮加载中状态
      },
      // id: '',
      detailMode: "",
      columnNum: 2,
      oneColumnWidth: "100%",
      twoColumnWidth: "50%",
      failedDialog: false, // 无法提交待审批弹窗状态
      errInfo: "", // 无法提交待审批错误提示
      errSubmitList: [], // 无法提交待审批具体错误提示
      possibilityList: [],
      duplicateHref: "",
      duplicate_name: "",
      duplicate_labelname: "",
      saveandapproval: false, // 展示保存并新建按钮
      isZhucongfield: false,
      flag: "",
      rowIndex: "",
      apiname: "",
      ind: 0,
      alldataists: [],
      recordId: "", //主从记录ID
      editableCellValue: { value: "" },
      ownerName: "",
      ownerId: "",
      clientOwnerName: "",
      dataLists: [],
      showSearchTable: false,
      fieldId: "",
      relevantObjId: "",
      relevantPrefix: "",
      checkList: [],
      duplicateFieldDialogAttr: {
        title: "提示",
        isShow: false,
        dialogWidth: "25%",
      },
      recordTypeDialogAttr: {
        title: this.$i18n.t("label_tabpage_selrecordtypez"), //选择记录类型
        isShow: false,
        dialogWidth: "25%",
      },
      dialogAttr: {
        title: "",
        type: "add",
        isShow: false,
        dialogWidth: "85%",
      },
      subordinateFields: [], // 从对象字段信息
      fieldlist: [], //表头信息
      isaddShow: true,
      transferClientOwnerDialog: false,
      stateone: "",
      validRule: true,
      state: "",
      selectNum: 0,
      checked: false,
      isSendMail: false,
      transferOwnerDialog: false,
      optionList: {},
      pictLoading: false,
      printFlag: false,
      lengths: 0,
      showKanban: false,
      statusIcon: false,
      autoRefresh: false,
      showBaseForm: false,
      recordType: "", //记录类型
      recordOptions: [],
      formAttr: null,
      showScreen: false, // 是否显示筛选器
      isLabelView: false, //显示标签
      isChart: false, //显示图表
      viewIsmodify: false, // 当前视图是否可编辑
      url: "",
      tabName: "",
      relatedObjectApis: [], //主从relatedObjectApis
      relatedApinames: [], //主从relatedApiname
      objId: this.$route.params.objId, // 对象id
      tableSearchParams: {},
      inputWidth: "300px",
      timer: "", //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      timeInterval: "",
      newViewId: "",
      labelArr: [],
      str: "",
      isQueryCount: false,
      viewInfo: {},
      strs: [],
      lookupValues: [], // 查找字段
      controlContainer: null, //依赖关系
      relatedLookupValues: [],
      relatedControlContainer: [], //依赖关系
      actionRequireds: [], // 动态布局必填字段
      actionReadonlys: [], // 动态布局只读字段
      actionShowFields: [], // 动态布局显示字段
      actionShowSections: [], // 动态布局显示部分
      actionConditions: [], // 动态布局数据
      ismodify: "",
      relatedItem: {}, // 事件类型的相关项
      currentUserInfo: {}, // 当前登录用户
      eventDate: {}, // 事件类型的日期
      calendarData: {}, // 日历新建填入的数据
      shiftData: {}, // 轮班新建填入的数据
      calendarUser: {}, // 日历新建传入的人员信息
      dialogBodyHeight: `${document.body.offsetHeight - 155}px`,
      addable: "",
      dialogAddVisible: false,
      newObjLevel: 0, // 新建查找字段标识
      fieldProp: "",
      userUrl: "",
      token: this.$CCDK.CCToken.getToken(),
      validRelatedObj: false, //从对象信息必填校验
      isRepeatTime: false, //是否有时间段重复
      relatedObjPrefix: "",
      contactes: "",
      actives: "",
      fwb: "",
      vendorid: "",
      attrIndex: -1, // 表单字段所在位置
      isSaveBtnLoadin: false, // 保存按钮loading
      konwelgeid: "", //知识文章复制id
      tempParams: {}, //临时params对象（新建事件时存储）
      tempFlag: {}, //临时flag  对象（新建事件时存储）
      nowTime: "",
      fileList: {}, //保存新增文件字段文件
      fileInfo: { relatedListName: "", fileldId: "", index: "", length: 0 },
      fileTemplate: "",
      attrTemp: [], // 临时存储表单字段
      caseId: "", //保存个案id
      caseName: "", //保存个案名称
      bigMapDialog: false, //地理定位地图弹窗
      mapkey: "", //地理定位地图key
      lineIndex: "", //地理定位当行下标
      clearItemArr: {}, //地理定位当行信息
      dldwName: "",
      showSearchBar: true,
      allInd: "",
      countryCode: this.$cookies.get("countryCode"),
      mapWidth: "80%",
      mapTop: "6%",
      isMap: true,
      isInfoMap: false,
      taxInitCheckList: [], //初始化的税种数据
      columnsChange: "",
      currencyVal: this.$store.state.userInfoObj.currencyCode,
      changeCurrencyVal: "",
      // backId: "", //保存后返回的id
      // backname: "", //保存后返回name
      currency: "",
      lookupValueExpression: null, //查找带值规则,
      //价格手册数组
      pricList: [],
      orderToInvoiceId: [],
      //当前选中税种的值
      taxList: [],
      filterFlag: true,
      selectPrice: "",
      //主从录入搜索关键字
      keywords: "",
      //获取数据条数
      pageNumber: 1,
      arrayLength: 1,
      optionst_one: [],
      condition: [], //筛选的数组
      addRelatedDisabled: false, //添加产品是否禁用
      customerAddress: {}, //判断当前客户下是否有默认地址
      isObjNewBuild: false, //判断是否在其他对象下新建客户地址
      addrArr: [], //存放地址字段子级数组
      newCreate: "", //判断当前新建是否为新建订单或发票页面再进行客户地址新建
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  created() {
    this.userUrl = this.$cookies.get("domainName");
  },
  mounted() {
    Bus.$on("windowResize", this.ebFn);
    // 响应式多列在修改宽度后需要动态的显示两列或多列
    this.$nextTick(() => {
      window.addEventListener("resize", this.windowResizeFn);
    });
  },
  methods: {
    ebFn(offsetHeight) {
      this.dialogBodyHeight = `${offsetHeight - 155}px`;
      this.setDetailMode();
    },
    windowResizeFn() {
      //监听浏览器窗口大小改变
      //浏览器变化执行动作
      if (
        this.$cookies.get(`${this.objectApi}DetailMode`) ==
          "responsiveLayout" ||
        this.columnsChange == "responsiveLayout"
      ) {
        this.calcDetailInfoWidth();
      }
    },
    //更改添加产品从录入宽度样式
    zhucongWidth(name) {
      if (this.isZhuCong) {
        if (
          name == "chanpin" ||
          name == "product2id" ||
          name == "product" ||
          name == "plan" ||
          name == "hxmms" ||
          name === "description"
        ) {
          return "20%";
        } else if (
          name == "xsjg" ||
          name == "rate" ||
          name == "unitprice" ||
          name == "xiaoji" ||
          name == "amount" ||
          name == "zongjia" ||
          name == "totalprice" ||
          name == "total" ||
          name == "totalamount"
        ) {
          return "10%";
        } else if (
          name == "shuliang" ||
          name == "quotedquantity" ||
          name == "qty" ||
          name == "quantity" ||
          name == "discountamount" ||
          name === "tax" ||
          name === "taxamount"
        ) {
          return "8%";
        } else {
          return "180px";
        }
      } else {
        return "180px";
      }
    },
    changeCurrencyEdit(result) {
      this.formAttr.forEach((item, index) => {
        if (item.apiname === "currency") {
          if (result.length > 0) {
            this.$refs.baseForm.formAttr[index].edit = false;
            this.$refs.baseForm.formAttr[index].showCurrencyIntro = false;
          } else {
            this.$refs.baseForm.formAttr[index].showCurrencyIntro = true;
          }
          return;
        } else {
          this.$refs.baseForm.formAttr[index].showCurrencyIntro = true;
        }
      });
    },
    cancelPricChange() {
      this.pricSelect = this.pricOldSelect;
      this.pricIntro = false;
    },
    confirmPricChange() {
      this.pricOldSelect = JSON.parse(JSON.stringify(this.pricSelect));
      let num = this.dataLists[this.subordinateObjIndex].length;
      for (let key = 0; key < num; key++) {
        this.deleteUl(key, this.subordinateObjIndex, true);
      }
      this.addRelatedData(this.subordinateObjIndex);
      this.$store.commit("setLanguge", this.changeCurrencyVal);
      this.pricIntro = false;
      this.searchmanualData = [];
    },
    pricChang(val) {
      this.pricIntro = true;
      this.pricSelect = val;
      this.$refs.productFilter.searchProducts();
    },
    cancelChange() {
      this.formAttr.forEach((item, index) => {
        if (item.apiname === "currency") {
          this.$set(this.formAttr[index], "value", this.currencyVal);
          this.$refs.baseForm.formData["currency"] = this.currencyVal;
        }
      });
      this.showIntro = false;
    },
    confirmChange() {
      let num = this.dataLists[this.subordinateObjIndex].length;
      for (let key = 0; key < num; key++) {
        this.deleteUl(key, this.subordinateObjIndex, true);
      }
      this.addRelatedData(this.subordinateObjIndex);
      this.$store.commit("setLanguge", this.changeCurrencyVal);
      this.currencyVal = this.changeCurrencyVal;
      this.showIntro = false;
    },
    changeCurrency(val) {
      this.changeCurrencyVal = val;
      if (this.subordinateFields.length > 0 && this.dialogAttr.type === "NEW") {
        this.subordinateFields.forEach((list, index) => {
          this.changeCurrencyIntro(this.objectApi, list.objectApi, index, val);
        });
      }
    },
    // 改变币种则进行提示
    changeCurrencyIntro(mianObj, subordinateObj, index, val) {
      // mianObj    Opportunity   quote         cloudccorder        Invoices        cloudccpurchaseorder
      // objectApi                quotedetail   cloudccorderitem    InvoicesItems   purchaseorderitem
      if (
        (mianObj === "quote" && subordinateObj === "quotedetail") ||
        (mianObj === "cloudccorder" && subordinateObj === "cloudccorderitem") ||
        (mianObj === "Invoices" && subordinateObj === "InvoicesItems") ||
        (mianObj === "cloudccpurchaseorder" &&
          subordinateObj === "purchaseorderitem")
      ) {
        if (this.dataLists[index].length > 0) {
          this.showIntro = true;
        } else {
          this.currencyVal = val;
        }
        this.subordinateObjIndex = index;
      }
    },
    // 获取订单的币种
    async getOrderCurrency() {
      if (this.isOrderPage && this.checkedLists.length === 0) {
        this.orderToInvoiceId.push(this.$route.params.id);
        let result = await createApi.findOrderCurrency({
          orderid: this.$route.params.id,
        });
        this.currencyVal = result.data[0].currency;
      } else if (this.isOrderPage && this.checkedLists.length !== 0) {
        this.orderToInvoiceId = [];
        this.orderToInvoiceId = this.checkedLists;
        this.currencyVal = this.orderInfo.currency;
      }
    },
    //选择价格手册下的产品
    selectPric(row, type) {
      if (this.objectApi === "cloudccorder") {
        //名称回显
        this.dataLists[this.dataLists.length - 1][this.idx].product2id.value =
          row.id;
        if (type == "search") {
          this.dataLists[this.dataLists.length - 1][
            this.idx
          ].product2idOptions = [{ label: row.name, value: row.id }];
        }
        //数量回显
        this.dataLists[this.dataLists.length - 1][
          this.idx
        ].quotedquantity.value =
          row.defaultquantity && row.defaultquantity !== "0"
            ? row.defaultquantity
            : 1.0;
      }
      if (this.objectApi === "Invoices") {
        this.dataLists[this.dataLists.length - 1][this.idx].plan.value = row.id;
        if (type == "search") {
          this.dataLists[this.dataLists.length - 1][this.idx].planOptions = [
            { label: row.name, value: row.id },
          ];
        }
        this.dataLists[this.dataLists.length - 1][this.idx].qty.value =
          row.defaultquantity && row.defaultquantity !== "0"
            ? row.defaultquantity
            : 1.0;
      }
      if (this.dataLists[this.dataLists.length - 1][this.idx].chanpin) {
        this.dataLists[this.dataLists.length - 1][this.idx].chanpin.value =
          row.id;
        if (type == "search") {
          this.dataLists[this.dataLists.length - 1][this.idx].chanpinOptions = [
            { label: row.name, value: row.id },
          ];
        }
        this.dataLists[this.dataLists.length - 1][this.idx].shuliang.value =
          row.defaultquantity && row.defaultquantity !== "0"
            ? row.defaultquantity
            : 1.0;
      }
      if (this.objectApi === "cloudccpurchaseorder") {
        if (type == "search") {
          this.dataLists[this.dataLists.length - 1][this.idx].productOptions = [
            { label: row.name, value: row.id },
          ];
        }
        this.dataLists[this.dataLists.length - 1][this.idx].product.value =
          row.id;
        this.dataLists[this.dataLists.length - 1][this.idx].quantity.value =
          row.defaultquantity && row.defaultquantity !== "0"
            ? row.defaultquantity
            : 1.0;
      }
      if (this.$refs.baseForm.formData.currency) {
        this.$store.commit("setLanguge", this.$refs.baseForm.formData.currency);
      }
      //初始化产品id
      this.subordinateFields[this.zhuCongLength].nullObject[
        this.idx
      ].productid = row.id;
      // 从录入产品价格手册关联
      this.linkTax(row.id);
      this.$refs.productFilter.addmanualVisible = false;
      // if (
      //   (this.objectApi === "cloudccorder" ||
      //     this.objectApi === "cloudccpurchaseorder") &&
      //   this.subordinateFields[this.zhuCongLength].nullObject[this.idx].name
      // ) {
      //   this.subordinateFields[this.zhuCongLength].nullObject[
      //     this.idx
      //   ].name.value = row.name;
      // }
      // 主从录入
    },
    //添加产品 (判断有没有价格手册)
    isManual() {
      this.manual = this.$i18n.t("label.quote.choseproduct");
      this.page = 1;
      this.condition = [];
      this.manuFun();
    },
    //业务机会产品添加产品 列表
    addmanualInit() {
      createApi.getFieldList({ objId: "201100000006339JMl6w" }).then((res) => {
        if (res.data) {
          this.optionst_one = res.data;
          // this.pricebooks.forEach((val) => {
          //   if (val.id === res.data.pricebookId) {
          //     this.defaultManual = val.name;
          //   }
          // });
        }
      });
    },
    remoteSearchs(item, num) {
      this.rowIndex = num;
      this.fieldId = item.fieldId;
      this.showSearchTable = true;
      // this.popArr.popName = "3";
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    pricNext() {
      this.$refs.pricFrom.validate((valid) => {
        if (valid) {
          this.pricSelect = this.pricFrom.pricSelect;
          this.pricVisible = false;
          this.$refs.productFilter.searchProducts();
          this.$refs.productFilter.addmanualVisible = true;
        } else {
          return false;
        }
      });
    },
    handlePricClose() {
      this.pricVisible = false;
    },
    // 价格手册下的搜索
    remoteSearchPric(value, item, num) {
      if (!this.isOrderPage) {
        this.idx = num;
        if (this.pricSelect === "") {
          this.pricVisible = true;
        } else {
          this.$refs.productFilter.searchProducts();
          this.$refs.productFilter.addmanualVisible = true;
        }
      }
    },
    //模糊搜索获取下标
    remoteSearchCount(value, item, num) {
      if (!this.isOrderPage) {
        this.idx = num;
      }
    },
    tipMainShowMe(flg, type) {
      if (type === 1) {
        this.tipMainShow.zhekouFlg = false;
        this.tipMainShow.taxFlg = false;
      } else {
        if (flg) {
          this.tipMainShow.zhekouFlg = false;
          this.tipMainShow.taxFlg = !this.tipMainShow.taxFlg;
        } else {
          this.tipMainShow.zhekouFlg = !this.tipMainShow.zhekouFlg;
          this.tipMainShow.taxFlg = false;
        }
        for (let i = 0; i < this.tipShow.length; i++) {
          this.tipShow[i].zhekouFlg = false;
          this.tipShow[i].taxFlg = false;
        }
      }
      window.scrollTo(document.body.scrollLeft, document.body.scrollHeight);
    },
    switchShow(idx, flg, type, movement) {
      this.idx = idx;
      if (flg || movement == "save") {
        //选择税种弹框与减价弹框展示控制
        if (type == "tax") {
          this.tipShow[idx].taxFlg = !this.tipShow[idx].taxFlg;
        } else if (type == "price") {
          this.tipShow[idx].zhekouFlg = !this.tipShow[idx].zhekouFlg;
        }
      } else {
        //折扣影响问题
        this.linkTax(this.subordinateFields[0].nullObject[idx].productid, type);
        this.tipShow[idx].zhekouFlg = false;
        this.tipShow[idx].taxFlg = !this.tipShow[idx].taxFlg;
      }
      for (let i = 0; i < this.tipShow.length; i++) {
        if (i !== idx) {
          this.tipShow[i].zhekouFlg = false;
          this.tipShow[i].taxFlg = false;
        }
      }
      this.tipMainShow.zhekouFlg = false;
      this.tipMainShow.taxFlg = false;
    },
    editValueZhekouChange(data, idx) {
      this.subordinateFields[this.zhuCongLength].nullObject[idx].radioData =
        data;
    },
    getInvoiceList(list) {
      this.invoiceList = list;
    },
    // 从录入更新
    editValueChangeTax(val, options, index, subordinateIndex, checkList, type) {
      this.taxList = checkList == undefined ? this.taxList : checkList;
      this.subordinateFields[this.zhuCongLength].nullObject[index][
        options
      ].value = val;
      this.idx = index;
      this.entryCount(index, subordinateIndex, val, options, type);
    },
    // 从录入计算
    entryCount(index, subordinateIndex, val, options, type) {
      this.subordinateFields[this.zhuCongLength].nullObject[index].idx =
        index || this.idx;
      if (this.isOrderPage === false) {
        let indexCount = 0;
        if (this.objectApi === "quote") {
          //从录入总计计算 单价乘数量
          this.subordinateFields[this.zhuCongLength].nullObject[
            index
          ].xiaoji.value =
            this.subordinateFields[this.zhuCongLength].nullObject[index]
              .shuliang.value *
            this.subordinateFields[this.zhuCongLength].nullObject[index].xsjg
              .value;
          this.subordinateFields[this.zhuCongLength].nullObject[
            index
          ].xiaoji.value =
            this.subordinateFields[this.zhuCongLength].nullObject[
              index
            ].xiaoji.value.toFixed(2);
          //计算更新后的税种以及折扣
          this.taxCount(type);

          indexCount =
            Number(
              this.subordinateFields[this.zhuCongLength].nullObject[index]
                .xiaoji.value
            ) -
            Number(
              this.subordinateFields[this.zhuCongLength].nullObject[index]
                .discountamount.value
            ) +
            Number(
              this.subordinateFields[this.zhuCongLength].nullObject[index].tax
                .value
            );

          this.subordinateFields[this.zhuCongLength].nullObject[
            index
          ].zongjia.value = Number(Number(indexCount).toFixed(2));
        } else if (this.objectApi === "Invoices") {
          if (this.orderToInvoiceId.length === 0) {
            this.subordinateFields[this.zhuCongLength].nullObject[
              index
            ].amount.value =
              this.subordinateFields[this.zhuCongLength].nullObject[index].rate
                .value *
              this.subordinateFields[this.zhuCongLength].nullObject[index].qty
                .value;

            this.subordinateFields[this.zhuCongLength].nullObject[
              index
            ].amount.value =
              this.subordinateFields[this.zhuCongLength].nullObject[
                index
              ].amount.value.toFixed(2);

            //计算更新后的税种以及折扣
            this.taxCount(type);
            indexCount =
              Number(
                this.subordinateFields[this.zhuCongLength].nullObject[index]
                  .amount.value
              ) -
              Number(
                this.subordinateFields[this.zhuCongLength].nullObject[index]
                  .discountamount.value
              ) +
              Number(
                this.subordinateFields[this.zhuCongLength].nullObject[index].tax
                  .value
              );
            this.subordinateFields[this.zhuCongLength].nullObject[
              index
            ].totalamount.value = Number(Number(indexCount).toFixed(2));
          } else {
            this.subordinateFields[subordinateIndex].nullObject[index][
              options
            ].value = val;
            let obj =
              this.subordinateFields[subordinateIndex].nullObject[index];
            obj.amount.value = (
              obj.unitprice.value * Number(obj.quantity.value)
            ).toFixed(2);
            obj.totalprice.value = (
              Number(obj.amount.value) -
              obj.discountamount.value +
              Number(obj.tax.value)
            ).toFixed(2);
            this.mainObjFields.forEach((item) => {
              if (item.apiname === "total_amount") {
                let dataArray =
                  this.subordinateFields[subordinateIndex].nullObject;
                item.defaultValue = 0;
                item.value = 0;
                dataArray.forEach((itemS) => {
                  item.defaultValue += Number(itemS.totalprice.value);
                  item.value += Number(itemS.totalprice.value);
                });
                item.defaultValue = item.defaultValue.toFixed(2);
                item.value = item.value.toFixed(2);
              }
            });
          }
        } else if (this.objectApi === "cloudccorder") {
          this.subordinateFields[this.zhuCongLength].nullObject[
            index
          ].amount.value =
            this.subordinateFields[this.zhuCongLength].nullObject[index]
              .unitprice.value *
            this.subordinateFields[this.zhuCongLength].nullObject[index]
              .quotedquantity.value;

          this.subordinateFields[this.zhuCongLength].nullObject[
            index
          ].amount.value = Number(
            this.subordinateFields[this.zhuCongLength].nullObject[index].amount
              .value
          ).toFixed(2);
          //计算更新后的税种以及折扣
          this.taxCount(type);
          indexCount =
            Number(
              this.subordinateFields[this.zhuCongLength].nullObject[index]
                .amount.value
            ) -
            Number(
              this.subordinateFields[this.zhuCongLength].nullObject[index]
                .discountamount.value
            ) +
            Number(
              this.subordinateFields[this.zhuCongLength].nullObject[index].tax
                .value
            );

          this.subordinateFields[this.zhuCongLength].nullObject[
            index
          ].totalprice.value = Number(Number(indexCount).toFixed(2));
        } else if (this.objectApi === "cloudccpurchaseorder") {
          this.subordinateFields[this.zhuCongLength].nullObject[
            index
          ].amount.value =
            this.subordinateFields[this.zhuCongLength].nullObject[index]
              .unitprice.value *
            this.subordinateFields[this.zhuCongLength].nullObject[index]
              .quantity.value;

          this.subordinateFields[this.zhuCongLength].nullObject[
            index
          ].amount.value = Number(
            this.subordinateFields[this.zhuCongLength].nullObject[index].amount
              .value
          ).toFixed(2);
          //计算更新后的税种以及折扣
          this.taxCount(type);

          indexCount =
            Number(
              this.subordinateFields[this.zhuCongLength].nullObject[index]
                .amount.value
            ) -
            Number(
              this.subordinateFields[this.zhuCongLength].nullObject[index]
                .discountamount.value
            ) +
            Number(
              this.subordinateFields[this.zhuCongLength].nullObject[index]
                .taxamount.value
            );

          this.subordinateFields[this.zhuCongLength].nullObject[
            index
          ].total.value = Number(Number(indexCount).toFixed(2));
        }
      } else {
        this.subordinateFields[subordinateIndex].nullObject[index][
          options
        ].value = val;
        if (this.objectApi === "Invoices") {
          let obj = this.subordinateFields[subordinateIndex].nullObject[index];
          obj.amount.value = (
            obj.unitprice.value * Number(obj.quantity.value)
          ).toFixed(2);
          obj.totalprice.value = (
            Number(obj.amount.value) -
            obj.discountamount.value +
            Number(obj.tax.value)
          ).toFixed(2);
          this.mainObjFields.forEach((item) => {
            if (item.apiname === "total_amount") {
              let dataArray =
                this.subordinateFields[subordinateIndex].nullObject;
              item.defaultValue = 0;
              item.value = 0;
              dataArray.forEach((itemS) => {
                item.defaultValue += Number(itemS.totalprice.value);
                item.value += Number(itemS.totalprice.value);
              });
              item.defaultValue = item.defaultValue.toFixed(2);
              item.value = item.value.toFixed(2);
            }
          });
        }
      }
      this.mainObjCount();
    },
    //主录入更新
    changMainData(data) {
      if (data.type === "tax" || data.type == "taxamount") {
        this.mainObjData.taxCheckList = data.taxCheckList;
        let text = "";
        data.taxCheckList.forEach((tax) => {
          if (tax.checked) {
            text += tax.ratio + ",";
          }
        });
        if (text.length > 0) {
          text = text.substr(0, text.length - 1);
        }
        this.mainObjFields.forEach((main) => {
          if (
            main.schemefieldName === "tax" ||
            main.schemefieldName === "taxamount"
          ) {
            main.defaultValue = text;
          }
        });
      } else if (data.type === "adjustment") {
        this.mainObjData.adjustment = data.adjustment;
      } else {
        this.mainObjData.radioData = data.radioData;
        this.mainObjFields.forEach((main) => {
          if (main.schemefieldName === data.type) {
            main.defaultValue =
              data.radioData.radio === "1"
                ? data.radioData.percentageValue
                : data.radioData.cutValue;
          }
        });
      }
      this.mainObjCount();
    },
    // 主录入数值计算
    mainObjCount() {
      let cutFlg = this.mainObjData.radioData.radio; //折扣模式  '1' 百分比 '2' 减价
      let zongjia = 0;
      let taxTotal = 1;
      let countSum = Number(0);
      this.mainObjData.taxCheckList.forEach((t) => {
        if (t.checked) {
          taxTotal += t.ratio * 0.01;
        }
      });

      // 计算总价合
      if (this.objectApi === "quote") {
        // 计算累计总价总价
        this.subordinateFields[this.zhuCongLength].nullObject.forEach(
          (item) => {
            if (item.zongjia && item.zongjia.value) {
              countSum = item.zongjia.value + countSum;
            }
          }
        );
        this.mainObjFields.forEach((main) => {
          if (main.schemefieldName === "zongjia") {
            main.defaultValue = Number(countSum).toFixed(2);
          }
        });
        zongjia = countSum;
      } else if (this.objectApi === "Invoices") {
        this.subordinateFields[this.zhuCongLength].nullObject.forEach(
          (item) => {
            if (
              this.isOrderPage ||
              (this.objectApi === "Invoices" &&
                this.orderToInvoiceId.length !== 0)
            ) {
              if (item.totalprice.value) {
                countSum = Number(item.totalprice.value) + Number(countSum);
              }
            } else {
              if (item.totalamount && item.totalamount.value) {
                countSum = item.totalamount.value + countSum;
              }
            }
          }
        );
        this.mainObjFields.forEach((main) => {
          if (
            main.schemefieldName === "subtotal" ||
            main.schemefieldName === "total_amount"
          ) {
            main.defaultValue = Number(countSum).toFixed(2);
            main.value = Number(countSum).toFixed(2);
          }
        });
        zongjia = countSum;
      } else if (this.objectApi === "cloudccorder") {
        this.subordinateFields[this.zhuCongLength].nullObject.forEach(
          (item) => {
            if (item.totalprice && item.totalprice.value) {
              countSum = item.totalprice.value + countSum;
            }
          }
        );
        this.mainObjFields.forEach((main) => {
          if (main.schemefieldName === "subtotal") {
            main.defaultValue = Number(countSum).toFixed(2);
          }
        });
        zongjia = countSum;
      } else if (this.objectApi === "cloudccpurchaseorder") {
        this.subordinateFields[this.zhuCongLength].nullObject.forEach(
          (item) => {
            if (item.total && item.total.value) {
              countSum = item.total.value + countSum;
            }
          }
        );
        this.mainObjFields.forEach((main) => {
          if (main.schemefieldName === "subtotal") {
            main.defaultValue = Number(countSum).toFixed(2);
          }
        });
        zongjia = countSum;
      }
      let total = 0;
      if (cutFlg === "1") {
        total =
          zongjia *
            (1 - this.mainObjData.radioData.percentageValue * 0.01) *
            taxTotal +
          Number(this.mainObjData.adjustment);
        let zhekouShow =
          zongjia * (this.mainObjData.radioData.percentageValue * 0.01);
        // 显示折扣税减少增加金额计算
        if (this.objectApi === "quote") {
          this.mainObjFields.forEach((main) => {
            if (main.schemefieldName === "totalcustomerdiscountamount") {
              main.defaultValue = zhekouShow.toFixed(2);
            }
          });
        } else {
          this.mainObjFields.forEach((main) => {
            if (main.schemefieldName === "discountamount") {
              main.defaultValue = zhekouShow.toFixed(2);
              main.value = zhekouShow.toFixed(2);
            }
          });
        }
      } else {
        total =
          (zongjia - this.mainObjData.radioData.cutValue) * taxTotal +
          this.mainObjData.adjustment;
        if (this.objectApi === "quote") {
          this.mainObjFields.forEach((main) => {
            if (main.schemefieldName === "totalcustomerdiscountamount") {
              main.defaultValue = Number(
                this.mainObjData.radioData.cutValue
              ).toFixed(2);
            }
          });
        } else {
          this.mainObjFields.forEach((main) => {
            if (main.schemefieldName === "discountamount") {
              main.defaultValue = Number(
                this.mainObjData.radioData.cutValue
              ).toFixed(2);
              main.value = Number(this.mainObjData.radioData.cutValue).toFixed(
                2
              );
            }
          });
        }
      }
      // 收税多少显示计算
      let taxShow = zongjia * taxTotal - zongjia;
      this.mainObjFields.forEach((main) => {
        if (
          main.schemefieldName === "tax" ||
          main.schemefieldName === "taxamount"
        ) {
          main.defaultValue = taxShow.toFixed(2);
          main.value = taxShow.toFixed(2);
        }
      });
      // 最终总和赋值
      if (this.objectApi === "quote") {
        this.mainObjFields.forEach((main) => {
          if (main.schemefieldName === "totalamount") {
            // main.defaultValue = Number(total).toFixed(2);
            //报价单最终报价计算
            main.defaultValue = (
              Number(this.mainObjFields[0].defaultValue) -
              Number(this.mainObjFields[1].defaultValue) +
              Number(this.mainObjFields[2].defaultValue) +
              Number(this.mainObjFields[3].defaultValue)
            ).toFixed(2);
          }
        });
      } else if (this.objectApi === "Invoices") {
        this.mainObjFields.forEach((main) => {
          if (main.schemefieldName === "total") {
            // main.defaultValue = Number(total).toFixed(2);
            //发票最终报价计算
            main.defaultValue = (
              Number(this.mainObjFields[0].defaultValue) -
              Number(this.mainObjFields[1].defaultValue) +
              Number(this.mainObjFields[2].defaultValue) +
              Number(this.mainObjFields[3].defaultValue)
            ).toFixed(2);
            if (
              this.isOrderPage ||
              (this.objectApi === "Invoices" &&
                this.orderToInvoiceId.length > 0)
            ) {
              main.value = Number(total).toFixed(2);
            }
          }
        });
        this.invociesData = Number(total).toFixed(2);
      } else if (this.objectApi === "cloudccorder") {
        this.mainObjFields.forEach((main) => {
          if (main.schemefieldName === "totalamount") {
            // main.defaultValue = Number(total).toFixed(2);
            //订单最终报价计算
            main.defaultValue = (
              Number(this.mainObjFields[0].defaultValue) -
              Number(this.mainObjFields[1].defaultValue) +
              Number(this.mainObjFields[2].defaultValue) +
              Number(this.mainObjFields[3].defaultValue)
            ).toFixed(2);
          }
        });
      } else if (this.objectApi === "cloudccpurchaseorder") {
        this.mainObjFields.forEach((main) => {
          if (main.schemefieldName === "grandtotal") {
            // main.defaultValue = Number(total).toFixed(2);
            //采购订单最终报价计算
            main.defaultValue = (
              Number(this.mainObjFields[0].defaultValue) -
              Number(this.mainObjFields[1].defaultValue) +
              Number(this.mainObjFields[2].defaultValue) +
              Number(this.mainObjFields[3].defaultValue)
            ).toFixed(2);
          }
        });
      }
    },
    addWorkSubmitSecond(data) {
      this.dialogAddVisibleSecond = false;
      this.fileList[this.fileInfo.relatedListName][this.fileInfo.index][
        this.fileInfo.fileldId
      ] = data;
    },
    // 取消上传文件
    addWorkCloseSecond() {
      this.dialogAddVisibleSecond = false;
    },
    // 打开上传文件弹窗
    openUpload(relatedApiname, item, index) {
      this.fileInfo.relatedListName = relatedApiname;
      this.fileInfo.fileldId = item.name;
      this.fileInfo.index = index;
      this.fileInfo.length = Number(item.defaultvalue);
      this.$refs.addFileSecond.initPage();
      this.dialogAddVisibleSecond = true;
    },
    // 删除文件
    deleteFile(titleName, name, index, num) {
      this.fileList[titleName][num][name].splice(index, 1);
      this.$message.success("已移除文件");
    },
    // 设置两列布局/响应式布局
    setDetailMode() {
      this.$nextTick(() => {
        if (this.detailMode !== "twoColumns") {
          this.calcDetailInfoWidth();
        } else {
          this.changeColumns("twoColumns");
        }
      });
    },
    //提交待审批成功后跳转详情页(弹窗)
    approvalSuccess() {
      this.$router.push({
        path: `/commonObjects/detail/${this.recordId}/DETAIL`,
      });
    },
    addFieldOption(prop, option) {
      if (this.optionList[prop]) {
        this.optionList[prop].push(option);
      } else {
        this.optionList[prop] = [option];
      }
    },
    // 轮班视图点击格子新建
    handleShift(obj) {
      this.shiftData = obj;
      this.add();
    },
    // 日历视图传入的相关参数
    toDoList(obj) {
      this.objId = obj.type;
      this.eventDate = obj.timeObj || {};
      this.calendarData = obj.config || {};
      this.calendarUser = obj.userInfo || {};
      this.add();
    },
    // 计算详细信息宽度
    calcDetailInfoWidth() {
      if (
        document.getElementsByClassName("detailContentBox")[0] !== undefined
      ) {
        let width =
          document.getElementsByClassName("detailContentBox")[0].clientWidth;
        if (width <= 1280 - 72) {
          this.setColumnsMode("twoColumns");
        } else if (1280 - 72 < width && width <= 1920 - 72) {
          this.setColumnsMode("fourColumns");
        } else if (width > 1920 - 72) {
          this.setColumnsMode("sixColumns");
        }
      }
    },
    // 二列布局和响应式布局切换
    changeColumns(columns) {
      this.columnsChange = columns;
      if (columns === "twoColumns") {
        VueCookies.set(`${this.objectApi}DetailMode`, "twoColumns", { sameSite: 'Strict' });
        this.setColumnsMode("twoColumns");
      } else {
        VueCookies.set(`${this.objectApi}DetailMode`, "responsiveLayout", { sameSite: 'Strict' });
        this.calcDetailInfoWidth();
      }
      Bus.$emit("setColumnsMode", columns);
      this.detailMode = VueCookies.get(`${this.objectApi}DetailMode`);
    },
    // 获取数据成功
    haveResult(val) {
      if (val) {
        clearTimeout(this.timerS);
      }
    },
    // 新建
    add(val, Wval, type, newCreate) {
      //判断当前新建是否为新建订单或发票页面再进行客户地址新建
      this.newCreate = newCreate;
      //判断当前是客户地址新建
      this.customerAddress = Wval == "cloudccustomeraddress" ? val : {};
      //判断当前是否在其他对象下新建客户地址
      this.isObjNewBuild = type == "objNewBuild" ? true : false;
      if (this.isReceivable) {
        this.timerS = setTimeout(() => {
          this.$refs.invoiceSpecial.getDataList();
        }, 800);
      }
      this.actives = val;
      this.recordType = "";
      this.validRule = false;
      this.formAttr = [];
      this.optionList = {};
      this.showBaseForm = false;
      this.getCurrentUser();
      // 记录电话没有记录类型弹框
      if (Wval != "isCall") {
        this.getRecordType(Wval);
      } else {
        this.dialogAttr.type = "NEW";
        this.getFormAttr("New", "isCall");
        this.dialogAttr.isShow = true;
      }
      // 新建
      this.dialogAttr.title = `${this.$i18n.t("label.ems.create")} ${
        this.objectName
      }`;
      this.dialogAttr.type = "NEW";
    },
    // 修改记录类型后编辑
    editAfterChangeRecordType(recordType) {
      this.recordType = recordType;
      this.validRule = false;
      this.showBaseForm = false;
      this.dialogAttr.type = "EDIT";
      this.recordOptions = [];
      this.getFormAttr();
      // 编辑
      //项目列表编辑子任务
      if (
        this.$route.path.substring(this.$route.path.length - 3) == "p03" &&
        // eslint-disable-next-line no-undef
        row.data.their_task
      ) {
        this.dialogAttr.title = `${this.$i18n.t(
          "pagecreator_page_button_edit"
        )} 子任务`;
      } else {
        this.dialogAttr.title = `${this.$i18n.t(
          "pagecreator_page_button_edit"
        )} ${this.objectName}`;
      }
      this.dialogAttr.isShow = true;

      this.setDetailMode();
    },
    // 编辑
    edit(row, type, types, newCreate) {
      //判断当前新建是否为新建订单或发票页面再进行客户地址新建
      this.newCreate = newCreate;
      //判断当前是客户地址新建
      this.customerAddress = type == "cloudccustomeraddress" ? row : {};
      //判断当前是否在其他对象下新建客户地址
      this.isObjNewBuild = types == "objNewBuild" ? true : false;
      this.recordType = "";
      this.validRule = false;
      this.showBaseForm = false;
      this.dialogAttr.type = "EDIT";
      this.recordOptions = [];
      //知识文章更改记录类型
      if (type && type != "isCall" && type != "cloudccustomeraddress") {
        this.recordType = type;
        createApi.getRecordType({ prefix: this.prefix }).then((res) => {
          if (
            res.data.recordTypeList !== null &&
            res.data.recordTypeList !== undefined
          ) {
            res.data.recordTypeList.forEach((record) => {
              this.recordOptions.push({
                val: record.id,
                key: record.name,
              });
            });
            if (res.data.recordTypeList.length > 1) {
              this.recordTypeDialogAttr.isShow = true;
            }
          }
        });
      } else if (type && type == "isCall") {
        this.dialogAttr.isShow = true;
        this.getFormAttr("", "isCall");
      } else {
        this.dialogAttr.isShow = true;
        this.getFormAttr();
      }
      // 编辑
      //项目列表编辑子任务
      if (
        this.$route.path.substring(this.$route.path.length - 3) == "p03" &&
        row.data.their_task
      ) {
        this.dialogAttr.title = `${this.$i18n.t(
          "pagecreator_page_button_edit"
        )} 子任务`;
      } else {
        this.dialogAttr.title = `${this.$i18n.t(
          "pagecreator_page_button_edit"
        )} ${this.objectName}`;
      }
      // this.dialogAttr.isShow = true;
      this.setDetailMode();
    },
    // 复制
    copy(konwelgeid = "") {
      this.konwelgeid = konwelgeid;
      this.getCurrentUser();
      this.validRule = false;
      this.showBaseForm = false;
      this.dialogAttr.type = "COPY";
      createApi.getRecordType({ prefix: this.prefix }).then((res) => {
        if (
          res.data.recordTypeList !== null &&
          res.data.recordTypeList !== undefined
        ) {
          res.data.recordTypeList.forEach((record) => {
            this.recordOptions.push({
              val: record.id,
              key: record.name,
            });
          });
        }
      });
      this.getFormAttr();
      // 复制
      if (this.konwelgeid !== "") {
        this.dialogAttr.title = `${this.$i18n.t(
          "pagecreator_page_button_edit"
        )} 知识文章`;
      } else {
        this.dialogAttr.title = `${this.$i18n.t("label.clone")} ${
          this.objectName
        }`;
      }

      this.dialogAttr.isShow = true;
      this.setDetailMode();
    },
    //联系人第一次启用外部用户
    contacts() {
      this.recordType = "";
      this.validRule = false;
      this.showBaseForm = false;
      this.contactes = "contactsNew";
      this.getFormAttr("contactsNew");
      // 新建
      this.dialogAttr.title = this.$i18n.t("label.ems.create");
      this.dialogAttr.isShow = true;
      this.setDetailMode();
    },
    // 下一步 打开新建/编辑弹窗
    next() {
      this.formAttr = [];
      this.getFormAttr();
      if (this.dialogAttr.type === "NEW" || this.dialogAttr.type === "COPY") {
        // this.getZhuCong()
      }
      this.cancelRecordTypeDialog();
      this.dialogAttr.isShow = true;
      this.setDetailMode();
    },
    // 获取当前登录用户
    getCurrentUser() {
      // 获取登录用户
      this.currentUserInfo = {
        id: localStorage.getItem("userId"),
        name: localStorage.getItem("username"),
      };
    },
    // 事件类型的相关项处理
    getRelatedItem() {
      // 获取相关项
      createApi.getDialigItem({ type: this.objId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.relatedItem = {
            reItem: res.data.relateobjList,
            naItem: res.data.whoobjInfoList,
          };
          this.formAttr.map((item) => {
            if (item.prop === "relateid") {
              item.type = "eventTypeB";
              item.optionItem = this.relatedItem.reItem;
              item.width = "100%";
              if (this.isActive === "active") {
                if (
                  this.objectApies !== "Lead" &&
                  this.objectApies !== "cloudcclead" &&
                  this.objectApies !== "Contact"
                ) {
                  item.edit = false;
                } else {
                  item.edit = true;
                }
              } else if (this.standardHomePage === "homepage") {
                if (
                  this.homeNweObjid !== "Lead" &&
                  this.homeNweObjid !== "cloudcclead" &&
                  this.homeNweObjid !== "Contact"
                ) {
                  item.edit = false;
                } else {
                  item.edit = true;
                }
              } else if (this.calendarData.id) {
                if (
                  this.calendarData.prefix !== "003" &&
                  this.calendarData.prefix !== "004"
                ) {
                  item.edit = false;
                } else {
                  item.edit = true;
                }
              }
            }
            if (item.prop === "whoid") {
              item.type = "eventTypeA";
              item.optionItem = this.relatedItem.naItem;
              item.width = "100%";
              if (this.isActive === "active") {
                if (
                  this.objectApies === "Lead" ||
                  this.objectApies === "cloudcclead" ||
                  this.objectApies === "Contact"
                ) {
                  item.edit = false;
                } else {
                  item.edit = true;
                }
              } else if (this.standardHomePage === "homepage") {
                if (
                  this.homeNweObjid === "Lead" ||
                  this.homeNweObjid === "cloudcclead" ||
                  this.homeNweObjid === "Contact"
                ) {
                  item.edit = false;
                } else {
                  item.edit = true;
                }
              } else if (this.calendarData.id) {
                if (
                  this.calendarData.prefix === "003" ||
                  this.calendarData.prefix === "004"
                ) {
                  item.edit = false;
                } else {
                  item.edit = true;
                }
              }
            }
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 获取当前系统时间的下一个整点以及整点之后一个小时的时间
    getHour() {
      let date = new Date();
      date.setHours(date.getHours() + 1);
      let y = date.getFullYear();
      let m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let startTime = y + "-" + m + "-" + d + " " + h + ":00:00";
      function getNextHour(intPoint) {
        let dd = new Date(intPoint);
        let Y = dd.getFullYear();
        let M =
          dd.getMonth() + 1 < 10
            ? "0" + (dd.getMonth() + 1)
            : dd.getMonth() + 1;
        let D = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        let H =
          dd.getHours() + 1 < 10
            ? "0" + (dd.getHours() + 1)
            : dd.getHours() + 1;
        return Y + "-" + M + "-" + D + " " + H + ":00:00";
      }
      return { start: startTime, end: getNextHour(startTime) };
    },
    // 远程搜索
    remoteMethod(params, item, formData) {
      //在项目管理系统下对负责人所属项目的判断
      if (
        item.apiname == "principal" ||
        item.apiname == "member" ||
        (window.location.href.substring(window.location.href.length - 3) ==
          "p04" &&
          (item.apiname == "milestone" ||
            item.apiname == "affected_milestones"))
      ) {
        if (
          formData.project_name == "" ||
          formData.their_project == "" ||
          formData.their_project_name == ""
        ) {
          this.$message.error(
            this.$i18n.t("label.projectManagement.selectitem")
          ); //"请选择项目"
          return;
        }
      }
      if (
        (window.location.href.substring(window.location.href.length - 3) !==
          "p03" ||
          window.location.href.substring(window.location.href.length - 3) !==
            "p05") &&
        item.apiname !== "their_milestone"
      ) {
        params.projectId =
          formData.their_project ||
          formData.project_name ||
          formData.their_project_name;
      }
      // 查询是否有默认条件
      createApi
        .getLookupInfo({
          fieldId: params.fieldId,
          objId: item.objId,
          prefix: item.prefix,
        })
        .then((res) => {
          // eslint-disable-next-line no-unused-vars
          // let lkdp = [""];
          let values = [""];

          // 开启查找筛选
          if (res.data.isEnableFilter === "true") {
            for (let reltaion in res.data.filterFieldIds) {
              let attr = this.formAttr.find((attrItem) => {
                return (
                  attrItem.fieldId === res.data.filterFieldIds[reltaion].fieldid
                );
              });
              if (
                attr !== undefined &&
                formData[attr.prop] !== null &&
                Array.isArray(formData[attr.prop])
              ) {
                values.push(formData[attr.prop].join(";"));
              } else if (attr !== undefined && formData[attr.prop] !== null) {
                values.push(formData[attr.prop]);
              }
            }
          }
          params.lkdp = JSON.stringify(values);
          createApi.queryMoreDataByName(params).then((res) => {
            let options = JSON.parse(res.data);
            options.forEach((option) => {
              option.value = option.id;
              option.label = option.queryname;
            });
            this.$set(this.optionList, item.prop, options);
          });
        });
    },
    // 新增查找、查找多选字段
    newSearchableField(item) {
      // 获取对象api后进行页面跳转
      createApi
        .getLookupInfo({
          fieldId: item.fieldId,
          objId: item.objId,
          prefix: item.prefix,
        })
        .then((res) => {
          let objectApi = res.data.objectApi;
          window.open(
            `#/add-relevant-obj/${item.prefix}/${objectApi}/${this.newObjLevel}?addType=option&prop=${item.prop}`
          );
        });
    },
    // 添加文件
    addFile(field) {
      this.fieldProp = field.prop;
      this.$refs.addFile.initPage();
      this.dialogAddVisible = true;
    },
    // 添加文件
    addWorkSubmit(checkedFileList) {
      let ids = [];
      checkedFileList.forEach((file) => {
        if (file.fileInfoId) {
          ids.push(file.fileInfoId);
        } else {
          ids.push(file.id);
        }
      });
      // 文件上传个数超出时给提示
      this.formAttr.map((attr) => {
        if (attr.prop === this.fieldProp) {
          // 当前文件总数
          let idsNum = Array.isArray(attr.data)
            ? attr.data.length + ids.length
            : ids.length;
          if (attr.length && idsNum > Number(attr.length)) {
            this.$message.warning(this.$i18n.t("vue_label_file_num_limit_tip"));
            this.$refs.addFile.buttonLoading = false;
          } else {
            attr.data = Array.isArray(attr.data)?[...attr.data,...checkedFileList]:checkedFileList;
            let fileIds = this.$refs.baseForm.formData[this.fieldProp]
            fileIds = fileIds?`${fileIds},${ids.toString()}`:ids.toString()
            this.$set(
              this.$refs.baseForm.formData,
              this.fieldProp,
              fileIds
            );
            this.addWorkClose();
          }
        }
      });
    },
    // 关闭添加文件
    addWorkClose() {
      this.dialogAddVisible = false;
    },
    // 获取记录类型
    getRecordType(Wval) {
      this.recordOptions = [];
      createApi.getRecordType({ prefix: this.prefix }).then((res) => {
        if (
          res.data.recordTypeList !== null &&
          res.data.recordTypeList !== undefined
        ) {
          res.data.recordTypeList.forEach((record) => {
            // 设置默认记录类型
            if (record.isdefault === "true") {
              this.recordType = record.id;
            }

            return this.recordOptions.push({
              val: record.id,
              key: record.name,
            });
          });
          // 若没有默认记录类型，设置第一个为默认
          if (this.recordType === "") {
            this.recordType =
              this.recordOptions.length > 0 && this.dialogAttr.type !== "EDIT"
                ? this.recordOptions[0].val
                : "";
          }
          // recordType未赋上值或者只有一个记录类型时，展示记录详情
          if (this.recordType === "" || this.recordOptions.length === 1) {
            this.dialogAttr.isShow = true;
            this.setDetailMode();
            this.getFormAttr();
            // this.getZhuCong()
          }

          // 若默认记录类型只有一个，跳过 选择记录类型
          if (res.data.recordTypeList.length > 1) {
            this.recordTypeDialogAttr.isShow = true;
          } else {
            this.dialogAttr.isShow = true;
            this.setDetailMode();
          }
        } else {
          this.dialogAttr.isShow = true;
          this.setDetailMode();
          this.getFormAttr("", Wval);
          // this.getZhuCong()
        }
      });
    },
    // 246列布局设置
    setColumnsMode(detailMode) {
      switch (detailMode) {
        case "twoColumns":
          this.columnNum = 2;
          this.oneColumnWidth = "100%";
          this.twoColumnWidth = "50%";
          break;
        case "fourColumns":
          this.columnNum = 4;
          this.oneColumnWidth = "50%";
          this.twoColumnWidth = "25%";
          break;
        case "sixColumns":
          this.columnNum = 6;
          this.oneColumnWidth = `${100 / 3}%`;
          this.twoColumnWidth = `${100 / 6}%`;
          break;
      }
    },
    // 获取表单数据
    getFormAttr(type, Wval) {
      this.getOrderCurrency();
      this.orderToInvoiceId = [];
      // 初始化税种
      this.taxInit();
      this.attrIndex = -1;
      this.formAttr = [];
      this.attrTemp = [];
      this.subordinateFields = [];

      this.optionList = {};
      this.showBaseForm = false;
      this.isaddShow = false;

      // 246列布局设置
      if (VueCookies.get(`${this.objectApi}DetailMode`) === null) {
        VueCookies.set(`${this.objectApi}DetailMode`, "twoColumns", { sameSite: 'Strict' });
      }
      this.detailMode = VueCookies.get(`${this.objectApi}DetailMode`);

      let params = {
        id:
          (Wval === "isCall" && type === "New") ||
          this.dialogAttr.type === "NEW"
            ? ""
            : this.id,
        objectApi: this.objectApi,
        recordId: this.relativeRecordId,
        fieldId: this.relationFieldId,
        operation: this.dialogAttr.type,
        tasktype: Wval === "isCall" ? "logcall" : null,
      };
      if (type === "contactsNew") {
        let obj = {
          controlContainer: [],
          dataList: this.dataListPartner,
          dependContainer: [],
          lookupValueExpression: "",
        };
        obj.dataList.forEach((group) => {
          if (group.showWhenEdit) {
            this.attrIndex = -1;
          }

          let colNum = group.colNum;
          if (group.showWhenEdit) {
            this.attrTemp.push({
              width: "100%",
              colNum: 1,
              type: "groupTitle",
              title: group.title,
              selectionId: group.selectionId,
              groupShow: true,
              hidden: false,
            });
          }

          // 非内嵌页面
          if (group.selectionType === "Field") {
            group.data.length > 0 &&
              group.data.forEach((line) => {
                if (
                  line.left &&
                  !(Array.isArray(line.left) && line.left.length === 0) &&
                  line.left.fieldType !== "blank"
                ) {
                  let attr = this.attrTemp.find((item) => {
                    return item.fieldId === line.left.fieldId;
                  });
                  if (attr === undefined) {
                    this.addFormAttr(
                      group,
                      colNum,
                      line.left,
                      undefined,
                      "left"
                    );
                  }
                } else {
                  if (line.left.fieldType == "blank") {
                    this.attrIndex += group.colNum === 1 ? 0 : 1;
                    this.attrTemp.push({
                      width: "50%",
                      colNum: 2,
                      attrIndex: this.attrIndex,
                      type: "noData",
                      hidden: false,
                      groupShow: true,
                      selectionId: group.selectionId,
                      groupTitle: group.title,
                      position: "left",
                    });
                  }
                }
                if (Number(colNum) !== 1) {
                  if (
                    !(Array.isArray(line.right) && line.right.length === 0) &&
                    line.right.fieldType !== "blank"
                  ) {
                    let attr = this.attrTemp.find((item) => {
                      return item.fieldId === line.right.fieldId;
                    });
                    if (attr === undefined) {
                      this.addFormAttr(
                        group,
                        colNum,
                        line.right,
                        undefined,
                        "right"
                      );
                    }
                  } else {
                    if (line.right.fieldType == "blank") {
                      this.attrIndex += group.colNum === 1 ? 0 : 1;
                      this.attrTemp.push({
                        width: "50%",
                        colNum: 2,
                        attrIndex: this.attrIndex,
                        type: "noData",
                        hidden: false,
                        groupShow: true,
                        selectionId: group.selectionId,
                        groupTitle: group.title,
                      });
                    }
                  }
                }
              });
          } else {
            // 内嵌页面
            this.attrTemp.push({
              width: "100%",
              colNum: 1,
              type: "customPage",
              pageUrl: group.pageUrl,
              selectionId: group.selectionId,
              groupShow: group.showWhenEdit,
              hidden: false,
            });
          }

          this.showBaseForm = true;
        });
        this.formAttr = this.attrTemp;
        // 强制刷新表单
        this.$refs.baseForm && this.$refs.baseForm.filterFormData();

        // 查找带值，绑定事件
        if (
          obj.lookupValueExpression !== undefined &&
          obj.lookupValueExpression !== "" &&
          obj.lookupValueExpression !== null
        ) {
          this.setLookupValue(JSON.parse(obj.lookupValueExpression));
        }
        // 动态布局规则，绑定事件
        this.getDynamicLayoutListDetail(obj.layoutId);
        this.isaddShow = true;
        return;
      }
      if (this.recordType !== "") {
        params.recordtype = this.recordType;
      }
      this.nowTime = new Date().valueOf();
      // eslint-disable-next-line no-unused-vars
      let time = new Date().valueOf();
      if (params.objectApi == "cloudcccase") {
        params.phoneOrEmail = this.phoneemail;
        params.vistorId = this.vistorId;
      }

      createApi
        .getDetail(params)
        .then((res) => {
          if (res.result && res.returnCode === "1") {
            this.layoutId = res.data.layoutId;
            // 获取从对象信息
            this.getZhuCong(res.data.layoutId);

            // 是否展示 保存并提交按钮
            this.saveandapproval =
              res.data.saveandapproval && res.data.saveandapproval === "true"
                ? true
                : false;

            res.data.dataList.forEach((group) => {
              let colNum = group.colNum;
              if (group.showWhenEdit) {
                this.attrTemp.push({
                  width: "100%",
                  type: "groupTitle",
                  title: group.title,
                  selectionId: group.selectionId,
                  groupShow: true,
                  hidden: false,
                });
              }
              group.data.length > 0 &&
                group.data.forEach((line) => {
                  if (
                    line.left &&
                    !(Array.isArray(line.left) && line.left.length === 0) &&
                    line.left.fieldType !== "blank"
                  ) {
                    let attr = this.attrTemp.find((item) => {
                      return item.fieldId === line.left.fieldId;
                    });
                    if (attr === undefined) {
                      this.addFormAttr(
                        group,
                        colNum,
                        line.left,
                        undefined,
                        "left"
                      );
                    }
                  } else {
                    if (line.left.fieldType == "blank") {
                      this.attrIndex += group.colNum === 1 ? 0 : 1;
                      this.attrTemp.push({
                        width: "50%",
                        colNum: 2,
                        attrIndex: this.attrIndex,
                        type: "noData",
                        hidden: false,
                        groupShow: true,
                        selectionId: group.selectionId,
                        groupTitle: group.title,
                        position: "left",
                      });
                    }
                  }
                  if (colNum !== 1) {
                    if (
                      !(Array.isArray(line.right) && line.right.length === 0) &&
                      line.right.fieldType !== "blank"
                    ) {
                      let attr = this.attrTemp.find((item) => {
                        return item.fieldId === line.right.fieldId;
                      });
                      if (attr === undefined) {
                        this.addFormAttr(
                          group,
                          colNum,
                          line.right,
                          undefined,
                          "right"
                        );
                      }
                    } else {
                      if (line.right.fieldType == "blank") {
                        this.attrIndex += group.colNum === 1 ? 0 : 1;
                        this.attrTemp.push({
                          width: "50%",
                          colNum: 2,
                          attrIndex: this.attrIndex,
                          type: "noData",
                          hidden: false,
                          groupShow: true,
                          selectionId: group.selectionId,
                          groupTitle: group.title,
                        });
                      }
                    }
                  }
                });
            });
            this.showBaseForm = true;
            this.formAttr = this.attrTemp;
            // 公海池的特殊字段，客户公海池和潜在客户公海池新建时才所有人是所属公海池
            if (
              this.pageFlag === "clientPool" &&
              this.dialogAttr.type === "NEW" &&
              this.clientPoolOwner.value
            ) {
              let flag = this.formAttr.find((item) => {
                return item.apiname && item.apiname === "marketsea";
              });
              if (Object.prototype.toString.call(flag) == "[object Object]") {
                this.formAttr.find((item) => {
                  if (item.apiname && item.apiname === "ownerid") {
                    item.value = this.clientPoolOwner.value;
                    this.$set(
                      this.optionList,
                      "ownerid",
                      this.clientPoolOwner.options
                    );
                  }
                });
              }
            }
            if (
              !this.isOrderPage &&
              this.dialogAttr.type === "EDIT" &&
              (this.objectApi === "cloudccorder" ||
                this.objectApi === "Invoices" ||
                this.objectApi === "cloudccpurchaseorder" ||
                this.objectApi === "quote")
            ) {
              for (let i = 0; i < this.formAttr.length; i++) {
                if (this.formAttr[i].apiname === "currency") {
                  this.currencyVal = this.formAttr[i].value;
                  break;
                }
              }
            }
            this.$nextTick(() => {
              // 强制刷新表单
              this.$refs.baseForm && this.$refs.baseForm.filterFormData();
              this.lookupValueExpression = res.data.lookupValueExpression;

              // 处理依赖关系，绑定事件
              this.controlContainer = JSON.parse(res.data.controlContainer);
              this.isaddShow = true;
            });
          } else {
            this.$message.error(res.returnInfo);
          }
          // 非新增操作时回填记录类型
          if (this.dialogAttr.type !== "NEW") {
            this.recordType = res.data.recordType;
          }
        })
        .catch(() => {
          this.cancel();
        });
      // 主从录入初始化币种
    },
    // 组件内部处理完formData后表单才显示，父组件才能通过$refs获取到表单组件
    ruleProcessing() {
      this.$nextTick(() => {
        // 字段依赖
        this.setControlContainer();
        // 查找带值，绑定事件
        if (
          this.lookupValueExpression !== undefined &&
          this.lookupValueExpression !== "" &&
          this.lookupValueExpression !== null
        ) {
          this.setLookupValue(JSON.parse(this.lookupValueExpression));
        }
        // 动态布局规则，绑定事件
        this.getDynamicLayoutListDetail(this.layoutId);
      });
    },
    // 查找带值字段
    setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.lookupValues.push(item);
        this.formAttr.forEach((attr) => {
          if (attr.apiname === item.expression.split(",")[0].toLowerCase()) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
            // 初始化规则时走规则
            this.isZhucongfield = false;
            this.getALLLookupValue(
              lookupValueExpression,
              attr.apiname,
              attr.value
            );
          }
        });
      });
    },
    // 查找带值
    lookupValue(selectVal, selectItem, formData) {
      this.isZhucongfield = false;
      this.getALLLookupValue(
        this.lookupValues,
        selectItem.apiname,
        selectVal,
        formData
      );
    },
    taxCount(type) {
      let number = 0;
      if (type == "click") {
        this.subordinateFields[this.zhuCongLength].nullObject[
          this.idx
        ].taxCheckList = this.taxList;
      }
      //当编辑时税种数据保存
      if (this.taxList.length > 0) {
        let ratetypeList = "";
        this.subordinateFields[this.zhuCongLength].nullObject[
          this.idx
        ].taxCheckList.forEach((el) => {
          if (el.checked) {
            let ratetypeStr = "";
            ratetypeStr = el.name + ":" + el.ratio + "%;";
            ratetypeList += ratetypeStr;
          }
        });
        this.subordinateFields[this.zhuCongLength].nullObject[
          this.idx
        ].ratetype.value = ratetypeList;
      }
      this.subordinateFields[this.zhuCongLength].nullObject[
        this.idx
      ].taxCheckList.forEach((item) => {
        if (item.checked) {
          number += item.ratio * 0.01;
        }
      });
      let text = "";
      if (
        this.subordinateFields[this.zhuCongLength].nullObject[this.idx].xiaoji
      ) {
        text = Number(
          this.subordinateFields[this.zhuCongLength].nullObject[this.idx].xiaoji
            .value * number
        ).toFixed(2);
      } else {
        text = Number(
          this.subordinateFields[this.zhuCongLength].nullObject[this.idx].amount
            .value * number
        ).toFixed(2);
      }
      let textZhekou = "";
      if (
        this.subordinateFields[this.zhuCongLength].nullObject[this.idx]
          .radioData.radio === "1"
      ) {
        if (
          this.subordinateFields[this.zhuCongLength].nullObject[this.idx].xiaoji
        ) {
          textZhekou = Number(
            Number(
              this.subordinateFields[this.zhuCongLength].nullObject[this.idx]
                .xiaoji.value
            ) *
              Number(
                this.subordinateFields[this.zhuCongLength].nullObject[this.idx]
                  .radioData.percentageValue * 0.01
              )
          ).toFixed(2);
        } else {
          textZhekou = Number(
            this.subordinateFields[this.zhuCongLength].nullObject[this.idx]
              .amount.value *
              (this.subordinateFields[this.zhuCongLength].nullObject[this.idx]
                .radioData.percentageValue *
                0.01)
          ).toFixed(2);
        }
      } else {
        textZhekou = Number(
          this.subordinateFields[this.zhuCongLength].nullObject[this.idx]
            .radioData.cutValue
        ).toFixed(2);
      }
      this.subordinateFields[this.zhuCongLength].nullObject[
        this.idx
      ].discountamount.value = textZhekou;
      if (this.objectApi === "cloudccpurchaseorder") {
        this.subordinateFields[this.zhuCongLength].nullObject[
          this.idx
        ].taxamount.value = text;
      } else {
        this.subordinateFields[this.zhuCongLength].nullObject[
          this.idx
        ].tax.value = text;
      }
    },
    linkTax(productid, type) {
      createApi.listRelatedTax({ id: productid }).then((res) => {
        if (res.data[0][productid].length > 0) {
          if (type != "click") {
            res.data[0][productid].forEach((el) => {
              el.checked = true;
            });
            this.subordinateFields[this.zhuCongLength].nullObject[
              this.idx
            ].taxCheckList = res.data[0][productid];
          } else if (this.dialogAttr.type == "EDIT") {
            //编辑时回显选中的税种
            let taxArr = []; //当前数据所选税种
            taxArr =
              this.subordinateFields[this.zhuCongLength].nullObject[
                this.idx
              ].ratetype.value.split(";");
            taxArr.pop();
            taxArr = taxArr.map((taxItem) => {
              let obj = {
                name: taxItem.split(":")[0],
                value: taxItem.split(":")[1],
              };
              return obj;
            });
            res.data[0][productid].forEach((el) => {
              taxArr.forEach((item) => {
                if (
                  el.name == item.name &&
                  Number(el.ratio) ==
                    Number(item.value.substr(0, item.value.length - 1))
                ) {
                  el.checked = true;
                }
              });
            });
            this.subordinateFields[this.zhuCongLength].nullObject[
              this.idx
            ].taxCheckList = res.data[0][productid];
          }
        } else {
          this.subordinateFields[this.zhuCongLength].nullObject[
            this.idx
          ].taxCheckList = [];
        }
        if (type != "click") {
          this.relatedProductPrice(productid, type);
        }
      });
    },
    taxInit() {
      createApi.ListTax({ id: "" }).then((res) => {
        if (res.result) {
          res.data.forEach((item) => {
            item.checked = false;
          });
          this.taxInitCheckList = res.data;
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    // 改变从对象查找/查找带值选项及值
    async changeSlaveSelect(row) {
      let options = [];
      let optionValue = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
      } else {
        options.push({
          value: row.data.id,
          label: row.data.name,
        });
        optionValue = row.data.id.toString();
      }
      let value = { value: optionValue };
      this.showSearchTable = false;
      if (
        this.objectApi === "quote" ||
        this.objectApi === "cloudccorder" ||
        this.objectApi === "Invoices" ||
        this.objectApi === "cloudccpurchaseorder"
      ) {
        this.$set(this.addmanualForm.list[this.rowIndex], "val", options);
      }
      //主从录入选择产品
      if (row.field.id === "ffe201100006341skyK1") {
        if (this.$refs.baseForm.formData.currency) {
          this.$store.commit(
            "setLanguge",
            this.$refs.baseForm.formData.currency
          );
        }
        //初始化产品id
        this.subordinateFields[this.zhuCongLength].nullObject[
          this.idx
        ].productid = row.data.id;
        // 从录入产品价格手册关联
        this.linkTax(row.data.id);
        if (
          (this.objectApi === "cloudccorder" ||
            this.objectApi === "cloudccpurchaseorder") &&
          this.subordinateFields[this.zhuCongLength].nullObject[this.idx].name
        ) {
          this.subordinateFields[this.zhuCongLength].nullObject[
            this.idx
          ].name.value = row.data.name;
        }
      }

      // 主从录入
      this.$set(
        this.dataLists[this.ind][this.rowIndex - 1],
        this.apiname,
        value
      );
      this.$set(
        this.dataLists[this.ind][this.rowIndex - 1],
        `${this.apiname}Options`,
        options
      );
      this.getALLLookupValue(
        this.relatedLookupValues,
        this.apiname,
        optionValue
      );
    },
    // 从录入产品价格手册关联
    relatedProductPrice(productid, type) {
      // 从录入产品价格手册关联

      createApi
        .relatedProductPrice({
          productid: productid,
          currency:
            this.$store.state.channel.languge ||
            this.$store.state.userInfoObj.currencyCode,
          isdefault: "1",
        })
        .then((res) => {
          if (res.data && res.data.length !== 0) {
            if (this.objectApi === "quote") {
              this.editValueChangeTax(
                Number(Number(res.data[0].listprice).toFixed(2)),
                "xsjg",
                this.idx,
                type
              );
            } else if (this.objectApi === "Invoices") {
              this.editValueChangeTax(
                Number(Number(res.data[0].listprice).toFixed(2)),
                "rate",
                this.idx,
                type
              );
            } else if (this.objectApi === "cloudccorder") {
              this.editValueChangeTax(
                Number(Number(res.data[0].listprice).toFixed(2)),
                "unitprice",
                this.idx,
                type
              );
            } else if (this.objectApi === "cloudccpurchaseorder") {
              this.editValueChangeTax(
                Number(Number(res.data[0].listprice).toFixed(2)),
                "unitprice",
                this.idx,
                type
              );
            }
          } else {
            if (this.objectApi === "quote") {
              this.editValueChangeTax(0.0, "xsjg", this.idx, type);
            } else if (this.objectApi === "Invoices") {
              this.editValueChangeTax(0.0, "rate", this.idx, type);
            } else if (this.objectApi === "cloudccorder") {
              this.editValueChangeTax(0.0, "unitprice", this.idx, type);
            } else if (this.objectApi === "cloudccpurchaseorder") {
              this.editValueChangeTax(0.0, "unitprice", this.idx, type);
            }
          }
        });
    },
    changeMR(selectVal, selectItem) {
      if (
        this.objectApi === "Invoices" &&
        selectItem.apiname === "cloudccorder"
      ) {
        this.getZhuCong("", selectVal);
        this.orderToInvoiceId = selectVal;
      }
    },
    // 改变查找/查找带值选项及值
    changeSelect(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      let optionValue = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue = row.data.id.toString();
      }

      // eslint-disable-next-line no-unused-vars
      let value = { value: optionValue };
      this.showSearchTable = false;
      if (this.transferOwnerDialog || this.transferClientOwnerDialog) {
        this.changeSelectOwner(row);
      } else {
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === fieldId) {
            this.$set(this.optionList, attr.prop, options);
            if (attr.fieldType === "MR") {
              options.forEach((option) => {
                fieldVal.push(option.value);
              });
              formData[attr.apiname] = fieldVal;
            } else {
              formData[attr.apiname] =
                row.data === undefined ? "" : row.data.id;
            }
            this.$refs.baseForm.filterOptions = [];

            // 若有查找带值，将值带入
            this.getALLLookupValue(
              this.lookupValues,
              attr.apiname,
              optionValue,
              formData
            );
          }
        });
      }
      if (this.objectApi === "Invoices" && fieldId === "ffe2021theinvoices01") {
        let array = [];
        if (Array.isArray(row)) {
          row.forEach((list) => {
            array.push(list.id);
          });
        } else {
          array.push(row.data.id);
        }
        this.orderToInvoiceId = array;
        this.getZhuCong("", array);
        if (row.relaInfo) {
          this.currencyVal = row.relaInfo.currency;
          this.pricSelect = row.relaInfo.pricebookId;
          this.formAttr.forEach((list) => {
            if (list.apiname === "currency") {
              list.value = row.relaInfo.currency;
              this.$refs.baseForm.formData["currency"] = row.relaInfo.currency;
            }
          });
        }
      }
      if (
        (this.objectApi === "quote" && fieldId === "ffe201300152388xiDHE") ||
        (this.objectApi === "cloudccorder" &&
          fieldId === "ffe20210525order0023") ||
        (this.objectApi === "Invoices" && fieldId === "ffe2022theinvoices02") ||
        (this.objectApi === "cloudccpurchaseorder" &&
          fieldId === "ffe2022purchaorder00")
      ) {
        this.pricSelect = row.data.id;
      }
    },
    // 依赖关系
    setControlContainer() {
      // 先将被控字段选项清空,循环后有对应选项再进行赋值
      this.controlContainer.forEach((control) => {
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                this.optionList[controlAttr.prop] = [];
              }
            });
          });
        });
      });

      this.controlContainer.forEach((control) => {
        // 控制字段
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === control.controlfield) {
            this.control(
              attr.value,
              attr.fieldId,
              this.$refs.baseForm.formData,
              true
            );
          }
        });
      });
    },
    // 依赖字段 ,initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
    control(selectVal, fieldId, formData, initControl = false) {
      // 被控制字段集合
      let controledFieldId = [];
      let controledFieldProp = [];

      // 查找对应规则
      let control = this.controlContainer.find((control) => {
        return control.controlfield === fieldId;
      });
      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        if (!initControl) {
          control.controlVals.forEach((vals) => {
            vals.dependFields.forEach((dependField) => {
              this.formAttr.forEach((controlAttr) => {
                if (controlAttr.children) {
                  controlAttr.children.forEach((controlAttr) => {
                    this.clearControlValue(
                      controlAttr,
                      dependField,
                      controledFieldId,
                      controledFieldProp,
                      formData
                    );
                  });
                }
                this.clearControlValue(
                  controlAttr,
                  dependField,
                  controledFieldId,
                  controledFieldProp,
                  formData
                );
              });
            });
          });
        }

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          controledFieldId.forEach((fieldId) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === fieldId) {
                // 清空被控字段值并递归
                if (controlAttr.fieldType === "Q") {
                  this.$set(formData, controlAttr.apiname, []);
                } else {
                  this.$set(formData, controlAttr.apiname, "");
                }
                this.control(formData[controlAttr.apiname], fieldId, formData);
              }
            });
          });
        } else {
          // 控制选项列表和选项值
          controlCondition.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              //地址字段含有子级处理逻辑
              if (controlAttr.children) {
                controlAttr.children.forEach((controlAttr) => {
                  this.controlChildren(controlAttr, dependField, formData);
                });
              }
              this.controlChildren(controlAttr, dependField, formData);
            });
          });
        }

        // 控制条件
        control.controlVals.forEach(() => {});
      }
    },
    //清空字段依赖选中数据
    clearControlValue(
      controlAttr,
      dependField,
      controledFieldId,
      controledFieldProp,
      formData
    ) {
      if (controlAttr.fieldId === dependField.dependfieldid) {
        controledFieldId.push(controlAttr.fieldId);
        controledFieldProp.push(controlAttr.prop);
        this.$set(this.optionList, controlAttr.prop, []);
        if (
          controlAttr.prop &&
          controlAttr.prop.slice(controlAttr.prop.length - 2) === "01"
        ) {
          let attrField = controlAttr.prop.slice(
            0,
            controlAttr.prop.length - 2
          );
          formData[attrField + "02"] = "";
          formData[attrField + "04"] = "";
        }
      }
    },
    //字段依赖方法
    controlChildren(controlAttr, dependField, formData) {
      if (controlAttr.fieldId === dependField.dependfieldid) {
        let options = [];
        dependField.vals.forEach((element) => {
          options.push({
            key: element.val,
            val: element.deflangval,
          });
        });
        this.optionList[controlAttr.prop] = options;

        // 若当前选中值不在选项列表中，则清空，由于选项列表多选字段值是数组，选项列表和选项列表多选字段值需要区分处理
        if (controlAttr.fieldType === "Q") {
          let feildVals = JSON.parse(
            JSON.stringify(formData[controlAttr.apiname])
          );
          // 循环查找多选字段的每一个值，看是否在可选选项中，依次进行处理
          feildVals.forEach((val, index) => {
            let option = options.find((item) => {
              return item.val === val;
            });
            if (option === undefined) {
              formData[controlAttr.apiname].splice(index, 1);
            }
          });
          // 若所有值都不在可选选项中，查看字段默认值是否为可选选项
          if (formData[controlAttr.apiname].length === 0) {
            // 如果被控制字段选项中包含字段默认值，将依赖字段的值赋为默认值
            if (controlAttr.defaultValue && controlAttr.defaultValue !== "") {
              let option = options.find((item) => {
                return item.val === controlAttr.defaultValue;
              });
              if (option !== undefined) {
                this.$set(formData, controlAttr.apiname, [
                  controlAttr.defaultValue,
                ]);
              }
            }
          }

          this.control(
            formData[controlAttr.apiname],
            dependField.dependfieldid,
            formData
          );
        } else {
          let option = options.find((item) => {
            return item.val === formData[controlAttr.apiname];
          });
          if (option === undefined) {
            // 清空被控字段值并递归
            this.$set(formData, controlAttr.apiname, "");
            // 如果被控制字段选项中包含字段默认值，将依赖字段的值赋为默认值
            if (controlAttr.defaultValue && controlAttr.defaultValue !== "") {
              let option = options.find((item) => {
                return item.val === controlAttr.defaultValue;
              });
              if (option !== undefined) {
                this.$set(
                  formData,
                  controlAttr.apiname,
                  controlAttr.defaultValue
                );
              }
            }
            this.control(
              formData[controlAttr.apiname],
              dependField.dependfieldid,
              formData
            );
          }
        }
      }
    },
    // 选项列表展开时查看是否有字段依赖规则并执行
    // visibleChange(field,formData){
    //   let controlfield = this.dependContainer.find(item => {
    //     return item.id === field.fieldId
    //   })
    //   let attr = this.formAttr.forEach(item => {
    //     return item.fieldId === controlfield.controlfieldid
    //   })
    //   this.control(formData[attr.prop],controlfield.controlfieldid,formData)
    // },
    // 动态布局规则
    getDynamicLayoutListDetail(layoutId) {
      createApi
        .getDynamicLayoutListDetail({
          layoutId: layoutId,
        })
        .then((res) => {
          // 清空动态布局规则
          this.actionRequireds = [];
          this.actionShowFields = [];
          this.actionShowSections = [];
          this.actionReadonlys = [];
          this.actionConditions = res.data;

          res.data !== null &&
            res.data.forEach((action) => {
              // 重置规则控制字段
              action.mainConditions.forEach((mainCondition) => {
                // 主规则
                this.handleCondition(mainCondition, false);

                this.formAttr.forEach((attr, idx) => {
                  // 地址和地理定位字段动态布局返回的是父字段id
                  let fieldId = attr.fieldId;
                  if (attr.parentType === "AD" || attr.parentType === "LT") {
                    fieldId = attr.parentId;
                  }
                  if (fieldId === mainCondition.fieldId) {
                    let changeEvent =
                      attr.changeEvent === undefined
                        ? "handleAction"
                        : `${attr.changeEvent},handleAction`;
                    this.$set(this.formAttr[idx], "changeEvent", changeEvent);
                  }
                });

                // 二级规则
                mainCondition.dynamicPageConditions &&
                  mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                    this.handleCondition(dynamicPage, false);
                    //
                    dynamicPage.twoLevelConditions.forEach(
                      (twoLevelCondition) => {
                        this.formAttr.forEach((attr, idx) => {
                          // 地址和地理定位字段动态布局返回的是父字段id
                          let fieldIdTwo = attr.fieldId;
                          if (
                            attr.parentType === "AD" ||
                            attr.parentType === "LT"
                          ) {
                            fieldIdTwo = attr.parentId;
                          }
                          if (fieldIdTwo === twoLevelCondition.fieldId) {
                            let changeEvent =
                              attr.changeEvent === undefined
                                ? "handleAction"
                                : `${attr.changeEvent},handleAction`;
                            this.$set(
                              this.formAttr[idx],
                              "changeEvent",
                              changeEvent
                            );
                          }
                        });
                      }
                    );
                  });
              });
            });

          // 走规则
          this.handleAction();
        });
    },
    // 重置规则控制字段
    handleCondition(condition, flag) {
      // 设置字段/部分 是否显示/必填 注：部分下有必填字段时不隐藏该部分
      // 当flag为真时，规则中只要有一个条件满足，该字段显示/必填就为真
      // 每个控制字段变化时所有规则重新走一遍
      if (flag) {
        if (condition.action_requireds) {
          condition.action_requireds.forEach((requiredField) => {
            //  如果动态布局不是只读的，判断该字段动态布局是否是必填的，需要结合动态布局是否是必填的，如果是必填的，那么就是必填，只读为false。动态布局优先级永远是大于原布局
            this.actionRequireds.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.required = item.required || flag;
              }
            });
          });
        }
        if (condition.action_readonlys) {
          condition.action_readonlys.forEach((readonlysField) => {
            this.actionReadonlys.forEach((item) => {
              if (item.fieldId === readonlysField.field_id) {
                item.readonlys = item.readonlys || flag;
              }
            });
          });
        }
        if (condition.action_showFields) {
          condition.action_showFields.forEach((requiredField) => {
            this.actionShowFields.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.show = item.show || flag;
              }
            });
          });
        }
        if (condition.action_showSections) {
          condition.action_showSections.forEach((requiredField) => {
            this.actionShowSections.forEach((item) => {
              if (item.selectionId === requiredField.section_id) {
                item.showSection = item.showSection || flag;
              }
            });
          });
        }
      } else {
        // 初始化需要去重
        // 字段显示与否
        if (condition.action_showFields) {
          let showFields = [];
          condition.action_showFields.forEach((requiredField) => {
            if (!showFields.includes(requiredField.field_id)) {
              showFields.push(requiredField.field_id);
              this.actionShowFields.push({
                fieldId: requiredField.field_id,
                show: flag,
              });
            }
          });
        }
        // 部分显示与否
        if (condition.action_showSections) {
          let showSections = [];
          condition.action_showSections.forEach((requiredField) => {
            if (!showSections.includes(requiredField.section_id)) {
              showSections.push(requiredField.section_id);
              this.actionShowSections.push({
                sectionName: requiredField.sectionName,
                selectionId: requiredField.section_id,
                showSection: flag,
              });
            }
          });
        }
        // 字段必填与否
        if (condition.action_requireds) {
          let requireds = [];
          condition.action_requireds.forEach((requiredField) => {
            if (!requireds.includes(requiredField.field_id)) {
              requireds.push(requiredField.field_id);
              this.actionRequireds.push({
                fieldId: requiredField.field_id,
                required: flag,
              });
            }
          });
        }
        // 只读字段

        if (condition.action_readonlys) {
          let readonlys = [];
          condition.action_readonlys.forEach((readonlysField) => {
            if (!readonlys.includes(readonlysField.field_id)) {
              readonlys.push(readonlysField.field_id);
              this.actionReadonlys.push({
                fieldId: readonlysField.field_id,
                readonlys: flag,
              });
            }
          });
        }
      }
    },
    /**
     * 判断高级条件是否成立
     * @param {Array} booleanList:条件成立结果组成的数组
     * @param {String} label:高级条件的label，表示高级条件的组合关系
     * 
     */
     isJudgment(booleanList,label){
      let isTrue = false
      if(label){
        // 去掉1 and 
        label = label.slice(5)
        // 替换and和or
        label = label.replace(/and/g,'&&')
        label = label.replace(/or/g,'||')
        // 替换每个条件的执行
        booleanList.forEach((item,index) => {
          let labelIndex = new RegExp((index+2).toString())
          label = label.replace(labelIndex,item)
        })
        isTrue = eval(label)
      }
      return isTrue
    },
    // 走规则
    handleAction() {
      // 清空动态布局规则
      this.actionRequireds = [];
      this.actionShowFields = [];
      this.actionShowSections = [];
      this.actionReadonlys = [];

      this.actionConditions.forEach((action) => {
        // 重置规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          // 主规则
          this.handleCondition(mainCondition, false);

          // 二级规则
          mainCondition.dynamicPageConditions &&
            mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
              this.handleCondition(dynamicPage, false);
            });
        });
      });

      this.actionConditions.forEach((action) => {
        // 循环规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          let judgment = this.judgValue(
            mainCondition.fieldName,
            mainCondition.operator,
            mainCondition.value,
            mainCondition.fieldType
          );
          if (judgment) {
            this.handleCondition(mainCondition, true);
            // 二级规则 - 符合一级规则时才走二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                // 二级条件执行
                let judgment = [];
                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  let judfment2= this.judgValue(
                      twoLevelCondition.fieldName,
                      twoLevelCondition.operator,
                      twoLevelCondition.value,
                      twoLevelCondition.fieldType
                    );
                    
                  judgment.push(judfment2)
                });
                // 执行高级条件
                if (this.isJudgment(judgment,dynamicPage.label)) {
                  this.handleCondition(dynamicPage, true);
                }
              });
          }
        });
      });
      // 根据规则设置字段显示/隐藏
      this.actionShowFields.forEach((actionShowField) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionShowField.fieldId) {
            this.$set(attr, "hidden", !actionShowField.show);
          }
        });
      });

      // 设置部分显示/隐藏
      this.actionShowSections.forEach((actionShowSection) => {
        if (actionShowSection.sectionName) {
          this.formAttr.forEach((attr) => {
            // 控制某部分标题显示/隐藏
            // if (attr.title === actionShowSection.sectionName || attr.groupTitle == actionShowSection.sectionName) {
            if (attr.selectionId === actionShowSection.selectionId) {
              if (actionShowSection.showSection) {
                attr.hidden = false;
                attr.groupShow = true;
              } else {
                // 动态布局下只能设置条件符合时部分显示、字段显示、字段只读、字段必填
                // 如果动态规则条件不成立，原布局部分下有必填字段时不隐藏该部分
                let requiredFields = this.formAttr.find((item) => {
                  return (
                    item.selectionId === actionShowSection.selectionId &&
                    item.required
                  );
                });
                if (requiredFields !== undefined) {
                  // 当动态布局规则不满足，需要隐藏部分，但部分下有必填字段时，此时只控制部分名称显示，部分下的字段显示与否不变
                  if (attr.type === "groupTitle") {
                    attr.hidden = false;
                    attr.groupShow = true;
                  }
                } else {
                  attr.hidden = true;
                  attr.groupShow = false;
                }
              }
            }
          });
        }
      });

      // 必须先走字段必填，在走字段只读
      // 根据规则设置字段必填
      this.actionRequireds.forEach((actionRequire) => {
        // 需要判断该字段动态布局是否只读,并且只读条件成立，或者原布局该字段只读
        // 该字段动态布局是否只读,并且只读条件成立
        let flag = this.actionReadonlys.find((item) => {
          return item.fieldId === actionRequire.fieldId && item.readonlys;
        });

        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          // 是否是地址字段
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionRequire.fieldId) {
            // 判断该字段动态布局或者原布局是否只读
            if (flag == undefined && attr.editCopy) {
              // 只读不成立
              // 判断动态必填或者原布局必填是否成立
              if (attr.requiredCopy || actionRequire.required) {
                attr.rules = [
                  {
                    required: true,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      attr.fieldLabel
                    }`,
                    trigger: "blur",
                    // 请输入
                  },
                ];
                // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段无规则
                // 清空必填，设置可编辑
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      //                 控制字段只读
      this.actionReadonlys.forEach((actionReadonly) => {
        // 详情页活动等快速新建事件、任务对象时,名称和相关项字段只读,此时不走动态布局中的字段只读规则
        if (
          !(
            (this.isActive === "active" ||
              this.standardHomePage === "homepage" ||
              this.calendarData.id) &&
            [
              "ffe2014061332890a8YD",
              "ffe2014000032890C8YD",
              "ffe4102061332890a8YD",
              "ffe4102000032890C8YD",
            ].indexOf(actionReadonly.fieldId) !== -1
          )
        ) {
          this.formAttr.forEach((attr) => {
            let fieldId = attr.fieldId;
            if (attr.parentType == "AD" || attr.parentType == "LT") {
              // 地址和地理定位字段时需要对比attr上的父字段
              fieldId = attr.parentId;
            }
            if (fieldId === actionReadonly.fieldId) {
              // 原字段只读或者动态规则只读条件成立
              if (!attr.editCopy || actionReadonly.readonlys) {
                attr.edit = false;
                attr.rules = [];
              } else {
                // 只读不成立，判断原布局或动态布局必填
                let flag = this.actionRequireds.find((item) => {
                  return (
                    item.fieldId === actionReadonly.fieldId && item.required
                  );
                });
                if (attr.requiredCopy || flag !== undefined) {
                  attr.rules = [
                    {
                      required: true,
                      message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                        attr.fieldLabel
                      }`,
                      trigger: "blur", // 请输入
                    },
                  ]; // 改变只读,设置可编辑
                  attr.edit = true;
                } else {
                  // 该字段没有规则
                  attr.edit = true;
                  attr.rules = [];
                }
              }
            }
          });
        }
      });

      this.$refs.baseForm && this.$refs.baseForm.resetRules();
    },
    // 判断值
    judgValue(field, operator, value, fieldType) {
      if (
        this.$refs.baseForm &&
        (this.$refs.baseForm.formData[field] ||
          this.$refs.baseForm.formData[field] === 0)
      ) {
        if (fieldType === "Q") {
          //  以后组件值是数组的都要走这里
          //  多选列表选中的值
          let valueAry = this.$refs.baseForm.formData[field];
          // 主字段设置的数组
          let actionAry = value.split(",");
          // 多选列表选中值中被主字段设置的数据包含的值
          let filterAry = actionAry.filter((item) => {
            return valueAry.indexOf(item) > -1;
          });
          switch (operator) {
            case "e":
              // 等于(全包含，并且数组长度都一样)
              return (
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );
            case "n":
              // 不等于
              return !(
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );

            case "c":
              // 包含（只要有其中一个条件就成立）
              return filterAry.length > 0;
            case "k":
              // 不包含
              return filterAry.length === 0;
          }
        } else {
          // 判断字段类型
          fieldType = fieldType ? INPUTTYPE[fieldType] : fieldType;
          // 获取表单中该字段的值
          let formDateField = this.$refs.baseForm.formData[field].toString();

          if (fieldType === "date" || fieldType === "datetime") {
            // 处理日期、日期时间字段
            formDateField = new Date(formDateField).getTime().toString();
            value = new Date(value).getTime().toString();
          }

          if (fieldType === "time") {
            // 处理时间字段
            let formField = formDateField.split(":");
            let valueField = value.split(":");
            formDateField = (
              Number(formField[0] ? formField[0] : "") * 3600 +
              Number(formField[1] ? formField[1] : "") * 60 +
              Number(formField[2] ? formField[2] : "")
            ).toString();

            value = (
              Number(valueField[0] ? valueField[0] : "") * 3600 +
              Number(valueField[1] ? valueField[1] : "") * 60 +
              Number(valueField[2] ? valueField[2] : "")
            ).toString();
          }
          switch (operator) {
            case "e":
              // 等于
              return formDateField === value;
            case "n":
              // 不等于
              return formDateField !== value;
            case "l":
              // 小于
              return Number(formDateField) < Number(value);
            case "g":
              // 大于
              return Number(formDateField) > Number(value);
            case "m":
              // 小于或等于
              return Number(formDateField) <= Number(value);
            case "h":
              // 大于或等于
              return Number(formDateField) >= Number(value);
            case "c":
              // 包含
              return formDateField.includes(value);
            case "k":
              // 不包含
              return !formDateField.includes(value);
            case "s":
              // 起始字符
              return formDateField.indexOf(value) === 0;
          }
        }
      } else {
        // 如果是不包含、不等于返回true，其他false
        let flag = operator === "n" || operator === "k";
        // 如果value为空，此时operator是e（等于）返回为true
        if (operator === "e" && !value && value !== 0) {
          flag = true;
        }
        return flag;
      }
    },
    // 表单添加属性
    // parentAttr:父字段内容
    addFormAttr(group, colNum, attr, isChildren, position, parentAttr, type) {
      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      // 单独存储备用原布局中的required和edit,动态布局设置规则的时候使用
      attr.requiredCopy = attr.required;
      attr.editCopy = attr.edit;
      if (attr.required && attr.edit) {
        if (this.objectApi === "Contact" && this.contactes === "contactsNew") {
          if (attr.apiname === "loginname") {
            attr.rules = [
              {
                required: attr.required,
                message: `${this.$i18n.t(
                  "label_tabpage_placeenterz"
                )} ${this.$i18n.t("label.email")}`,
                trigger: "blur",
                // 请输入
              },
            ];
          } else {
            attr.rules = [
              {
                required: attr.required,
                message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                  attr.fieldLabel || attr.label
                }`,
                trigger: "blur",
                // 请输入
              },
            ];
          }
        } else {
          attr.rules = [
            {
              required: attr.required,
              message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                attr.fieldLabel || attr.label
              }`,
              trigger: "blur",
              // 请输入
            },
          ];
        }
      }
      attr.prop =
        isChildren && isChildren !== undefined ? attr.name : attr.apiname;
      if (
        parentAttr &&
        (parentAttr.fieldType == "AD" || parentAttr.fieldType == "LT")
      ) {
        // 如果父字段是地址和地理定位字段，存储父字段fieldId
        attr.parentId = parentAttr.fieldId;
        attr.parentType = parentAttr.fieldType;
      }
      // 新建回款特殊新增时
      if (this.isReceivable || this.isOrderPage) {
        // 日期默认设置
        if (
          (attr.prop === "paymentdate" || attr.prop === "invoice_date") &&
          this.dialogAttr.type === "NEW"
        ) {
          let date = new Date();
          let year = date.getFullYear(); // 得到年份
          let month = date.getMonth(); // 得到月份
          let dates = date.getDate(); // 得到日期
          month = month + 1;
          month = month.toString().padStart(2, "0");
          dates = dates.toString().padStart(2, "0");
          let defaultDate = `${year}-${month}-${dates}`;
          attr.value = defaultDate;
          this.$set(this.optionList, attr.prop, date);
        }
      }
      // 给字段设置默认值
      if (this.dialogAttr.type !== "EDIT" && this.dialogAttr.type !== "COPY") {
        // 非查找/查找多选字段
        if (attr.defaultValue && attr.defaultValue !== "") {
          if (attr.prop === "currency") {
            if (this.isOrderPage) {
              attr.value = this.currencyVal;
              attr.edit = false;
            } else {
              attr.value = attr.defaultValue;
              this.currencyVal = attr.defaultValue;
            }
          } else {
            attr.value = attr.defaultValue;
          }
        }
      }
      if (
        attr.fieldType !== "AD" &&
        attr.fieldType !== "LT" &&
        !attr.children
      ) {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );

        // 百分数字段字数长度限制
        if (attr.fieldType === "P") {
          attr.precision = Number(attr.decimalPlaces);
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        }
        // 图片显示
        if (
          attr.fieldType === "IMG" &&
          attr.expressionType !== "url" &&
          attr.value !== undefined &&
          this.dialogAttr.type === "EDIT"
        ) {
          if (attr.value !== "") {
            attr.data = [
              {
                name: "",
                url: `${this.userUrl}/querysome.action?m=viewImg&fileid=${attr.value}&binding=${this.token}`,
              },
            ];
          } else {
            attr.data = [];
          }
        }
        // 评分新建时评分
        if (attr.type === "score") {
          attr.value =
            this.dialogAttr.type !== "EDIT"
              ? attr.value
                ? Number(attr.value)
                : 0
              : Number(attr.value);
        }
        // 选项列表和选项列表多选
        if (attr.type === "select" || attr.type === "multi-select") {
          let options = [];
          // 记录类型可改
          // if (attr.prop === "recordtype" && (this.dialogAttr.type === "EDIT" || this.dialogAttr.type === "COPY")) {
          //   this.recordType = attr.id
          // } else if (attr.prop === "recordtype" && this.dialogAttr.type === "NEW") {
          //   // 新建时若有记录类型字段，设置当前记录类型到表单中
          //   this.$set(attr, "value", this.recordType)
          // }

          // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
          if (
            attr.name &&
            attr.name !== "name00" &&
            attr.name.slice(attr.name.length - 2) === "00"
          ) {
            this.getCountrySelectValue(attr.name);
          } else {
            attr.data &&
              attr.data.length > 0 &&
              attr.data.forEach((item) => {
                options.push({
                  key: item.value,
                  val: item.id,
                });
              });

            this.optionList[attr.prop] = options;
          }

          // 设置表单记录类型选项
          if (attr.prop === "recordtype") {
            this.optionList[attr.prop] = [
              {
                key: attr.value,
                val: attr.id,
              },
            ];
          }
          if (attr.type === "multi-select") {
            attr.value =
              // 无
              attr.value === "" ||
              attr.value === null ||
              attr.value === undefined
                ? []
                : attr.value.split(";");
          }
        } else if (
          attr.type === "remote-select" ||
          attr.type === "remote-multi-select"
        ) {
          // 赋值默认值方法
          // 查找多选字段选项设置
          if (
            attr.fieldType === "MR" &&
            attr.value &&
            attr.value.length !== 0 &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            let multiOptions = [];
            let labels = attr.lookupmutidata;
            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index].name,
                value: labels[index].id,
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            // 无
            attr.value =
              (attr.value === attr.data[0].value) === ""
                ? []
                : attr.value.split(";");
          } else {
            // 查找字段选项设置
            let multiOptions = [];
            //启用外部用户的联系人
            if (attr.apiname === "usercontact") {
              multiOptions.push({
                label: attr.usercontactName,
                value: attr.value,
              });
            } else {
              // 查找字段对应的记录有对应的value时才展示
              if (
                attr.value !== "" &&
                attr.data &&
                attr.data.length > 0 &&
                attr.data[0].value !== ""
              ) {
                multiOptions.push({
                  label: attr.data[0].value,
                  value: attr.data[0].id,
                });
              } else {
                if (
                  attr.apiname === "marketsea" &&
                  this.dialogAttr.type === "NEW" &&
                  this.isClientPoolEditStatus === false
                ) {
                  // 公海池的特殊字段，客户公海池和潜在客户公海池新建时才默认赋值
                  // 公海池的新建时 选中默认的当前的公海池 option
                  // this.objectApi = this.$route.params.objectApi;
                  if (
                    this.objectApi !== "Lead" &&
                    this.objectApi !== "cloudcclead"
                  ) {
                    multiOptions.push({
                      label: this.currentClientPoolSelectObj.name,
                      value: this.currentClientPoolSelectObj.id,
                    });
                    attr.value = this.currentClientPoolSelectObj.id;
                  }
                } else {
                  attr.value = attr.fieldType === "MR" ? [] : "";
                }
              }
            }

            // 创建人和最后修改人选项
            if (
              attr.apiname === "createbyid" ||
              attr.apiname === "lastmodifybyid"
            ) {
              multiOptions.push({
                value: attr.value,
                label: attr[`${attr.apiname}Name`],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }

          // 新建时设置默认值和选项
          if (
            this.dialogAttr.type !== "EDIT" &&
            attr.defaultValue &&
            attr.defaultValue !== "" &&
            attr[`${attr.apiname}Name`] &&
            attr[`${attr.apiname}Name`] !== "" &&
            (attr.fieldType === "Y" || attr.fieldType === "M")
          ) {
            let option = [
              {
                label: attr[`${attr.apiname}Name`],
                value: attr.defaultValue,
              },
            ];
            this.$set(attr, "value", attr.defaultValue);
            this.$set(this.optionList, attr.prop, option);
          }
          // attr.readonly = true;
          if (this.checkedLists.length !== 0) {
            if (
              attr.prop === "account_name" &&
              this.dialogAttr.type === "NEW"
            ) {
              attr.value = this.orderInfo.contract.id;
              let options = [
                {
                  label: this.orderInfo.contract.value,
                  value: this.orderInfo.contract.id,
                },
              ];
              this.$set(this.optionList, attr.prop, options);
            }
            // 订单默认值
            if (
              attr.prop === "cloudccorder" &&
              this.dialogAttr.type === "NEW"
            ) {
              let options = [];
              let id = [];
              this.checkedLists.forEach((item) => {
                id.push(item.id);
                options.push({
                  label: item.name,
                  value: item.id,
                });
              });
              attr.value = id;
              this.orderToInvoiceId = id;
              this.$set(this.optionList, attr.prop, options);
            }
          }
          if (this.isOrderPage && this.checkedLists.length == 0) {
            if (
              attr.prop === "account_name" &&
              this.dialogAttr.type === "NEW"
            ) {
              attr.value = this.orderInfo.contract.id;
              let options = [
                {
                  label: this.orderInfo.contract.value,
                  value: this.orderInfo.contract.id,
                },
              ];
              this.$set(this.optionList, attr.prop, options);
            }
            // 订单默认值
            if (
              attr.prop === "cloudccorder" &&
              this.dialogAttr.type === "NEW"
            ) {
              attr.value = [this.$route.params.id];
              let options = [
                {
                  label: this.orderInfo.orderlabel,
                  value: this.$route.params.id,
                },
              ];
              this.orderToInvoiceId = [this.$route.params.id];
              this.$set(this.optionList, attr.prop, options);
            }
          }
          // 新建回款特殊新增时
          if (this.isReceivable) {
            // 客户默认设置
            if (
              attr.prop === "account" &&
              this.dialogAttr.type === "NEW" &&
              this.invoiceInfo.customer
            ) {
              attr.value = this.invoiceInfo.customer.id;
              let options = [
                {
                  label: this.invoiceInfo.customer.value,
                  value: this.invoiceInfo.customer.id,
                },
              ];
              this.$set(this.optionList, attr.prop, options);
            }
          }
          // 新增时默认 所有人 为当前登录用户
          if (
            attr.prop === "ownerid" &&
            (this.dialogAttr.type === "NEW" || this.dialogAttr.type === "COPY")
          ) {
            // 公海池的特殊字段，客户公海池和潜在客户公海池新建时才所有人是所属公海池
            if (
              this.pageFlag === "clientPool" &&
              this.dialogAttr.type === "NEW"
            ) {
              // 存储所有人信息，当布局中存在公海池所有人时，回显当前值
              this.clientPoolOwner.value = attr.defaultValue;
              this.clientPoolOwner.options = [
                {
                  label: attr.owneridName,
                  value: attr.defaultValue,
                },
              ];
            }
            attr.value = this.currentUserInfo.id;
            let options = [
              {
                label: this.currentUserInfo.name,
                value: this.currentUserInfo.id,
              },
            ];
            this.$set(this.optionList, attr.prop, options);
          }
        } else if (attr.type === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        } else if (attr.type === "checkbox") {
          attr.value =
            attr.value === "true" || attr.value === "1" ? true : false;
          //如果当前是新建客户地址
          if (attr.apiname == "billingaddress") {
            attr.defaultAddress =
              this.customerAddress.defaultBilling != undefined
                ? this.customerAddress.defaultBilling
                : false;
          } else if (attr.apiname == "shippingaddress") {
            attr.defaultAddress =
              this.customerAddress.defaultShipping != undefined
                ? this.customerAddress.defaultShipping
                : false;
          }
        }
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.colNum = colNum;
        // colNum === 1 || attr.type === "kindeditor" ? "100%" : "50%";

        attr.groupTitle = group.title;
        attr.selectionId = group.selectionId;
        attr.groupShow = true;
        //判断是否为地址字段子级
        if (type == "address") {
          this.addrArr.push(attr);
        } else {
          this.attrTemp.push(attr);
        }
        // 标签和新建时自动编号字段不展示，空位留出(更改需求，自动编号字段展示)
        // if (
        //   attr.apiname !== "cloudcctag" &&
        //   !(this.dialogAttr.type !== "EDIT" && attr.fieldType === "V")
        // ) {
        //   this.attrTemp.push(attr);
        // } else {
        //   this.attrTemp.push({
        //     width: "50%",
        //     colNum: 2,
        //     attrIndex: this.attrIndex,
        //     type: "noData",
        //     hidden: false,
        //     groupShow: true,
        //     selectionId: group.selectionId,
        //     groupTitle: group.title,
        //   });
        // }
      } else {
        if (attr.fieldType === "AD") {
          attr.isContrField = true;
          this.addrArr = [];
          // 地址和地理定位特殊处理
          attr.groupShow = true;
          attr.label = attr.fieldLabel;
          attr.children.forEach((item) => {
            if (
              (item.name && item.name.slice(-9) === "longitude") ||
              (item.name && item.name.slice(-8) === "latitude")
            ) {
              this.$set(item, "type", "noData");
              this.$set(item, "hidden", true);
            }
            this.addFormAttr(
              group,
              colNum,
              item,
              true,
              position,
              attr,
              "address"
            );
          });
          attr.children = this.addrArr;
          this.attrTemp.push(attr);
        } else {
          attr.children.forEach((item) => {
            if (
              (item.name && item.name.slice(-9) === "longitude") ||
              (item.name && item.name.slice(-8) === "latitude")
            ) {
              this.$set(item, "type", "noData");
              this.$set(item, "hidden", true);
            }
            //订单发票新建时地址字段只读保存后自动赋值
            // if((this.objectApi == 'cloudccorder' || this.objectApi == 'Invoices') && this.dialogAttr.type == "NEW"){
            // this.$set(item, 'placeholder', this.$t('c566'));
            // this.$set(item, 'edit', false);
            // }
            this.addFormAttr(group, colNum, item, true, position, attr);
          });

          this.attrIndex -= attr.children.length - 1;
        }
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
      if (this.dialogAttr.type === "NEW" && this.isOrderPage) {
        // 价格手册自动带值
        if (attr.apiname === "pricebook2id") {
          this.pricSelect = this.orderInfo.pricebook2id.id;
          attr.edit = false;
          if (
            this.orderInfo.pricebook2id.id &&
            this.orderInfo.pricebook2id.id !== ""
          ) {
            attr.value = this.orderInfo.pricebook2id.id;
            let options = [
              {
                label: this.orderInfo.pricebook2id.value,
                value: this.orderInfo.pricebook2id.id,
              },
            ];
            this.$set(this.optionList, attr.prop, options);
          }
        }
        if (this.checkedLists.length === 0) {
          //订单生成发票  地址字段处理
          if (attr.name) {
            let str = attr.name;
            if (str.indexOf("billing") !== -1) {
              this.orderInfo.billingaddress.forEach((list) => {
                str = str.replace("_", "");
                if (list.name === str) {
                  attr.value = list.value;
                }
              });
            } else if (str.indexOf("ship") !== -1) {
              this.orderInfo.shippingaddress.forEach((list) => {
                str = str.replace("_", "ping");
                if (list.name === str) {
                  attr.value = list.value;
                }
              });
            }
          }
        } else if (this.checkedLists.length > 0) {
          if (attr.apiname === "currency") {
            attr.edit = false;
            if (this.orderInfo.currency && this.orderInfo.currency !== "") {
              attr.value = this.orderInfo.currency;
            }
          }
        }
      }
      // 如果为事件或任务类型，默认分配当前登录用户，特殊处理名称及相关项
      if (
        this.objId === "event" ||
        this.objId === "task" ||
        this.objectApi === "Event" ||
        this.objectApi === "Task"
      ) {
        // 针对lightning版本：
        // 1、针对任务、事件两个对象中的“主题”字段，遵循后台配置，字段类型保持选项列表类型，且可根据配置选择选项。样式使用选项两列表类型的展示与操作样式；
        // 2、针对任务、事件两个对象中的“主题”字段，变更为选项列表类型字段后，同时也支持直接输入内容，并将输入的内容保存。
        if (attr.prop === "subject") {
          attr.type = "specialTypeSubject";
        }
        // 如果操作为新建，填充部分默认值
        if (this.dialogAttr.type === "NEW" || this.dialogAttr.type === "COPY") {
          // 被分配人字段重新复制
          if (attr.prop === "belongtoid") {
            attr.fieldType = "MR";
            attr.type = "remote-multi-select";
            if (this.dialogAttr.type === "NEW") {
              // 使用日历视图传过来的值
              if (this.calendarUser.userId) {
                attr.value = [this.calendarUser.userId];
                this.optionList.belongtoid = [
                  {
                    value: this.calendarUser.userId,
                    label: this.calendarUser.userName,
                  },
                ];
              } else {
                attr.value = [this.currentUserInfo.id];
                this.optionList.belongtoid = [
                  {
                    value: this.currentUserInfo.id,
                    label: this.currentUserInfo.name,
                  },
                ];
              }
            } else if (this.dialogAttr.type === "COPY") {
              attr.value = [attr.value];
              // this.optionList.belongtoid = [];
            }
            this.getRelatedItem();
          } else if (attr.prop === "begintime") {
            attr.value = this.eventDate.start || this.getHour().start;
          } else if (attr.prop === "endtime") {
            attr.value = this.eventDate.end || this.getHour().end;
          } else if (attr.prop === "whoid") {
            // 判断对象是否事件/任务
            if (this.objectApies !== "") {
              if (
                this.objectApies === "Lead" ||
                this.objectApies === "cloudcclead" ||
                this.objectApies === "Contact"
              ) {
                if (this.bigTitle.length !== 0) {
                  let child = {};
                  let obj = {
                    whoname: this.bigTitle[0].fieldValue,
                    whoobj: this.objectNames,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.recordIds;
                }
              }
            }

            // 判断是否主页事件、任务等要赋值的对象
            else if (this.homeNweObjid !== "") {
              if (
                this.homeNweObjid === "Lead" ||
                this.homeNweObjid === "cloudcclead" ||
                this.homeNweObjid === "Contact"
              ) {
                if (this.homeNweName) {
                  let child = {};
                  let obj = {
                    whoname: this.homeNweName,
                    whoobj: this.homeContactName,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.homeNweId;
                }
              }
            }

            // 来自详情页跳转日历新建事件，回显处理
            else if (this.calendarData.id) {
              if (
                this.calendarData.prefix === "003" ||
                this.calendarData.prefix === "004"
              ) {
                let child = {};
                let obj = {
                  whoname: this.calendarData.name,
                  whoobj: this.calendarData.tabName,
                };
                child = obj;
                attr.child = child;
                attr.value = this.calendarData.id;
              }
            }
          } else if (attr.prop === "relateid") {
            if (this.objectApies !== "") {
              if (
                this.objectApies !== "Lead" &&
                this.objectApies !== "cloudcclead" &&
                this.objectApies !== "Contact"
              ) {
                if (this.bigTitle.length !== 0) {
                  let child = {};
                  let obj = {
                    relatename: this.bigTitle[0].fieldValue,
                    relateobj: this.objectNames,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.recordIds;
                }
              }
            } else if (this.homeNweObjid !== "") {
              if (
                this.homeNweObjid !== "Lead" &&
                this.homeNweObjid !== "cloudcclead" &&
                this.homeNweObjid !== "Contact"
              ) {
                if (this.homeNweName) {
                  let child = {};
                  let obj = {
                    relatename: this.homeNweName,
                    relateobj: this.homeContactName,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.homeNweId;
                }
              }
            } else if (this.calendarData.id) {
              if (
                this.calendarData.prefix !== "003" &&
                this.calendarData.prefix !== "004"
              ) {
                attr.child = {
                  relatename: this.calendarData.name,
                  relateobj: this.calendarData.tabName,
                };
                attr.value = this.calendarData.id;
              }
            }
          }
        } else {
          if (attr.prop === "belongtoid") {
            attr.fieldType = "MR";
            attr.type = "remote-multi-select";
            attr.value = [attr.value];
            this.getRelatedItem();
          }
        }
      }
      // 判断为轮班，处理传入时间和服务区域、服务资源
      if (this.objectApi === "Shift") {
        if (this.dialogAttr.type === "NEW" && this.shiftData.date) {
          if (attr.prop === "starttime") {
            attr.value = this.shiftData.date.startTime || "";
          } else if (attr.prop === "endtime") {
            attr.value = this.shiftData.date.endTime || "";
          } else if (attr.prop === "serviceresourceid") {
            // 服务资源
            attr.value = this.shiftData.resouce.titleId || "";
            this.optionList.serviceresourceid = [
              {
                value: this.shiftData.resouce.titleId || "",
                label: this.shiftData.resouce.title || "",
              },
            ];
          } else if (attr.prop === "serviceterritoryid") {
            // 服务区域
            attr.value = this.shiftData.area.id || "";
            this.optionList.serviceterritoryid = [
              {
                value: this.shiftData.area.id || "",
                label: this.shiftData.area.name || "",
              },
            ];
          }
        }
      }
      // 企业微信联系人转联系人或潜在客户专用---------
      if (this.objectApi == "Contact") {
        if (attr.apiname == "qywxlxr") {
          attr.value = this.ewId;
          attr.edit = false;
        }
        for (let i in this.ewData) {
          if (attr.apiname == i) {
            attr.value = this.ewData[i];
          }
        }
      }
      if (this.objectApi == "Lead" || this.objectApi == "cloudcclead") {
        if (attr.apiname == "qywxlxr") {
          attr.value = this.ewId;
          attr.edit = false;
        }
        for (let i in this.ewData) {
          if (attr.apiname == i) {
            attr.value = this.ewData[i];
          }
        }
      }
      // 企业微信联系人转联系人或潜在客户专用---------
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue(filedName) {
      createApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              val: item.codekey,
              key: item.codevalue,
            });
          });
          this.$set(this.optionList, filedName, options);
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    notZhucongfield() {
      this.isZhucongfield = false;
    },
    // 查找/查找多选
    remoteSearch(apiname, item, num, ind, relatedObjPrefix) {
      // 采购订单选择产品
      if (
        (this.isOrderPage || this.orderToInvoiceId.length !== 0) &&
        (apiname === "orderid" || apiname === "product2id")
      ) {
        return;
      } else {
        this.idx = num;
        this.ind = ind;
        this.rowIndex = num + 1;
        this.apiname = apiname;
        this.fieldId = item.id;
        this.isZhucongfield = true;
        this.showSearchTable = true;
        this.relevantPrefix = item.lookupObj;
        this.relevantObjId = item.lookupObjid;
        this.relatedObjPrefix = relatedObjPrefix;
        this.checked = item.type === "remote-multi-select" ? true : false;
        if (
          (apiname = "product" && this.objectApi === "cloudccpurchaseorder")
        ) {
          if (this.$refs.baseForm.formData.vendorname) {
            this.vendorid = this.$refs.baseForm.formData.vendorname;
          } else {
            this.vendorid = "";
          }
        }
        this.$nextTick(() => {
          this.$refs.searchTable.page = 1;
          this.$refs.searchTable.pageObj.dataList = [];
          this.$refs.searchTable.searchConditions = "";
          this.$refs.searchTable.init();
        });
      }
    },
    // 设置查找筛选条件
    setFieldReltaion(filterFieldIds) {
      let values = [];
      let dataId =
        this.dataLists[this.ind][this.rowIndex - 1].id === undefined
          ? ""
          : this.dataLists[this.ind][this.rowIndex - 1].id.value;
      values.push(dataId);
      for (let reltaion in filterFieldIds) {
        this.$refs.EditableCell.forEach((editableCell) => {
          if (
            editableCell.lineIndex === this.rowIndex - 1 &&
            editableCell.fieldId === filterFieldIds[reltaion].fieldid
          ) {
            if (Array.isArray(editableCell.editValue)) {
              values.push(editableCell.editValue.join(";"));
            } else {
              values.push(editableCell.editValue);
            }
          }
        });
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    closeSearchTable() {
      this.showSearchTable = false;
    },
    // 新增/修改保存
    save(flag) {
      this.$cookies.set("workinghuorss", "", { sameSite: 'Strict' });
      if (this.subordinateFields.length > 0) {
        this.savemessages();
        if (!this.validRelatedObj) {
          // 请检查必填项
          this.$message.warning(
            this.$i18n.t("label.batchadd.newbatchpage.save.emptytext")
          );
          this.verifyJumpFn(this.nullElement, false);
          return;
        }
        if (this.isRepeatTime) {
          // 提示有重复
          this.$message.warning(this.$i18n.t("label.Time.slot.overlap"));
          return;
        }
      }
      this.$refs.baseForm.$refs.elForm.validate((valid, object) => {
        if (valid) {
          // 保存按钮loading
          this.isSaveBtnLoadin = true;
          let data = Object.assign({}, this.$refs.baseForm.formData);
          let dataList = [];
          // 数字字段特殊处理
          for (const key in data) {
            this.formAttr.forEach((attr) => {
              //数字类型保存传值（小数位不够时补0）
              if (attr.type === "number" && data[attr.apiname]) {
                data[attr.apiname] = parseFloat(data[attr.apiname]).toFixed(
                  attr.precision
                );
              }
              if (
                attr.prop === key &&
                data[key] === undefined &&
                (attr.type === "number" || attr.type === "percent-number")
              ) {
                data[key] = "";
              }
            });
          }
          delete data.undefined;
          // 潜在客户和个案使用有效的分配规则进行分配
          if (
            this.objectApi === "Lead" ||
            this.objectApi === "cloudcclead" ||
            this.objectApi === "cloudcccase"
          ) {
            data.case_assiGNment = this.validRule ? "true" : "false";
          }

          for (const key in data) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(";");
              // 无
            } else if (
              data[key] === `--${this.$i18n.t("label.data.newtemplate.null")}--`
            ) {
              this.formAttr.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  data[key] = "";
                }
              });
            } else if (data[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (data[key] !== undefined && data[key] !== null) {
                data[key] = data[key].toString();
              } else {
                data[key] = "";
              }
            } else if (data[key] === undefined) {
              this.formAttr.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  data[key] = "";
                }
              });
            }
          }
          if (
            this.dialogAttr.type !== "NEW" &&
            this.dialogAttr.type !== "COPY"
          ) {
            data.id = this.id;
          } else {
            // 新增时，保存记录 记录类型保证传参

            if (
              !("recordtype" in data) &&
              this.recordType !== null &&
              this.recordType !== "null"
            ) {
              this.$set(data, "recordtype", this.recordType);
            }
          }
          // 复制时将记录id、创建人、最后修改人字段清空
          if (this.dialogAttr.type === "COPY") {
            delete data.id;
            delete data.createbyid;
            delete data.lastmodifybyid;
          }
          // 新建记录电话
          if (
            this.objectName ===
            this.$i18n.t("label.globaloperation.operationtype.record.phone")
          ) {
            data.tasktype = "logcall";
          }
          //判断是否是联系人，且是否为联系人第一次启用
          if (
            this.objectApi === "Contact" &&
            this.contactes === "contactsNew"
          ) {
            delete data.id;
            let params = data;
            getPartnerSavePartnerUser(params)
              .then((res) => {
                if (res.result) {
                  this.$emit("Refreshes", "true");
                  this.cancel();
                  this.contactes = "";
                } else {
                  this.$message.error(res.returnInfo);
                  this.cancel();
                }
              })
              .catch(() => {
                this.isSaveBtnLoadin = false;
              });
            return;
          }
          dataList.push(data);
          // return false
          let that = this;
          // 服务云_服务资源校验规则
          // relatedrecordid 用户
          // resourcetype: "班组""调度员""技术人员"
          // servicecrewid 服务班组
          if (that.objectApi === "ServiceResource") {
            // 资源类型 为班组时
            if (dataList[0].resourcetype === "班组") {
              if (dataList[0].servicecrewid) {
                dataList[0].relatedrecordid = "";
              } else if (dataList[0].relatedrecordid) {
                this.$message.error(
                  this.$i18n.t("label.service.resource.uesr.tip")
                );
                this.isSaveBtnLoadin = false;
                return false;
              }
            } else if (dataList[0].resourcetype !== "班组") {
              if (dataList[0].relatedrecordid) {
                dataList[0].servicecrewid = "";
              } else if (dataList[0].servicecrewid) {
                this.$message.error(
                  this.$i18n.t("label.service.resource.group.tip")
                );
                this.isSaveBtnLoadin = false;
                return false;
              }
            }
          }
          // 判断结束时间是否大于开始时间
          if (
            (dataList[0].begintime && dataList[0].endtime) ||
            (dataList[0].start_date && dataList[0].end_date)
          ) {
            // eslint-disable-next-line no-inner-declarations
            function tab(date1, date2) {
              let oDate1 = new Date(date1);
              let oDate2 = new Date(date2);
              if (oDate1.getTime() > oDate2.getTime()) {
                // 结束日期不得小于开始日期
                that.$message.warning(
                  that.$i18n.t("label.weixin.end.later.start")
                );
                that.isSaveBtnLoadin = false;
                return false;
              } else {
                return true;
              }
            }
            if (
              !tab(dataList[0].begintime, dataList[0].endtime) ||
              !tab(dataList[0].start_date, dataList[0].end_date)
            ) {
              return false;
            }
          }
          // 项目管理系统下，fieldType为c即金额类添加验证
          if (
            this.$store.state.home.homeApplicationId === "ace2020D253B3984Or6Q"
          ) {
            let moneyFlag = true,
              hourFlag = true,
              realHourFlag = true,
              moneyName = "",
              hourName = "",
              realHourName = "";
            this.formAttr.forEach((item) => {
              if (item.fieldType && item.fieldType === "c") {
                if (
                  dataList[0][item.apiname] &&
                  dataList[0][item.apiname].length > 10
                ) {
                  moneyName = item.fieldLabel;
                  moneyFlag = false;
                  this.isSaveBtnLoadin = false;
                }
              }
              //项目管理下任务资源工时不能超过24小时的验证
              if (
                item.apiname === "daily_working_hours" &&
                (Number(dataList[0][item.apiname]) > 24 ||
                  Number(dataList[0][item.apiname]) <= 0)
              ) {
                hourName = item.fieldLabel;
                hourFlag = false;
                this.isSaveBtnLoadin = false;
              }
              //项目管理下实际工作清单中 实际工作时长不能超过24小时的验证
              if (
                item.apiname === "working_hours_billing" &&
                (Number(dataList[0][item.apiname]) > 24 ||
                  Number(dataList[0][item.apiname]) <= 0)
              ) {
                realHourName = item.fieldLabel;
                realHourFlag = false;
                this.isSaveBtnLoadin = false;
              }
            });
            if (!moneyFlag) {
              // 最多输入10位
              this.$message.warning(
                `${moneyName}` +
                  this.$i18n.t("label.projectManagement.enter.up.to.10.digits")
              );
              return;
            }
            if (!hourFlag) {
              this.$message.warning(
                `${hourName}` +
                  this.$i18n.t("label.projectManagement.positive.integer")
              ); //请输入0-24之间的正整数
              return;
            }
            if (!realHourFlag) {
              this.$message.warning(
                `${realHourName}` +
                  this.$i18n.t("label.projectManagement.positive.integer")
              ); //请输入0-24之间的正整数
              return;
            }
          }
          if (this.objectApi == "CloudccKArticle") {
            dataList[0].recordtype = this.recordType;
            if (this.dialogAttr.type == "NEW") {
              dataList[0].new_article_type = "create";
            }
            if (this.dialogAttr.type == "COPY") {
              dataList[0].id = this.konwelgeid;
            }
          }
          // 知识文章详情页保存搜索性能词添加知识文章id
          if (this.objectApi == "ClouddCCSearchTerm") {
            dataList[0].knowledge_article = sessionStorage.getItem("id");
          }
          // 甘特图添加排序字段
          if (this.orderNumber) {
            if (this.objectApi === "cloudccMilestone") {
              dataList[0].sequence_of_milestones = this.orderNumber;
            } else {
              dataList[0].sequence_of_tasks = this.orderNumber;
            }
          }
          // 主从录入 主录入保存
          if (
            this.objectApi === "quote" ||
            this.objectApi === "cloudccorder" ||
            this.objectApi === "Invoices" ||
            this.objectApi === "cloudccpurchaseorder"
          ) {
            this.mainObjFields.forEach((main) => {
              // 保存价格手册
              dataList[0].pricebook2id = this.pricSelect;
              if (main.schemefieldName === "adjustment") {
                dataList[0].adjustment = this.mainObjData.adjustment;
              } else if (
                this.objectApi === "cloudccpurchaseorder" &&
                (main.schemefieldName === "tax" ||
                  main.schemefieldName === "taxamount")
              ) {
                dataList[0].taxamount = this.mainObjData.adjustment;
              }
              {
                dataList[0][main.schemefieldName] = main.defaultValue;
              }
            });
          }
          //主录入添加折扣类型
          dataList[0].selectdiscount = this.mainObjData.radioData.radio;
          if (dataList[0].allWorkingHours) {
            dataList[0].allWorkingHours = JSON.parse(
              dataList[0].allWorkingHours
            );
          }
          //订单发票详情页新建客户地址save接口添加订单发票id
          // if(this.isObjNewBuild && this.orderInvoiceApi == 'Invoices'){
          //    dataList[0].invoiceid = this.orderInvoiceId
          // }else if(this.isObjNewBuild && this.orderInvoiceApi == 'cloudccorder'){
          //    dataList[0].orderid = this.orderInvoiceId
          //  }
          //判断更改的是账单地址还是收货地址
          if (
            this.isObjNewBuild &&
            (this.orderInvoiceApi == "Invoices" ||
              this.orderInvoiceApi == "cloudccorder")
          ) {
            if (this.customerAddress.currentType == "shipping") {
              dataList[0].shipping = "true";
            } else if (this.customerAddress.currentType == "billing") {
              dataList[0].billing = "true";
            }
          }
          //判断当前新建是否为新建订单或发票页面再进行客户地址新建
          if (this.newCreate == "newCreate") {
            this.$Bus.$emit("deliver-customer-address", dataList);
          }
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(dataList),
            marketsea: this.pageFlag === "clientPool" ? "true" : "false", //true是公海池新建记录，false不是
          };
          //项目管理在任务列表编辑子任务保存改变子任务的objectApi
          if (
            this.$route.path.substring(this.$route.path.length - 3) == "p03"
          ) {
            if (dataList[0].their_task) {
              params.objectApi = "cloudccSubtask";
            }
          }

          // 日历页新建事件前，进行查重验证
          if (this.objectApi === "Event") {
            // 将 params 参数放实例
            this.tempParams = JSON.parse(JSON.stringify(params));
            this.tempFlag = flag;
            let {
              createEventDialogObj: { page },
            } = this;
            this.checkEventConflictFun({ page });
          } else if (
            this.dialogAttr.type == "COPY" &&
            params.objectApi == "CloudccKArticle"
          ) {
            // 作为草稿编辑
            asDraftEdit(params).then((res) => {
              this.$router.push({
                path: `/commonObjects/detail/${res.data.id}/DETAIL`,
              });
              // this.$emit('save')
              this.cancel();
            });
          } else if (this.isReceivable) {
            let total = 0;
            this.invoiceList.forEach((list) => {
              total += Number(list.moneyDetail);
            });
            if (Number(JSON.parse(params.data)[0].amount) !== total) {
              this.$message.error(this.$i18n.t("c56"));
              this.isSaveBtnLoadin = false;
              return;
            } else {
              // 保存请求
              this.saveRequest({ params, flag });
            }
          } else if (
            this.isOrderPage ||
            (this.objectApi === "Invoices" && this.orderToInvoiceId.length > 0)
          ) {
            let count = 0;
            this.dataLists[0].forEach((list) => {
              this.dataJson.forEach((item) => {
                if (list.id.value === item.id) {
                  if (
                    Number(list.quantity.value) >
                    Number(item.tobeinvoicedquantity)
                  ) {
                    this.$message.error(this.$i18n.t("c332"));
                    this.isSaveBtnLoadin = false;
                    count++;
                    return;
                  }
                }
              });
            });
            if (count === 0) {
              this.saveRequest({ params, flag });
            }
          } else {
            // 保存请求
            this.saveRequest({ params, flag });
          }
        } else {
          this.verifyJumpFn(object, true);
          // 存在验证错误，请检查填写的内容
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
        }
      });
    },
    // 定位到非必填位置
    verifyJumpFn(object, type) {
      let dom = null;
      if (type) {
        for (let i in object) {
          dom = this.$refs.baseForm.$refs[i];
          if (Object.prototype.toString.call(dom) !== "[object Object]") {
            //这里是针对遍历的情况（多个输入框），取值为数组
            dom = dom[0];
            break;
          }
        }
      } else {
        dom = object;
      }
      dom.$el.scrollIntoView(false);
    },
    // 继续创建事件
    continueCreateEvent() {
      // 按钮加载中
      this.createEventDialogObj.checkEventdialogBtnLoading = true;
      let { tempParams, tempFlag } = this;
      // 保存请求
      this.saveRequest({ params: tempParams, flag: tempFlag });
    },

    // 保存请求
    saveRequest({ params, flag }) {
      // 更改从记录入信息
      let mainData = JSON.parse(params.data);
      let ratetypeMainList = "";
      this.mainObjData.taxCheckList.forEach((el) => {
        if (el.checked) {
          let ratetypeStr = "";
          ratetypeStr = el.name + ":" + el.ratio + "%;";
          ratetypeMainList += ratetypeStr;
        }
      });
      mainData[0].ratetype = ratetypeMainList;
      let zhekouMain = "";
      // 根据税种设计
      if (this.mainObjData.radioData) {
        if (this.mainObjData.radioData.radio === "1") {
          zhekouMain = this.mainObjData.radioData.percentageValue;
        } else {
          let zongjiaValue =
            mainData[0].xiaoji ||
            mainData[0].subtotal ||
            mainData[0].totalamount ||
            mainData[0].total_amount;
          zhekouMain = (
            this.mainObjData.radioData.cutValue / zongjiaValue
          ).toString();
        }
      } else {
        zhekouMain = 0;
      }

      if (this.objectApi === "quote") {
        mainData[0].customerdiscount = zhekouMain;
      } else {
        mainData[0].discount = zhekouMain;
      }
      // 将计算好的税率和
      params.data = JSON.stringify(mainData);

      if (
        this.subordinateFields.length > 0 &&
        (this.objectApi === "quote" ||
          this.objectApi === "cloudccorder" ||
          this.objectApi === "Invoices" ||
          this.objectApi === "cloudccpurchaseorder")
      ) {
        // 更改主从录入的币种信息
        for (
          let index = 0;
          index < this.subordinateFields[0].nullObject.length;
          index++
        ) {
          let ratetypeList = "";
          this.subordinateFields[0].nullObject[index].taxCheckList.forEach(
            (el) => {
              if (el.checked) {
                let ratetypeStr = "";
                ratetypeStr = el.name + ":" + el.ratio + "%;";
                ratetypeList += ratetypeStr;
              }
            }
          );

          this.alldataists[0].datalist[index].ratetype = ratetypeList;
          let zhekou = "";
          // 根据税种设计
          if (this.subordinateFields[0].nullObject[index].radioData) {
            if (
              this.subordinateFields[0].nullObject[index].radioData.radio ===
              "1"
            ) {
              zhekou =
                this.subordinateFields[0].nullObject[index].radioData
                  .percentageValue;
            } else {
              let xiaojiValue =
                this.alldataists[0].datalist[index].xiaoji ||
                this.alldataists[0].datalist[index].amount;
              zhekou = (
                this.subordinateFields[0].nullObject[index].radioData.cutValue /
                xiaojiValue
              ).toString();
            }
          } else {
            zhekou = 0;
          }
          if (this.objectApi === "quote") {
            this.alldataists[0].datalist[index].zhekou = zhekou;
          } else {
            this.alldataists[0].datalist[index].discount = zhekou;
          }
          //传折扣选择类型字段
          this.alldataists[0].datalist[index].selectdiscount =
            this.subordinateFields[0].nullObject[index].radioData.radio === "1"
              ? "1"
              : "2";
        }
        //删除无产品的空数据
        this.alldataists[0].datalist = this.alldataists[0].datalist.filter(
          (res) =>
            res.product2id != "" &&
            res.chanpin != "" &&
            res.product != "" &&
            res.plan != ""
        );
        params.masterdata = JSON.stringify(this.alldataists);
      } else if (this.subordinateFields.length > 0) {
        params.masterdata = JSON.stringify(this.alldataists);
      }

      createApi
        .saveObjectInfo(params)
        .then((res) => {
          // this.backId = res.data[0].id ? res.data[0].id : "";
          // this.backname = res.data[0].name ? res.data[0].name : "";
          // 获取保存后个案id
          if (params.objectApi == "cloudcccase") {
            this.caseId = res.data[0].id ? res.data[0].id : "";
            this.caseName = res.data[0].name ? res.data[0].name : "";
          }
          this.isSaveBtnLoadin = false;
          if (res.data && res.data[0] && res.data[0].isSaveSuccess === "true") {
            if (this.rtnURL) {
              // window.open(this.rtnURL)
              this.$router.push({ path: this.rtnURL });
            }
            // 群策卡片
            if (
              res.data[0].cardValue &&
              res.data[0].cardValue.fieldsList.length > 0
            ) {
              let fieldsArr = [];
              res.data[0].cardValue.fieldsList.forEach((element) => {
                if (fieldsArr.length < 5) {
                  if (element.fieldType == "B") {
                    if (element.newField == "true") {
                      element.newField = this.$i18n.t("label_tabpage_yesz");
                      element.oldField = this.$i18n.t("label.checkbox.false");
                    } else if (element.oldField == "true") {
                      element.newField = this.$i18n.t("label.checkbox.false");
                      element.oldField = this.$i18n.t("label_tabpage_yesz");
                    }
                  }
                  fieldsArr.push(element);
                }
              });
              detailSendCard(res.data[0].cardValue.groupId, {
                title: this.$i18n.t(
                  "vue_label_commonobjects_detail_record_changes"
                ), //字段记录变更
                recordId: res.data[0].cardValue.id,
                type: "TRACK_CARD",
                body: fieldsArr,
                changer: localStorage.getItem("username"),
              });
            }
            if (res.data[0].cardList) {
              let self = this;
              for (let i = 0; i < res.data[0].cardList.length; i++) {
                (function (i) {
                  setTimeout(async () => {
                    let fieldsList = [];
                    if (self.objectApi == "Event" || self.objectApi == "Task") {
                      res.data[0].cardList[i].fieldsList.forEach((item) => {
                        if (
                          item.fieldValue &&
                          item.fieldValue != "true" &&
                          item.fieldValue != "false" &&
                          item.fieldValue != "0" &&
                          item.fieldValue != "1" &&
                          item.filedName != "id"
                        ) {
                          if (self.objectApi == "Task") {
                            switch (item.fieldName) {
                              case "subject":
                                fieldsList[0] = item;
                                break;
                              case "remark":
                                fieldsList[1] = item;
                                break;
                              case "belongtoid":
                                fieldsList[2] = item;
                                break;
                              case "expiredate":
                                fieldsList[3] = item;
                                break;
                              case "status":
                                fieldsList[4] = item;
                                break;
                            }
                          } else if (self.objectApi == "Event") {
                            switch (item.fieldName) {
                              case "subject":
                                fieldsList[0] = item;
                                break;
                              case "remark":
                                fieldsList[1] = item;
                                break;
                              case "belongtoid":
                                fieldsList[2] = item;
                                break;
                              case "begintime":
                                fieldsList[3] = item;
                                break;
                              case "endtime":
                                fieldsList[4] = item;
                                break;
                            }
                          }
                        }
                      });
                      fieldsList = fieldsList.filter((element) => {
                        return element != undefined;
                      });
                      if (fieldsList.length < 5) {
                        res.data[0].cardList[i].fieldsList.forEach(
                          (nextElement) => {
                            if (self.objectApi == "Task") {
                              if (
                                fieldsList.length < 5 &&
                                nextElement.fieldName != "subject" &&
                                nextElement.fieldName != "remark" &&
                                nextElement.fieldName != "belongtoid" &&
                                nextElement.fieldName != "expiredate" &&
                                nextElement.fieldName != "status" &&
                                nextElement.fieldName != "id" &&
                                nextElement.fieldValue &&
                                nextElement.fieldValue != "true" &&
                                nextElement.fieldValue != "false" &&
                                nextElement.fieldValue != "0" &&
                                nextElement.fieldValue != "1"
                              ) {
                                fieldsList.push(nextElement);
                              }
                            } else if (self.objectApi == "Event") {
                              if (
                                fieldsList.length < 5 &&
                                nextElement.fieldName != "subject" &&
                                nextElement.fieldName != "remark" &&
                                nextElement.fieldName != "belongtoid" &&
                                nextElement.fieldName != "begintime" &&
                                nextElement.fieldName != "endtime" &&
                                nextElement.fieldName != "id" &&
                                nextElement.fieldValue &&
                                nextElement.fieldValue != "true" &&
                                nextElement.fieldValue != "false" &&
                                nextElement.fieldValue != "0" &&
                                nextElement.fieldValue != "1"
                              ) {
                                fieldsList.push(nextElement);
                              }
                            }
                          }
                        );
                      }
                    } else {
                      res.data[0].cardList[i].fieldsList.forEach((item) => {
                        if (
                          fieldsList.length < 5 &&
                          item.fieldValue != null &&
                          item.fieldValue != "true" &&
                          item.fieldValue != "false" &&
                          item.fieldValue != "0" &&
                          item.fieldValue != "1" &&
                          item.filedName != "id"
                        ) {
                          fieldsList.push(item);
                        }
                      });
                    }
                    await detailSendCard(res.data[0].cardList[i].groupId, {
                      // 新建
                      title:
                        self.$i18n.t("label.ems.create") +
                        res.data[0].cardList[i].objName,
                      recordId: res.data[0].id,
                      type: "NORMAL_CARD",
                      body: fieldsList,
                      changer: localStorage.getItem("username"),
                    });
                  }, 500 * i);
                })(i);
              }
            }
            this.recordId = res.data[0].id;
            if (this.recordId !== undefined && this.recordId !== "") {
              //将发票详情页新建回款中的回款明细 保存到生成的回款单中以及发票的相关列表
              if (this.isReceivable && this.invoiceList.length !== 0) {
                let detailArray = [];
                let paramsSecond = JSON.parse(params.data);
                this.invoiceList.map((item) => {
                  let obj = {
                    //客户  发票客户名称
                    account: this.invoiceInfo.customer.id
                      ? this.invoiceInfo.customer.id
                      : "",
                    accountccname: this.invoiceInfo.customer.value
                      ? this.invoiceInfo.customer.value
                      : "",
                    //所属合同
                    contract: this.invoiceInfo.contract.id
                      ? this.invoiceInfo.contract.id
                      : "",
                    contractccname: this.invoiceInfo.contract.value
                      ? this.invoiceInfo.contract.value
                      : "",
                    //订单  发票 订单
                    cloudccorder: this.invoiceInfo.order.id
                      ? this.invoiceInfo.order.id
                      : "",
                    cloudccorderccname: this.invoiceInfo.order.value
                      ? this.invoiceInfo.order.value
                      : "",
                    //回款明细金额
                    paymentdetails: item.moneyDetail,
                    paymentmode: paramsSecond[0].paymentmode,
                    paymentdate: paramsSecond[0].paymentdate,
                    invoice: item.id,
                  };
                  obj = JSON.stringify(obj);
                  let detailObj = JSON.parse(obj);
                  detailArray.push(detailObj);
                });
                let data = [
                  {
                    datalist: detailArray,
                    objectApi: "cloudccproceeddetail",
                    relatedApiname: "payment",
                  },
                ];
                let detailObj = {
                  data: JSON.stringify(data),
                  recordId: this.recordId,
                };
                createApi.saveMaster(detailObj).then(() => {});
              }
              // 将订单详情页新建发票中的发票明细  保存到之前的订单的发票的相关列表
              if (this.isReceivable && this.invoiceList.length !== 0) {
                let detailArray = [];
                let paramsSecond = JSON.parse(params.data);
                this.invoiceList.map((item) => {
                  let obj = {
                    //客户  发票客户名称
                    account: this.invoiceInfo.customer.id
                      ? this.invoiceInfo.customer.id
                      : "",
                    accountccname: this.invoiceInfo.customer.value
                      ? this.invoiceInfo.customer.value
                      : "",
                    //所属合同
                    contract: this.invoiceInfo.contract.id
                      ? this.invoiceInfo.contract.id
                      : "",
                    contractccname: this.invoiceInfo.contract.value
                      ? this.invoiceInfo.contract.value
                      : "",
                    //订单  发票 订单
                    cloudccorder: this.invoiceInfo.order.id
                      ? this.invoiceInfo.order.id
                      : "",
                    cloudccorderccname: this.invoiceInfo.order.value
                      ? this.invoiceInfo.order.value
                      : "",
                    //回款明细金额
                    paymentdetails: item.moneyDetail,
                    paymentmode: paramsSecond[0].paymentmode,
                    paymentdate: paramsSecond[0].paymentdate,
                    invoice: item.id,
                  };
                  obj = JSON.stringify(obj);
                  let detailObj = JSON.parse(obj);
                  detailArray.push(detailObj);
                });
                let data = [
                  {
                    datalist: detailArray,
                    objectApi: "cloudccproceeddetail",
                    relatedApiname: "payment",
                  },
                ];
                let detailObj = {
                  data: JSON.stringify(data),
                  recordId: this.recordId,
                };
                createApi.saveMaster(detailObj).then(() => {});
              }
              // this.saveRelatedData(flag);
              // 处理保存接口报错信息(从对象批量新增报错信息)
              let errorMsg = "";
              res.data.forEach((item) => {
                if (item.isSaveSuccess !== "true") {
                  if (item.id && item.id != "") {
                    errorMsg += this.$i18n.t("c170", [
                      item.id,
                      item.errormessage,
                    ]);
                  } else {
                    errorMsg += item.errormessage;
                  }
                  // errorMsg += this.$i18n.t(
                  //   "vue_label_commonobjects_view_notice_deletefailreason",
                  //   [item.id, item.errormessage]
                  // );
                }
              });
              // 修改判断逻辑，解决保存调用两次save方法的问题
              if (errorMsg === "") {
                if (
                  this.isOrderPage ||
                  (this.objectApi === "Invoices" &&
                    this.orderToInvoiceId.length > 0)
                ) {
                  let orderid = this.orderToInvoiceId.join(",");
                  createApi.updateOrderStatus({ orderid }).then(() => {});
                }
                // 没有从对象或者保存成功的时候
                this.cancel();
                // 不是保存并提交时才给保存成功提示
                if (flag !== "saveandapproval") {
                  this.$message.success(this.$i18n.t("label.search.saveok"));
                  this.$emit("update");
                }
                this.$emit("save", this.recordId);
                // 保存并新建
                if (flag === "saveAndRenew") {
                  this.copyJumpDetail();
                  this.add();
                } else if (flag === "saveandapproval") {
                  // 提交待审批
                  submitApproval({ relatedId: this.recordId })
                    .then((res) => {
                      // 提交待审批成功
                      if (res.result) {
                        this.$message({
                          message: this.$i18n.t(
                            "label.webtocloudcc.submit.success"
                          ),
                          type: "success",
                        });
                        this.$router.push({
                          path: `/commonObjects/detail/${this.recordId}/DETAIL`,
                          query: {
                            objId: this.realObjId,
                          },
                        });
                      } else {
                        if (res.returnCode == "Manual") {
                          this.$router.push({
                            path: `/commonObjects/detail/${this.recordId}/DETAIL`,
                            query: {
                              objId: this.realObjId,
                              isManual: true,
                              recordId: this.recordId,
                            },
                          });
                          // TODO 弹窗填写审批信息
                        } else if (res.returnCode !== "Manual") {
                          this.errInfo = res.returnInfo;
                          this.errSubmitList = res.data || [];
                          this.failedDialog = true;
                        }
                      }
                    })
                    .catch((err) => {
                      if (err.returnCode == "Manual") {
                        // TODO 弹窗填写审批信息
                        this.$refs.nextApprovalref.IsShowdialog(
                          true,
                          this.recordId
                        );
                      } else if (err.returnCode !== "Manual") {
                        this.errInfo = err.returnInfo;
                        this.errSubmitList = err.data || [];
                        this.failedDialog = true;
                      }
                      this.copyJumpDetail();
                    });
                } else if (
                  (this.$route.query.isConsole === "true" || this.phoneemail) &&
                  params.objectApi === "cloudcccase"
                ) {
                  // 客服服务云从 服务控制台新建 跳转到新的个案详情
                  // 默认跳转至“全部”视图 aec201402480758DdbFK
                  this.$router.push({
                    path: "/controlPanel/control",
                    query: {
                      caseId: this.recordId,
                      caseOrder: res.data[0].name,
                      viewId: "aec201402480758DdbFK",
                    },
                  });
                } else if (
                  (this.type !== "active" || this.dialogAttr.type === "COPY") &&
                  !this.isTableEdit
                ) {
                  // 列表页编辑保存不跳详情
                  // 仅保存 type为active,不跳转对应详情
                  this.$router.push({
                    path: `/commonObjects/detail/${this.recordId}/DETAIL`,
                    query: {
                      objId: this.realObjId,
                    },
                  });
                }
              } else {
                this.$message.error(errorMsg);
              }
            } else {
              // 字段重复值特殊处理
              if (res.data[0].duplicatemap) {
                this.duplicate_name = res.data[0].duplicatemap.duplicate_name;
                this.duplicate_labelname =
                  res.data[0].duplicatemap.duplicate_labelname;
                this.duplicateHref = `#/commonObjects/detail/${res.data[0].duplicatemap.duplicate_id}/DETAIL`;
                this.duplicateFieldDialogAttr.isShow = true;
              } else {
                this.$message.error(res.data[0].errormessage);
              }
            }
          } else {
            if (
              res.data[0].validaterule &&
              res.data[0].validaterule == "false"
            ) {
              if (
                res.data[0].errorField == null ||
                res.data[0].errorField == ""
              ) {
                if (
                  res.data[0].errormessage.includes("illegal_field_apiname")
                ) {
                  // 值不存在或与筛选条件不匹配
                  this.$message.error(this.$i18n.t("c2420"));
                } else {
                  this.$message.error(res.data[0].errormessage);
                }
              } else {
                this.$refs.baseForm.verify(res.data);
              }
            } else {
              this.$message.error(
                res.data[0].errormessage || this.$i18n.t("chatter.save.f")
              );
            }
          }
          this.createEventDialogObj.isShowEventDialog = false;
          // this.dialogAttr.isShow = false
          // this.$router.push({
          //     path: `/commonObjects/detail/${
          //       this.recordId
          //     }/DETAIL`,
          //   });
        })
        .catch(() => {
          this.isSaveBtnLoadin = false;
        });
    },
    cancelRecordTypeDialog() {
      // this.type = "";
      this.recordTypeDialogAttr.isShow = false;
    },
    // 弹窗关闭
    cancel() {
      this.contactes = "";
      this.dialogAttr.isShow = false;
      this.isSaveBtnLoadin = false;
      this.subordinateFields = [];
      this.alldataists = [];
      this.dataLists = [];
      this.row = "";
      this.$emit("reloadgatee");
      this.$cookies.set("workinghuorss", "", { sameSite: 'Strict' });
      this.searchmanualData = [];
      this.pageNumber = 1;
    },
    // 详情
    detail(row) {
      // 查找字段跳到对应详情页
      if (row.field.schemefieldType === "Y") {
        createApi
          .getPermissionById({
            id: row.data[row.field.apiname],
          })
          .then((res) => {
            if (res.data.isQuery) {
              this.$router.push({
                path: `/commonObjects/detail/${
                  row.data[row.field.apiname]
                }/DETAIL`,
              });
            } else {
              this.$message.warning(
                "对不起，您暂无权限查看这条数据的详细信息，如有疑问请联系系统管理员"
              );
            }
          });
      } else {
        this.$router.push({
          path: `/commonObjects/detail/${row.data.id}/DETAIL`,
          query: {
            viewId: this.viewId,
          },
        });
      }
    },
    editZhuCong() {
      let params = {
        objId: this.$route.params.objId || this.objId || this.realObjId,
        recordId: this.id,
      };
      createApi.masterRecordDetailInfo(params).then((res) => {
        this.pricSelect = res.data[0].pricebook2id;
        this.pricOldSelect = this.pricSelect;
        let dataMain = res.data[0];
        this.mainObjFields.forEach((el) => {
          //从记录右下角币种问题处理

          el.defaultValue = this.changeCurrencyAmount(
            dataMain[el.schemefieldName]
          );
        });
        //获取折扣百分比字段
        let discountPrecent =
          this.objectApi === "quote" ? "customerdiscount" : "discount";
        //主记录折扣编辑回显折扣数据
        this.mainObjFields[1].radioData = {
          radio:
            dataMain.selectdiscount != null ? dataMain.selectdiscount : "2",
          percentageValue: dataMain[discountPrecent].substr(
            0,
            dataMain[discountPrecent].length - 1
          ),
          cutValue: this.mainObjFields[1].defaultValue,
        };
        let taxArr = []; //当前数据所选税种
        taxArr =
          res.data[0].ratetype != null ? res.data[0].ratetype.split(";") : [];
        taxArr.pop();
        taxArr = taxArr.map((taxItem) => {
          let obj = {
            name: taxItem.split(":")[0],
            value: taxItem.split(":")[1],
          };
          return obj;
        });
        this.taxInitCheckList.forEach((el) => {
          taxArr.forEach((item) => {
            if (
              el.name == item.name &&
              Number(el.ratio) ==
                Number(item.value.substr(0, item.value.length - 1))
            ) {
              el.checked = true;
            }
          });
        });
        this.mainObjData.taxCheckList = this.taxInitCheckList;
      });
    },
    // 获取价格手册
    getPric() {
      createApi.getEnablePriceBook().then((res) => {
        this.pricList = res.data;
        if (this.dialogAttr.type === "NEW") {
          if (this.isOrderPage && this.checkedLists.length === 0) {
            if (
              this.orderInfo.pricebook2id.id &&
              this.orderInfo.pricebook2id.id !== ""
            ) {
              this.pricSelect = this.orderInfo.pricebook2id.id;
            }
          } else {
            if (
              !(
                this.objectApi === "Invoices" &&
                this.orderToInvoiceId.length !== 0
              )
            ) {
              let flag = true;
              // 如果标准价格手册启用
              this.pricList.forEach((list) => {
                if (list.id === "01s00000000000000001") {
                  flag = false;
                  this.pricSelect = list.id;
                }
              });
              if (flag) {
                this.pricSelect = this.pricList[0].id;
              }
              this.pricOldSelect = this.pricSelect;
            }
          }
        }
      });
    },
    //主从录入
    getZhuCong(layoutId, orderId) {
      this.$store.commit("setLanguge", "");
      this.vendorid = "";
      this.tipShow = [];
      this.relatedObjectApis = [];
      this.relatedApinames = [];
      this.relatedControlContainer = [];
      this.relatedLookupValues = [];
      this.mainObjFields = [];
      this.pricSelect = "";
      (this.pricFrom = {
        pricSelect: "",
      }),
        (this.mainObjData = {
          radioData: {
            radio: "1",
            percentageValue: 0,
            cutValue: 0,
          }, // 折扣
          taxCheckList: [], //选择税种
          adjustment: 0, //调整
        });
      let params = {
        objId:
          this.objectApi != "Task" && this.objectApi != "Event"
            ? this.$route.params.objId || this.objId || this.realObjId
            : "",
        layoutId: layoutId,
      };
      if (
        this.isOrderPage ||
        (this.objectApi === "Invoices" && this.orderToInvoiceId.length !== 0)
      ) {
        if (orderId && orderId.length !== 0) {
          params.recordId = orderId.join(",");
          params.isCustomPage = "true";
        } else if (this.$route.params.id) {
          params.isCustomPage = "true";
          params.recordId = this.$route.params.id;
        } else {
          let array = [];
          this.checkedLists.forEach((item) => {
            array.push(item.id);
          });
          params.recordId = array.join(",");
          params.isCustomPage = "true";
        }
        params.objId = "invoices";
      }
      if (this.dialogAttr.type === "EDIT") {
        params.recordId = this.id;
      }
      //为客户地址新建编辑时objId参数为空
      if (this.objectApi == "cloudccustomeraddress") {
        params.objId = "";
      }
      createApi
        .getMasterInfo(params)
        .then((res) => {
          if (
            this.objectApi === "quote" ||
            this.objectApi === "cloudccorder" ||
            this.objectApi === "Invoices" ||
            this.objectApi === "cloudccpurchaseorder"
          ) {
            // 添加判断  订单生成发票没有此数据结构
            if (res.data && res.data[0]) {
              this.searchProductsId = res.data[0].fieldList[1].id; //关联的产品的字段id
            }
            //获取价格手册
            this.getPric();
            if (
              !this.isOrderPage &&
              !(
                this.objectApi === "Invoices" &&
                this.orderToInvoiceId.length !== 0
              )
            ) {
              // 去除多余折扣
              res.data[0].fieldList = res.data[0].fieldList.filter((el) => {
                return el.apiname !== "zhekou" || el.apiname !== "discount";
              });
              // 通过循环补充字段
              for (
                let index = 0;
                index < res.data[res.data.length - 2].fieldList.length;
                index++
              ) {
                let obj = {
                  id: "ffe2011000064288l5QZ",
                  name: "shuliang",
                  entityFieldName: "s_str_field3",
                  lookupObj: null,
                  lookupObjid: null,
                  label: "数量",
                  type: "N",
                  required: "0",
                  readonly: "0",
                  visible: false,
                  visiblelines: null,
                  length: "19",
                  decimalPlaces: "2",
                  helpText: "",
                  errorMsg: null,
                  selectOptions: null,
                  expressionType: null,
                  summarizedObj: null,
                  aggregateField: null,
                  hasFilter: null,
                  isenableFilter: null,
                  currentObjFields: null,
                  isContrField: false,
                  section: null,
                  typeArgs: null,
                  defaultvalue: null,
                  datafieldRef: null,
                  ismylookup: null,
                  edittype: null,
                  iscover: null,
                  childrenFieldProperty: null,
                  displayType: null,
                  parentId: null,
                  renderstyle: null,
                  showalloptions: null,
                  placeholder: "",
                  value: null,
                  data: null,
                };
                obj.id = res.data[res.data.length - 2].fieldList[index].id;
                obj.name =
                  res.data[res.data.length - 2].fieldList[index].apiname;
                obj.decimalplaces =
                  res.data[res.data.length - 2].fieldList[index].decimalplaces;
                obj.label =
                  res.data[res.data.length - 2].fieldList[index].label;
                // 取消非必要input类型
                if (
                  [
                    "xsjg",
                    "unitprice",
                    "rate",
                    "zongjia",
                    "totalamount",
                    "totalprice",
                    "total",
                  ].includes(
                    res.data[res.data.length - 2].fieldList[index].apiname
                  )
                ) {
                  obj.type = "";
                } else {
                  obj.type =
                    res.data[res.data.length - 2].fieldList[index].type;
                }
                res.data[res.data.length - 2].fieldList[index] = obj;
              }
            }
            // 如果是订单详情页  进行新建发票
            if (
              this.isOrderPage ||
              (this.objectApi === "Invoices" &&
                this.orderToInvoiceId.length !== 0)
            ) {
              this.dataJson = JSON.parse(
                JSON.stringify(res.data.orderItemsData)
              );
              res.data.orderItemsData.forEach((item) => {
                if (item.quantity) {
                  item.quantity = item.quantity.replace(/,/g, "");
                }
                if (item.quotedquantity) {
                  item.quotedquantity = item.quotedquantity.replace(/,/g, "");
                }
                if (item.tobeinvoicedquantity) {
                  item.tobeinvoicedquantity = item.tobeinvoicedquantity.replace(
                    /,/g,
                    ""
                  );
                }
              });
              res.data.masterLabel.forEach((item) => {
                item.schemefieldName = item.apiname;
                item.name = item.label;
                if (
                  item.apiname === "total_amount" ||
                  item.apiname === "total"
                ) {
                  item.value = 0.0;
                  JSON.parse(JSON.stringify(res.data.orderItemsData)).forEach(
                    (items) => {
                      if (items.totalprice.indexOf("(") !== -1) {
                        let array = items.totalprice.split("(");
                        array.forEach((list, index) => {
                          list = list.replace(")", "");
                          list = list.replace(/,/g, "");
                          array[index] = list;
                          let res = array[index].split(" ");
                          if (res[0] === this.currencyVal) {
                            items.totalprice = res[1];
                          }
                        });
                      } else {
                        items.totalprice = items.totalprice.replace(
                          this.currencyVal,
                          ""
                        );
                        items.totalprice = items.totalprice.replace(/,/g, "");
                      }
                      items.totalprice = Number(items.totalprice);
                      item.value += items.totalprice;
                    }
                  );
                  item.value = item.value.toFixed(2);
                } else {
                  item.value = 0.0;
                }
              });
              this.mainObjFields = res.data.masterLabel;
              res.data.orderItemsLabel.forEach((item) => {
                if (item.apiname === "tax") {
                  item.type = "c";
                } else if (
                  item.apiname === "unitprice" ||
                  item.apiname === "totalprice"
                ) {
                  item.type = "cc";
                } else {
                  item.type = item.fieldtype;
                }
                if (item.apiname === "quotedquantity") {
                  item.label = this.$i18n.t("c521");
                } else if (item.apiname === "quantity") {
                  item.label = this.$i18n.t("label.file.num");
                }
                if (item.apiname !== "quantity") {
                  item.disabled = true;
                } else {
                  item.disabled = false;
                }
                if (item.apiname === "description") {
                  item.disabled = false;
                }
                item.name = item.apiname;
                item.show = "false";
              });
              let linenums = Number(res.data.orderItemsData.length);
              let obj = {
                linenums,
                object: "022",
                objectApi: "InvoicesItems",
                objectId: "invoicesitems",
                relatedApiname: "invoice",
                relatedListName: this.$i18n.t(
                  "front-generate-v1-invoiceDetail"
                ),
                fieldList: res.data.orderItemsLabel,
              };
              this.$set(obj, "data", res.data.orderItemsData);
              this.subordinateFields = [];
              this.subordinateFields.push(obj);
            } else {
              this.subordinateFields = res.data;
              this.zhuCongLength = this.subordinateFields.length - 2;
              this.mainObjFields = res.data[res.data.length - 1].fields;
            }
            //设置币种
            this.$store.commit(
              "setLanguge",
              this.$refs.baseForm.formData.currency
            );
            // 筛选出多余折扣
            this.mainObjFields = this.mainObjFields.filter((el) => {
              return (
                el.schemefieldName !== "customerdiscount" &&
                el.schemefieldName !== "discount" &&
                el.schemefieldName !== "ratetype"
              );
            });
            // 设置折扣 税 默认值
            this.mainObjFields.forEach((el) => {
              if (
                el.schemefieldName === "taxamount" &&
                this.objectApi != "cloudccpurchaseorder"
              ) {
                el.schemefieldName = "tax";
              }
              if (el.value) {
                el.defaultValue = el.value;
              } else {
                el.defaultValue = 0.0;
              }
            });
            this.mainObjData = {
              radioData: {
                radio: "1",
                percentageValue: 0,
                cutValue: 0,
              },
              taxCheckList: [
                {
                  name: "",
                  id: "",
                  ratio: "",
                  checked: false,
                },
              ],
              adjustment: 0,
            };
            if (
              !this.isOrderPage &&
              !(
                this.objectApi === "Invoices" &&
                this.orderToInvoiceId.length !== 0
              )
            ) {
              this.subordinateFields.pop();
            }
            // 编辑状态回显从记录
            if (this.dialogAttr.type === "EDIT") {
              this.editZhuCong();
            }
          } else {
            this.subordinateFields = res.data;
          }
          let fileObj = {};
          let fileBasicData = {};
          // 从录入表格主体回显数据
          this.dataLists = [];
          for (let i = 0; i < this.subordinateFields.length; i++) {
            fileObj[this.subordinateFields[i].relatedApiname] = {};
            fileBasicData[this.subordinateFields[i].relatedApiname] = [];
            // 从录入表格列
            let fileArray = this.subordinateFields[i].fieldList;

            //添加地理字段
            this.addAddres(fileArray);
            let fileTemplate = {};
            fileArray.map((item) => {
              if (item.type === "FL") {
                fileObj[this.subordinateFields[i].relatedApiname][item.name] =
                  [];
                fileTemplate =
                  fileObj[this.subordinateFields[i].relatedApiname];
              }
            });

            fileTemplate = JSON.stringify(fileTemplate);
            this.fileTemplate = JSON.stringify(fileObj);
            // 业务机会阶段变化时可能性和预测类别字段跟随变化
            if (this.subordinateFields[i].objectApi === "Opportunity") {
              this.getKNXSelectValue();
            }
            this.relatedObjectApis.push(this.subordinateFields[i].objectApi);
            this.relatedApinames.push(this.subordinateFields[i].relatedApiname);
            if (
              this.dialogAttr.type === "EDIT" ||
              this.isOrderPage ||
              (this.objectApi === "Invoices" &&
                this.orderToInvoiceId.length > 0)
            ) {
              let objectData = [];
              // 循环每一个相关列表
              if (
                this.subordinateFields[i].data &&
                this.subordinateFields[i].data.length > 0
              ) {
                // 循环每一行数据 每行数据data不一定有值
                this.subordinateFields[i].data.forEach((data) => {
                  //小计字段数据库不保存。前端编辑回显计算
                  if (this.objectApi === "quote") {
                    //处理金额字段问题
                    data.xsjg = this.changeCurrencyAmount(data.xsjg);
                    data.xiaoji = this.changeCurrencyAmount(data.xiaoji);
                    data.tax = this.changeCurrencyAmount(data.tax);
                    data.zongjia = this.changeCurrencyAmount(data.zongjia);
                    data.discountamount = this.changeCurrencyAmount(
                      data.discountamount
                    );
                    data.shuliang = this.changeCurrencyAmount(data.shuliang);
                    data.xiaoji = data.xsjg * data.shuliang;
                  } else if (this.objectApi === "cloudccorder") {
                    data.amount = this.changeCurrencyAmount(data.amount);
                    data.tax = this.changeCurrencyAmount(data.tax);
                    data.totalprice = this.changeCurrencyAmount(
                      data.totalprice
                    );
                    data.unitprice = this.changeCurrencyAmount(data.unitprice);
                    data.discountamount = this.changeCurrencyAmount(
                      data.discountamount
                    );
                    data.quotedquantity = this.changeCurrencyAmount(
                      data.quotedquantity
                    );
                    data.amount = data.unitprice * data.quotedquantity;
                  } else if (this.objectApi === "cloudccpurchaseorder") {
                    data.amount = this.changeCurrencyAmount(data.amount);
                    data.taxamount = this.changeCurrencyAmount(data.taxamount);
                    data.total = this.changeCurrencyAmount(data.total);
                    data.unitprice = this.changeCurrencyAmount(data.unitprice);
                    data.discountamount = this.changeCurrencyAmount(
                      data.discountamount
                    );
                    data.quantity = this.changeCurrencyAmount(data.quantity);
                    data.amount = data.unitprice * data.quantity;
                  } else if (
                    this.objectApi === "Invoices" &&
                    !this.isOrderPage &&
                    this.orderToInvoiceId.length === 0
                  ) {
                    data.amount = this.changeCurrencyAmount(data.amount);
                    data.tax = this.changeCurrencyAmount(data.tax);
                    data.rate = this.changeCurrencyAmount(data.rate);
                    data.totalamount = this.changeCurrencyAmount(
                      data.totalamount
                    );
                    data.discountamount = this.changeCurrencyAmount(
                      data.discountamount
                    );
                    data.qty = this.changeCurrencyAmount(data.qty);
                    data.amount = data.qty * data.rate;
                  }
                  // data.productid =data.product
                  let showObj = {
                    zhekouFlg: false,
                    taxFlg: false,
                  };
                  this.tipShow.push(showObj);

                  let addData = {};
                  data = JSON.parse(JSON.stringify(data));
                  for (const key in data) {
                    this.$set(addData, key, { value: data[key] });
                    if (
                      this.isOrderPage ||
                      (this.objectApi === "Invoices" &&
                        this.orderToInvoiceId.length > 0)
                    ) {
                      if (
                        key === "discountamount" ||
                        key === "unitprice" ||
                        key === "tax"
                      ) {
                        if (data[key].indexOf("(") !== -1) {
                          let array = data[key].split("(");
                          array.forEach((item, index) => {
                            item = item.replace(")", "");
                            item = item.replace(/,/g, "");
                            array[index] = item;
                            let res = array[index].split(" ");
                            if (res[0] === this.currencyVal) {
                              data[key] = res[1];
                              this.$set(addData, key, { value: data[key] });
                            }
                          });
                        } else {
                          data[key] = data[key].replace(this.currencyVal, "");
                          data[key] = data[key].replace(/,/g, "");
                          this.$set(addData, key, { value: data[key] });
                        }
                      } else if (key === "quantity") {
                        data[key] = data.tobeinvoicedquantity;
                        this.$set(addData, key, { value: data[key] });
                      } else if (key === "invoicedquantity" || key === "tax") {
                        data[key] = data[key]
                          ? Number(data[key]).toFixed(2)
                          : "0.00";
                        this.$set(addData, key, { value: data[key] });
                      }
                      // 查找字段回显
                      if (data[`${key}ccname`]) {
                        let option = [
                          {
                            label: data[`${key}ccname`],
                            value: data[key],
                          },
                        ];
                        this.$set(addData, `${key}Options`, option);
                      }
                    } else {
                      // 查找字段回显
                      if (data[`${key}Name`]) {
                        let option = [
                          {
                            label: data[`${key}Name`],
                            value: data[key],
                          },
                        ];
                        this.$set(addData, `${key}Options`, option);
                      }
                    }
                    if (
                      this.objectApi === "quote" ||
                      this.objectApi === "cloudccorder" ||
                      this.objectApi === "Invoices" ||
                      this.objectApi === "cloudccpurchaseorder"
                    ) {
                      if (data[`${key}ccname`]) {
                        let option = [
                          {
                            label: data[`${key}ccname`],
                            value: data[key],
                          },
                        ];
                        this.$set(addData, `${key}Options`, option);
                      }
                    }
                  }

                  if (this.objectApi === "quote") {
                    addData.productid = addData.chanpin.value;
                  } else if (this.objectApi === "cloudccorder") {
                    addData.productid = addData.product2id.value;
                  } else if (this.objectApi === "cloudccpurchaseorder") {
                    addData.productid = addData.product.value;
                  } else if (this.objectApi === "Invoices") {
                    if (
                      !this.isOrderPage &&
                      this.orderToInvoiceId.length === 0
                    ) {
                      addData.productid = addData.plan.value;
                    } else {
                      addData.productid = addData.product2id.value;
                      for (let key in addData) {
                        let value;
                        if (key === "amount") {
                          value = Number(
                            Number(addData.quantity.value) *
                              Number(addData.unitprice.value)
                          ).toFixed(2);
                          this.$set(addData, key, { value });
                        } else if (key === "totalprice") {
                          value = Number(
                            Number(addData.quantity.value) *
                              Number(addData.unitprice.value) -
                              Number(addData.discountamount.value) +
                              Number(addData.tax.value)
                          ).toFixed(2);
                          this.$set(addData, key, { value });
                        } else if (
                          key === "unitprice" ||
                          key === "quantity" ||
                          key === "tax" ||
                          key === "discountamount" ||
                          key === "invoicedquantity"
                        ) {
                          value = addData[key].value.replace(" ", "");
                          this.$set(addData, key, { value });
                        }
                      }
                    }
                  }
                  if (this.isZhuCong) {
                    //获取折扣百分比字段
                    let discountPrecent =
                      this.objectApi === "quote" ? "zhekou" : "discount";
                    // 折扣对象 折扣信息
                    addData.radioData = {
                      radio:
                        data.selectdiscount != undefined &&
                        data.selectdiscount != null
                          ? data.selectdiscount
                          : "2",
                      percentageValue:
                        data[discountPrecent] != undefined
                          ? data[discountPrecent].substr(
                              0,
                              data[discountPrecent].length - 1
                            )
                          : 0,
                      cutValue: addData.discountamount.value,
                    };
                    // 折扣对象 折扣信息
                    addData.taxCheckList = this.taxInitCheckList;
                  }
                  objectData.push(addData);
                });
                // 从录入编辑赋值
                this.subordinateFields[i].nullObject = objectData;

                this.dataLists.push(objectData);
              } else {
                this.subordinateFields[i].nullObject = [];
                for (let j = 0; j < this.subordinateFields[i].linenums; j++) {
                  let obj = {};
                  obj.taxCheckList = this.taxInitCheckList;
                  obj.radioData = {
                    radio: "1",
                    percentageValue: 0,
                    cutValue: 0,
                  }; // 折扣对象 折扣信息
                  let showObj = {
                    zhekouFlg: false,
                    taxFlg: false,
                  };
                  this.subordinateFields[i].fieldList.forEach((field) => {
                    obj[field.name] = { value: "" };
                  });
                  this.tipShow.push(showObj);
                  this.subordinateFields[i].nullObject.push(obj);
                  fileBasicData[this.subordinateFields[i].relatedApiname].push(
                    JSON.parse(fileTemplate)
                  );
                }
                this.dataLists = [];
                this.dataLists.push(this.subordinateFields[i].nullObject);
              }
            } else {
              //主从录入行数根据后台设置长度展示(bug-24356)(wxr)
              this.subordinateFields[i].nullObject = [];
              for (let j = 0; j < this.subordinateFields[i].linenums; j++) {
                let obj = {};
                obj.taxCheckList = this.taxInitCheckList;
                obj.radioData = {
                  radio: "1",
                  percentageValue: 0,
                  cutValue: 0,
                }; // 折扣对象 折扣信息
                let showObj = {
                  zhekouFlg: false,
                  taxFlg: false,
                };
                this.tipShow.push(showObj);
                this.subordinateFields[i].nullObject.push(obj);
                fileBasicData[this.subordinateFields[i].relatedApiname].push(
                  JSON.parse(fileTemplate)
                );
              }
              this.dataLists.push(this.subordinateFields[i].nullObject);
            }
            for (
              let j = 0;
              j < this.subordinateFields[i].fieldList.length;
              j++
            ) {
              // 姓、名、自动编号字段不显示
              if (
                this.subordinateFields[i].fieldList[j].type === "V" ||
                this.subordinateFields[i].fieldList[j].label === "姓" ||
                this.subordinateFields[i].fieldList[j].label === "名"
              ) {
                this.subordinateFields[i].fieldList.splice(j, 1);
                j--;
              } else {
                // 重置列表数据
                for (let num = 0; num < this.dataLists[i].length; num++) {
                  if (this.dialogAttr.type !== "EDIT") {
                    if (
                      !this.isOrderPage &&
                      this.orderToInvoiceId.length === 0
                    ) {
                      this.$set(
                        this.dataLists[i][num],
                        this.subordinateFields[i].fieldList[j].name,
                        { value: "" }
                      );
                    }
                  }

                  // 选项列表、选项列表多选字段设置选项
                  if (
                    INPUTTYPE[this.subordinateFields[i].fieldList[j].type] ===
                      "select" ||
                    INPUTTYPE[this.subordinateFields[i].fieldList[j].type] ===
                      "multi-select"
                  ) {
                    this.$set(
                      this.dataLists[i][num],
                      `${this.subordinateFields[i].fieldList[j].name}Options`,
                      this.subordinateFields[i].fieldList[j].selectOptions
                    );
                  }

                  // 百分数和数字字段字数长度限制
                  if (
                    this.subordinateFields[i].fieldList[j].type === "P" ||
                    INPUTTYPE[this.subordinateFields[i].fieldList[j].type] ===
                      "number"
                  ) {
                    let decimal =
                      this.subordinateFields[i].fieldList[j].decimalPlaces !==
                        undefined &&
                      this.subordinateFields[i].fieldList[j].decimalPlaces !==
                        null
                        ? 1 /
                          Math.pow(
                            10,
                            Number(
                              this.subordinateFields[i].fieldList[j]
                                .decimalPlaces
                            )
                          )
                        : 0;
                    this.subordinateFields[i].fieldList[j].valueInterval = {
                      min:
                        0 -
                        Math.pow(
                          10,
                          this.subordinateFields[i].fieldList[j].length -
                            this.subordinateFields[i].fieldList[j]
                              .decimalPlaces -
                            1
                        ) +
                        decimal,
                      max:
                        Math.pow(
                          10,
                          this.subordinateFields[i].fieldList[j].length -
                            this.subordinateFields[i].fieldList[j]
                              .decimalPlaces -
                            1
                        ) - decimal,
                    };
                  }
                }

                // 字段依赖事件
                if (this.subordinateFields[i].fieldList[j].isContrField) {
                  this.$set(
                    this.subordinateFields[i].fieldList[j],
                    "changeEvents",
                    "control"
                  );
                }
                this.$set(
                  this.subordinateFields[i].fieldList[j],
                  "fieldType",
                  INPUTTYPE[this.subordinateFields[i].fieldList[j].type]
                );
              }
              // // 字段依赖事件
              // if (this.subordinateFields[i].fieldList[j].isContrField) {
              //   this.$set(this.subordinateFields[i].fieldList[j], "changeEvents", "control")
              // }
              // this.$set(this.subordinateFields[i].fieldList[j], "fieldType", INPUTTYPE[this.subordinateFields[i].fieldList[j].type])
            }
            // 字段依赖性
            if (this.subordinateFields[i].controlContainer != undefined) {
              this.relatedControlContainer =
                this.relatedControlContainer.concat(
                  JSON.parse(this.subordinateFields[i].controlContainer)
                );
            }

            // 查找带值，绑定事件
            if (
              this.subordinateFields[i].lookupValueExpression !== undefined &&
              this.subordinateFields[i].lookupValueExpression !== "" &&
              this.subordinateFields[i].lookupValueExpression !== null
            ) {
              this.setRelatedLookupValue(
                JSON.parse(this.subordinateFields[i].lookupValueExpression)
              );
            }
          }
          this.fileList = fileBasicData;
          // 从录入排序
          if (this.objectApi === "quote") {
            let array = [
              "name",
              "chanpin",
              "hxmms",
              "xsjg",
              "shuliang",
              "xiaoji",
              "discountamount",
              "tax",
              "zongjia",
            ];

            this.sortMainObj(array);
          } else if (this.objectApi === "cloudccorder") {
            let array = [
              "orderitemnumber",
              "product2id",
              "description",
              "unitprice",
              "quotedquantity",
              "amount",
              "discountamount",
              "tax",
              "totalprice",
            ];
            this.sortMainObj(array);
          } else if (
            this.objectApi === "Invoices" &&
            !this.isOrderPage &&
            !(
              this.objectApi === "Invoices" &&
              this.orderToInvoiceId.length !== 0
            )
          ) {
            let array = [
              "name",
              "plan",
              "description",
              "rate",
              "qty",
              "amount",
              "discountamount",
              "tax",
              "totalamount",
            ];
            this.sortMainObj(array);
          } else if (this.objectApi === "cloudccpurchaseorder") {
            let array = [
              "name",
              "product",
              "description",
              "unitprice",
              "quantity",
              "amount",
              "discountamount",
              "taxamount",
              "total",
            ];
            this.sortMainObj(array);
          }
        })
        .catch(() => {});
    },
    // 主录入排序算法
    sortMainObj(array) {
      let diffArray = [];
      let outArry = [];
      diffArray.length =
        this.subordinateFields[this.zhuCongLength].fieldList.length;
      this.subordinateFields[this.zhuCongLength].fieldList.forEach((field) => {
        if (array.includes(field.name)) {
          diffArray[
            array.findIndex((value) => {
              return value === field.name;
            })
          ] = field;
        } else if (field.name !== "zhekou" && field.name !== "discount") {
          outArry.push(field);
        }
      });
      this.subordinateFields[this.zhuCongLength].fieldList = diffArray.filter(
        (el) => {
          return el !== null;
        }
      );
      //合并非排序对象
      this.subordinateFields[this.zhuCongLength].fieldList =
        this.subordinateFields[this.zhuCongLength].fieldList.concat(outArry);
    },
    // 获取 阶段-可能性 对应关系
    getKNXSelectValue() {
      createApi
        .getSelectValue({ fieldId: "ffe201100003943eFUc8" })
        .then((res) => {
          if (res.result && res.returnCode === "1") {
            let options = [];
            res.data.forEach((item) => {
              options.push({
                val: item.codevalue,
                key: item.codekey,
                knx: Number(item.knx),
                forecasttype: item.forecasttype,
              });
            });
            this.possibilityList = options;
          } else {
            this.$message.error(res.returnInfo);
          }
        });
    },
    // 主从录入查找带值
    setRelatedLookupValue(lookupValueExpression) {
      lookupValueExpression.forEach((item) => {
        this.subordinateFields.forEach((zhucong) => {
          zhucong.fieldList.forEach((attr) => {
            if (attr.name === item.expression.split(",")[0].toLowerCase()) {
              attr.changeEvents =
                attr.changeEvent === undefined
                  ? "lookupValue"
                  : `${attr.changeEvent},lookupValue`;
            }
          });
        });
        this.relatedLookupValues.push(item);
      });
    },
    // 主从录入查找带值
    relatedLookupValue(selectVal, fieldName) {
      this.isZhucongfield = true;
      // eslint-disable-next-line no-undef
      this.getALLLookupValue(relatedLookupValues, fieldName, selectVal);
    },
    // 合并主从录入和主记录查找带值
    getALLLookupValue(lookupValueExpression, fieldName, id, formData) {
      let promiseList = [];

      // 查找字段值为空时不走带值规则
      if (id !== undefined && id !== null && id.toString() !== "") {
        for (let idx in lookupValueExpression) {
          let lookupRule = lookupValueExpression[idx];
          if (lookupRule.expression.split(",")[0].toLowerCase() === fieldName) {
            // id: 查找记录的id
            let fieldJson = [];

            fieldJson.push({
              fieldrelateid: lookupRule.id,
              id: id,
              expression: lookupRule.expression,
              iscover: lookupRule.iscover,
            });
            let param = {
              objId: this.prefix,
              fieldJson: JSON.stringify(fieldJson),
            };
            if (this.isZhucongfield) {
              // 从对象前缀
              param.objId = this.relatedObjPrefix;
            }

            promiseList.push(
              createApi.getLookupRelatedFieldValue(param).then((res) => {
                let result = {
                  res: res,
                  lookupRule: lookupRule,
                };
                return result;
              })
            );
          }
        }
        // 查找带值合并请求
        Promise.all(promiseList).then((allRes) => {
          allRes.forEach((result) => {
            let res = result.res;
            let lookupRule = result.lookupRule;
            let carryValue = "";
            carryValue =
              res.data && res.data.length > 0 ? res.data[0].value : null;
            // 区分主从录入查找带值
            if (this.isZhucongfield) {
              this.$refs.EditableCell.forEach((editableCell) => {
                if (
                  editableCell.lineIndex === this.rowIndex - 1 &&
                  editableCell.fieldId === lookupRule.id
                ) {
                  editableCell.editValue = carryValue;
                }
              });
            } else {
              // 主对象查找带值
              // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
              this.formAttr.forEach((attr) => {
                if (attr.children) {
                  attr.children.forEach((attr) => {
                    this.queryGeneraValue(
                      attr,
                      res,
                      formData,
                      lookupRule,
                      carryValue
                    );
                  });
                }
                this.queryGeneraValue(
                  attr,
                  res,
                  formData,
                  lookupRule,
                  carryValue
                );
              });
            }
          });
        });
      }
    },
    //查找代值方法封装
    queryGeneraValue(attr, res, formData, lookupRule, carryValue) {
      if (attr.fieldId === lookupRule.id) {
        // 新建时必须修改
        if (this.dialogAttr.type === "NEW" || this.dialogAttr.type === "COPY") {
          // 查找、查找多选、主详字段特殊处理
          if (
            (attr.fieldType === "Y" ||
              attr.fieldType === "MR" ||
              attr.fieldType === "M") &&
            res.data[0].value &&
            res.data[0].lkid &&
            res.data[0].value !== "" &&
            res.data[0].lkid !== ""
          ) {
            this.optionList[attr.prop] = [
              {
                value: res.data[0].lkid,
                label: res.data[0].value,
              },
            ];
          }
          // formData !== undefined表示表单数据变化触发查找带值
          if (formData !== undefined) {
            // 查找、查找多选字段特殊处理
            if (
              (attr.fieldType === "Y" || attr.fieldType === "M") &&
              res.data[0].lkid &&
              res.data[0].lkid !== ""
            ) {
              formData[attr.prop] = res.data[0].lkid;
            } else if (
              attr.fieldType === "MR" &&
              res.data[0].lkid &&
              res.data[0].lkid !== ""
            ) {
              formData[attr.prop] = [res.data[0].lkid];
            } else {
              if (
                (attr.fieldType === "P" ||
                  attr.fieldType === "N" ||
                  attr.fieldType === "c") &&
                (carryValue === "" || carryValue === null)
              ) {
                formData[attr.prop] = undefined;
              } else {
                formData[attr.prop] = carryValue;
              }
            }
          } else {
            if (
              (attr.fieldType === "Y" || attr.fieldType === "M") &&
              res.data[0].lkid &&
              res.data[0].lkid !== ""
            ) {
              attr.value = res.data[0].lkid;
              this.$refs.baseForm &&
                this.$set(
                  this.$refs.baseForm.formData,
                  attr.prop,
                  res.data[0].lkid
                );
            } else if (
              attr.fieldType === "MR" &&
              res.data[0].lkid &&
              res.data[0].lkid !== ""
            ) {
              attr.value = [res.data[0].lkid];
              this.$refs.baseForm &&
                this.$set(this.$refs.baseForm.formData, attr.prop, [
                  res.data[0].lkid,
                ]);
            } else {
              attr.value = carryValue;
              this.$refs.baseForm &&
                this.$set(this.$refs.baseForm.formData, attr.prop, carryValue);
            }
          }
        } else if (this.dialogAttr.type === "EDIT") {
          // 编辑时
          // formData !== undefined表示表单数据变化触发查找带值
          if (formData !== undefined) {
            // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
            if (
              formData[attr.prop] === "" ||
              formData[attr.prop] === null ||
              (formData[attr.prop] instanceof Array &&
                formData[attr.prop].length === 0) ||
              lookupRule.iscover === "true"
            ) {
              // 查找、查找多选字段特殊处理
              if (
                (attr.fieldType === "Y" ||
                  attr.fieldType === "MR" ||
                  attr.fieldType === "M") &&
                res.data[0].value &&
                res.data[0].lkid &&
                res.data[0].value !== "" &&
                res.data[0].lkid !== ""
              ) {
                this.optionList[attr.prop] = [
                  {
                    value: res.data[0].lkid,
                    label: res.data[0].value,
                  },
                ];
                if (res.data[0].lkid !== "") {
                  formData[attr.prop] =
                    attr.fieldType === "Y" || attr.fieldType === "M"
                      ? res.data[0].lkid
                      : [res.data[0].lkid];
                }
              } else {
                if (
                  (attr.fieldType === "P" ||
                    attr.fieldType === "N" ||
                    attr.fieldType === "c") &&
                  (carryValue === "" || carryValue === null)
                ) {
                  formData[attr.prop] = undefined;
                } else {
                  formData[attr.prop] = carryValue;
                }
              }
            }
          } else {
            // 如果要带值的字段值为空，带的值必须带过去 如果值不为空，iscover为‘true’时才带值
            if (
              attr.value === undefined ||
              attr.value === "" ||
              attr.value === null ||
              lookupRule.iscover === "true"
            ) {
              if (
                (attr.fieldType === "Y" ||
                  attr.fieldType === "MR" ||
                  attr.fieldType === "M") &&
                res.data[0].value &&
                res.data[0].lkid &&
                res.data[0].value !== "" &&
                res.data[0].lkid !== ""
              ) {
                this.optionList[attr.prop] = [
                  {
                    value: res.data[0].lkid,
                    label: res.data[0].value,
                  },
                ];
                if (res.data[0].lkid !== "") {
                  attr.value =
                    attr.fieldType === "Y" || attr.fieldType === "M"
                      ? res.data[0].lkid
                      : [res.data[0].lkid];
                  this.$refs.baseForm &&
                    this.$set(
                      this.$refs.baseForm.formData,
                      attr.apiname,
                      attr.fieldType === "Y" || attr.fieldType === "M"
                        ? res.data[0].lkid
                        : [res.data[0].lkid]
                    );
                }
              } else {
                attr.value = carryValue;
                this.$refs.baseForm &&
                  this.$set(
                    this.$refs.baseForm.formData,
                    attr.apiname,
                    carryValue
                  );
              }
            }
          }
        }

        // 带值字段如果有字段依赖、查找、动态布局规则，执行
        let changeEvent =
          attr.changeEvent !== undefined ? attr.changeEvent.split(",") : [];
        changeEvent.forEach((eventItem) => {
          if (eventItem === "control") {
            this.control(
              attr.value || this.$refs.baseForm.formData[attr.prop],
              attr.fieldId,
              this.$refs.baseForm.formData
            );
          } else if (eventItem === "lookupValue") {
            this.lookupValue(
              attr.value || this.$refs.baseForm.formData[attr.prop],
              attr,
              this.$refs.baseForm.formData
            );
          } else if (eventItem === "handleAction") {
            this.handleAction();
          }
        });
      }
    },
    // 主从录入依赖字段
    relatedControl(selectVal, lineIndex, fieldId, subordinateIndex) {
      // 查找对应规则
      let control = this.relatedControlContainer.find((item) => {
        return item.controlfield === fieldId;
      });
      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.$refs.EditableCell.forEach((editableCell) => {
              if (
                editableCell.lineIndex === lineIndex &&
                editableCell.fieldId === dependField.dependfieldid
              ) {
                this.$set(
                  this.dataLists[subordinateIndex][lineIndex],
                  [`${editableCell.fieldName}Options`],
                  []
                );
                if (
                  editableCell.fieldName &&
                  editableCell.fieldName.slice(
                    editableCell.fieldName.length - 2
                  ) === "01"
                ) {
                  // eslint-disable-next-line no-unused-vars
                  let attrField = editableCell.fieldName.slice(
                    0,
                    editableCell.fieldName.length - 2
                  );
                  // this.dataList[subordinateIndex][lineIndex][
                  //   attrField + "02"
                  // ].value = "";
                  // this.dataList[subordinateIndex][lineIndex][
                  //   attrField + "04"
                  // ].value = "";
                }
              }
            });
          });
        });

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          control.controlVals.forEach((vals) => {
            // 控制值
            vals.dependFields.forEach((dependField) => {
              this.$refs.EditableCell.forEach((editableCell) => {
                if (
                  editableCell.lineIndex === lineIndex &&
                  editableCell.fieldId === dependField.dependfieldid
                ) {
                  editableCell.editValue = "";
                  this.relatedControl(
                    "",
                    lineIndex,
                    dependField.dependfieldid,
                    subordinateIndex
                  );
                }
              });
            });
          });
        }

        // 控制条件
        control.controlVals.forEach((vals) => {
          if (vals.val === selectVal) {
            // 控制值
            vals.dependFields.forEach((dependField) => {
              this.$refs.EditableCell.forEach((editableCell) => {
                if (
                  editableCell.lineIndex === lineIndex &&
                  editableCell.fieldId === dependField.dependfieldid
                ) {
                  let options = [];
                  dependField.vals.forEach((element) => {
                    options.push({
                      label: element.val,
                      value: element.deflangval,
                    });
                  });
                  this.$set(
                    this.dataLists[subordinateIndex][lineIndex],
                    [`${editableCell.fieldName}Options`],
                    options
                  );
                  // 若当前选中值不在选项列表中，则清空
                  let option = options.find((item) => {
                    return item.value === editableCell.editValue;
                  });
                  if (option === undefined) {
                    // 清空被控字段值并递归
                    editableCell.editValue = "";
                    this.relatedControl(
                      "",
                      lineIndex,
                      dependField.dependfieldid,
                      subordinateIndex
                    );
                  }
                }
              });
            });
          }
        });
      }
    },
    //主从录入添加
    addRelatedData(ind) {
      let obj = {};
      obj.taxCheckList = this.taxInitCheckList;
      obj.radioData = {
        radio: "1",
        percentageValue: 0,
        cutValue: 0,
      }; // 折扣对象 折扣信息
      this.dataLists[ind].push(obj);
      let showObj = {
        zhekouFlg: false,
        taxFlg: false,
      };
      this.tipShow.push(showObj);
      // 文件数据对象 添加 下表为ind的相关列表push
      if (
        this.fileList &&
        this.fileList[this.subordinateFields[ind].relatedApiname]
      ) {
        this.fileList[this.subordinateFields[ind].relatedApiname].push(
          JSON.parse(this.fileTemplate)[
            this.subordinateFields[ind].relatedApiname
          ]
        );
      }
      if (this.subordinateFields[ind] != "") {
        for (let i = 0; i < this.subordinateFields[ind].fieldList.length; i++) {
          // 重置新增加行的列表数据
          this.$set(
            this.dataLists[ind][this.dataLists[ind].length - 1],
            this.subordinateFields[ind].fieldList[i].name,
            { value: "" }
          );
          if (
            this.subordinateFields[ind].fieldList[i].name === "shuliang" ||
            this.subordinateFields[ind].fieldList[i].name ===
              "quotedquantity" ||
            this.subordinateFields[ind].fieldList[i].name === "qty" ||
            this.subordinateFields[ind].fieldList[i].name === "quantity"
          ) {
            this.$set(
              this.dataLists[ind][this.dataLists[ind].length - 1],
              this.subordinateFields[ind].fieldList[i].name,
              { value: "1" }
            );
          } else if (
            this.subordinateFields[ind].fieldList[i].name ===
              "discountamount" ||
            this.subordinateFields[ind].fieldList[i].name === "tax" ||
            this.subordinateFields[ind].fieldList[i].name === "taxamount" ||
            this.subordinateFields[ind].fieldList[i].name === "rate" ||
            this.subordinateFields[ind].fieldList[i].name === "unitprice" ||
            this.subordinateFields[ind].fieldList[i].name === "zongjia" ||
            this.subordinateFields[ind].fieldList[i].name === "xsjg" ||
            this.subordinateFields[ind].fieldList[i].name === "listprice" ||
            this.subordinateFields[ind].fieldList[i].name === "total"
          ) {
            this.$set(
              this.dataLists[ind][this.dataLists[ind].length - 1],
              this.subordinateFields[ind].fieldList[i].name,
              { value: 0.0 }
            );
          }
          // 设置选项列表值
          if (
            INPUTTYPE[this.subordinateFields[ind].fieldList[i].type] ===
              "select" ||
            INPUTTYPE[this.subordinateFields[ind].fieldList[i].type] ===
              "multi-select"
          ) {
            this.$set(
              this.dataLists[ind][this.dataLists[ind].length - 1],
              `${this.subordinateFields[ind].fieldList[i].name}Options`,
              this.subordinateFields[ind].fieldList[i].selectOptions
            );
          }
        }
      }
      if (this.dataLists[ind].length >= 30) {
        this.isShowThree = false;
      }
      // 从录入重新计算
      this.mainObjCount();
      this.$forceUpdate();
      if (this.isZhuCong && this.dataLists[ind].length >= 100) {
        this.addRelatedDisabled = true; //产品到100条禁用按钮
        this.$message.warning(this.$i18n.t("c747"));
      }
    },
    //主从录入删除
    deleteUl(num, ind, type) {
      this.addRelatedDisabled = false;
      // 编辑时删除从对象记录走权限，调用删除数据接口  id不为空时表明不是新增数据，走删除接口
      if (this.dialogAttr.type === "EDIT" && this.dataLists[ind][num].id) {
        createApi
          .getPermissionById({
            id: this.dataLists[ind][num].id.value,
          })
          .then((res) => {
            if (res.returnCode === "1" && res.result) {
              if (res.data.isDelete) {
                // 删除数据
                createApi
                  .del({
                    id: this.dataLists[ind][num].id.value,
                    objectApi: this.relatedObjectApis[ind],
                  })
                  .then(() => {
                    // 主从录入可将数据全部删除
                    if (
                      this.subordinateFields[ind] !== "" &&
                      this.dataLists[ind].length > 0
                    ) {
                      this.dataLists[ind].splice(num, 1);
                      this.tipShow.splice(num, 1);
                    }
                    if (this.dataLists[ind].length < 30) {
                      this.isShowThree = true;
                    }
                  });
              }
            } else {
              this.$message.error(res.returnInfo);
            }
          });
      } else {
        // 主从录入可将数据全部删除
        if (
          this.subordinateFields[ind] !== "" &&
          this.dataLists[ind].length > 0
        ) {
          if (type) {
            if (this.dataLists[ind].length > 0) {
              this.dataLists[ind].splice(0, 1);
            }
          } else {
            this.dataLists[ind].splice(num, 1);
            if (!this.isOrderPage) {
              this.fileList[this.subordinateFields[ind].relatedApiname].splice(
                num,
                1
              );
            }
          }
        }
        if (this.dataLists[ind].length < 30) {
          this.isShowThree = true;
        }
      }
      // 从录入重新计算
      this.mainObjCount();
    },
    // 编辑值改变
    editValueChange(val, lineIndex, field, ind) {
      this.dataLists[ind][lineIndex][field] = { value: val };

      // 业务机会对象可能性随阶段变化显示对应的值
      if (
        this.relatedObjectApis[ind] === "Opportunity" &&
        field === "jieduan"
      ) {
        let knx = this.possibilityList.find((item) => {
          return item.val === val;
        });
        // 找是否存在可能性和预测类别字段
        let knxField = this.subordinateFields[ind].fieldList.find((item) => {
          return item.name === "knx";
        });
        let forecasttypeField = this.subordinateFields[ind].fieldList.find(
          (item) => {
            return item.name === "forecasttype";
          }
        );
        if (knx && knxField) {
          this.$set(this.dataLists[ind][lineIndex], "knx", {
            value: knx.knx.toString(),
          });
        }
        if (knx && forecasttypeField) {
          this.$set(this.dataLists[ind][lineIndex], "forecasttype", {
            value: knx.forecasttype,
          });
        }
      }
    },
    //获取保存信息
    savemessages() {
      this.alldataists = [];
      this.validRelatedObj = true;
      this.isRepeatTime = false; //赋初值
      for (let i = 0; i < this.dataLists.length; i++) {
        let dataArr = [];
        let drr = {};
        for (let line = 0; line < this.dataLists[i].length; line++) {
          let obj = {};
          // 编辑时保存数据id
          if (this.dataLists[i][line].id && this.dataLists[i][line].id.value) {
            obj.id = this.dataLists[i][line].id.value;
          }
          this.$refs.EditableCell &&
            this.$refs.EditableCell.forEach((editableCell) => {
              if (
                editableCell.lineIndex === line &&
                editableCell.objectApi === this.relatedObjectApis[i] &&
                editableCell.editValue !== null &&
                editableCell.editValue !== undefined
              ) {
                obj[editableCell.field] = editableCell.editValue.toString();
                if (
                  editableCell.required &&
                  (editableCell.editValue === "" ||
                    editableCell.editValue === undefined ||
                    editableCell.editValue === null)
                ) {
                  this.validRelatedObj = false;
                  this.nullElement = editableCell;
                }
              }
            });
          //存经纬度值
          obj[this.dldwName + "longitude"] =
            this.dataLists[i][line][this.dldwName + "longitude"];
          obj[this.dldwName + "latitude"] =
            this.dataLists[i][line][this.dldwName + "latitude"];
          // 准备主从录入文件传参
          // 文件字段文件  通过外层循环  找到当前主从录入数据  内层循环找到当前主从录入当前行数据
          if (JSON.stringify(this.fileList) !== "{}") {
            let fileobj =
              this.fileList[this.subordinateFields[i].relatedApiname][line];
            let array = [];
            for (let key in this.fileList) {
              array.push(key);
            }
            let findex = null;
            array.map((item, index) => {
              if (item === this.subordinateFields[i].relatedApiname) {
                findex = index;
              }
            });
            if (JSON.stringify(fileobj) !== "{}" && i === findex) {
              for (let ffkey in fileobj) {
                if (fileobj[ffkey].length !== 0) {
                  obj[ffkey] = "";
                  fileobj[ffkey].map((ffitem) => {
                    let str = ffitem.id + ",";
                    obj[ffkey] += str;
                  });
                  obj[ffkey] = obj[ffkey].substring(0, obj[ffkey].length - 1);
                }
              }
            }
          }
          if (
            this.isOrderPage ||
            (this.objectApi === "Invoices" &&
              this.orderToInvoiceId.length !== 0)
          ) {
            for (let key in obj) {
              if (key === "id") {
                delete obj[key];
              }
              if (key === "unitprice") {
                obj[key] = obj[key].replace(/,/g, "");
                obj[key] = obj[key].replace(/ /g, "");
                obj[key] = obj[key].replace(
                  this.$store.state.channel.languge ||
                    this.$store.state.userInfoObj.currencyCode,
                  ""
                );
                obj.rate = obj[key];
              } else if (key === "discountamount") {
                obj[key] = obj[key].replace(/,/g, "");
                obj[key] = obj[key].replace(/ /g, "");
                obj[key] = obj[key].replace(
                  this.$store.state.channel.languge ||
                    this.$store.state.userInfoObj.currencyCode,
                  ""
                );
              } else if (key === "totalprice") {
                obj[key] = obj[key].replace(/,/g, "");
                obj[key] = obj[key].replace(/ /g, "");
                obj[key] = obj[key].replace(
                  this.$store.state.channel.languge ||
                    this.$store.state.userInfoObj.currencyCode,
                  ""
                );
                obj.totalamount = obj[key];
              } else if (key === "product2id") {
                obj.plan = obj[key];
              } else if (key === "quantity") {
                if (
                  Number(obj[key]) ===
                  Number(obj.quotedquantity) - Number(obj.invoicedquantity)
                ) {
                  obj.invoiceStatus = true;
                } else {
                  obj.invoiceStatus = false;
                }
                obj.qty = obj[key];
              }
            }
            obj.totalamount = obj.totalprice;
            obj.orderitem = this.subordinateFields[i].data[line].id;
            obj.ordernumber =
              this.subordinateFields[i].data[line].orderidccname;
            obj.orderitemccname = this.subordinateFields[i].data[line].name;
          }
          dataArr.push(obj);
        }
        // 判断是否有重复时间段
        for (let i = 0; i < dataArr.length; i++) {
          for (let j = i + 1; j < dataArr.length; j++) {
            // 先判断是否有重复的日期
            if (dataArr[i].dayofweek == dataArr[j].dayofweek) {
              // 有，则判断时间段
              let repeatDataArr = [dataArr[i], dataArr[j]];
              let timeArr = [];
              for (let n in repeatDataArr) {
                let obj = {};
                obj.startPeriod = repeatDataArr[n].starttime;
                obj.endPeriod = repeatDataArr[n].endtime;
                timeArr.push(obj);
              }
              this.isRepeatTime = this.isHasRepeatTime(timeArr); //结果赋值给判断变量
            }
          }
        }
        if (
          this.objectApi === "quote" ||
          this.objectApi === "cloudccorder" ||
          this.objectApi === "Invoices" ||
          this.objectApi === "cloudccpurchaseorder"
        ) {
          dataArr.forEach((list) => {
            list.currency = this.currencyVal;
          });
        }
        this.row += dataArr.length;
        drr.datalist = JSON.parse(JSON.stringify(dataArr));
        drr.objectApi = this.relatedObjectApis[i];
        drr.relatedApiname = this.relatedApinames[i];
        this.alldataists.push(drr);
      }
    },
    // 判断重复时间段
    isHasRepeatTime(data) {
      const startTimeArr = [];
      const endTimeArr = [];
      (data || []).map(function (item) {
        startTimeArr.push(item.startPeriod);
        endTimeArr.push(item.endPeriod);
      });
      const allStartTime = startTimeArr.sort();
      const allEndTime = endTimeArr.sort();
      let result = 0;
      for (let k = 1; k < allStartTime.length; k++) {
        if (allStartTime[k] < allEndTime[k - 1]) {
          result += 1;
        }
      }
      return result > 0;
    },
    async xiaoshou() {
      let params = {
        obj: this.getViewListDataParams.obj,
        viewId: this.$route.params.viewId,
      };
      let res = await createApi.getViewListRecently(params);
      if (res.result) {
        window.location.href = res.data.toolsBtn[0].url;
      }
    },
    fwbContent(val) {
      this.fwb = val;
    },
    // 新建事件前重复检查弹窗分页事件
    clickPage(currentPage) {
      this.checkEventConflictFun({ page: currentPage });
    },
    //  日历页新建事件前，进行查重验证
    checkEventConflictFun({ page }) {
      let { tempParams, tempFlag } = this;
      let {
        createEventDialogObj: { pagesize },
      } = this;
      tempParams = {
        ...tempParams,
        page,
        pagesize,
      };

      createApi.checkEventConflict(tempParams).then((res) => {
        if (res.data && res.data.code === "400") {
          let { data, totalCount } = res.data;
          this.createEventDialogObj = {
            ...this.createEventDialogObj,
            isShowEventDialog: true,
            dataArr: data,
            totalCount,
            checkEventdialogBtnLoading: false,
          };
        } else {
          // 保存请求
          this.saveRequest({ params: tempParams, flag: tempFlag });
        }
      });
    },
    //复制跳转详情页
    copyJumpDetail() {
      if (this.dialogAttr.type === "COPY") {
        this.$router.push({
          path: `/commonObjects/detail/${this.recordId}/DETAIL`,
          query: {
            objId: this.realObjId,
          },
        });
      }
    },
    //添加地理定位
    addAddres(arr) {
      let newAdress = {
        label: this.$i18n.t("LT"), //地理定位
        name: "dldw", //地理定位
        id: "1",
      };
      let countryIndex = "";
      let isAddress = false;
      arr.forEach((val, index) => {
        if (
          val.name &&
          val.name !== "name00" &&
          val.name.slice(val.name.length - 2) === "00"
        ) {
          isAddress = true;
          countryIndex = index;
          this.dldwName = val.name.slice(0, val.name.length - 2);
          newAdress.readonly = val.readonly;
        }
      });
      if (isAddress) {
        arr.splice(countryIndex, 0, newAdress);
      }
    },
    mapBtnChange(e) {
      if (e === "gd") {
        this.isMap = true;
        this.mapFun();
      } else {
        this.isMap = false;
        this.mapFun();
      }
    },
    //显示地图
    bigMapBtn(item, num, ind) {
      this.lineIndex = num;
      this.clearItemArr = item;
      this.allInd = ind;
      this.bigMapDialog = true;
      if (
        this.clearItemArr[this.dldwName + "00"] &&
        this.clearItemArr[this.dldwName + "00"].value
      ) {
        if (
          this.clearItemArr[this.dldwName + "00"].value ===
            this.$i18n.t("label.china") ||
          this.clearItemArr[this.dldwName + "00"].value ===
            this.$i18n.t("label.the.peoples.republic.of.china") ||
          this.clearItemArr[this.dldwName + "00"] === "China"
        ) {
          this.isMap = true;
          this.mapFun();
        } else {
          this.isMap = false;
          this.mapFun();
        }
      } else {
        if (this.countryCode === "CN") {
          this.isMap = true;
          this.mapFun();
        } else {
          this.isMap = false;
          this.mapFun();
        }
      }
    },
    //地图初始化
    mapFun() {
      if (this.isMap) {
        if (this.dialogAttr.type === "EDIT") {
          if (
            (this.clearItemArr[this.dldwName + "00"].value &&
              this.clearItemArr[this.dldwName + "00"].value ===
                this.$i18n.t("label.china")) ||
            this.clearItemArr[this.dldwName + "00"].value ===
              this.$i18n.t("label.the.peoples.republic.of.china") ||
            this.clearItemArr[this.dldwName + "00"].value === "China"
          ) {
            if (this.dldwName !== undefined) {
              let lon = Number(
                this.clearItemArr[this.dldwName + "longitude"].value
              );
              let lat = Number(
                this.clearItemArr[this.dldwName + "latitude"].value
              );
              this.clearItemArr.gdPointValue = [lon, lat];
            }
          } else {
            this.clearItemArr.gdPointValue = [];
          }
        }
        this.mapkey = window.Glod["AMAP_KEY"];
      } else {
        if (this.dialogAttr.type === "EDIT") {
          if (
            this.clearItemArr[this.dldwName + "00"] &&
            this.clearItemArr[this.dldwName + "00"] !==
              this.$i18n.t("label.china") &&
            this.clearItemArr[this.dldwName + "00"] !==
              this.$i18n.t("label.the.peoples.republic.of.china") &&
            this.clearItemArr[this.dldwName + "00"] !== "China" &&
            this.clearItemArr[this.dldwName + "longitude"] &&
            this.clearItemArr[this.dldwName + "latitude"]
          ) {
            let lon = Number(
              this.clearItemArr[this.dldwName + "longitude"].value
            );
            let lat = Number(
              this.clearItemArr[this.dldwName + "latitude"].value
            );
            this.clearItemArr.ggPointValue = {
              lat: lon,
              lng: lat,
            };
          } else {
            this.clearItemArr.ggPointValue = {};
          }
        }
        this.mapkey = "AIzaSyCyWXGrhpOd1py9eUCpzRAzbDjWtXQNqGU";
      }
      this.$nextTick(() => {
        this.$refs.mapGroup.init();
        this.$refs.mapGroup.searchKey = "";
        this.$refs.mapGroup.searchList = [];
      });
    },
    //关闭地图
    bigClose() {
      this.bigMapDialog = false;
    },
    //地图返回信息
    chooseLocation(allAdd, obj) {
      this.bigMapDialog = false;
      //CN 高德地图 ，海外谷歌
      if (this.isMap) {
        this.subordinateFields[this.allInd].fieldList.forEach((val) => {
          if (val.name === this.dldwName + "00") {
            this.relatedControl(
              allAdd.addressComponent.country,
              this.lineIndex,
              val.id,
              this.allInd
            );
          }
        });
        let itemList = this.dataLists[this.allInd][this.lineIndex];
        itemList.gdPointValue = obj;
        itemList[this.dldwName + "00"].value = allAdd.addressComponent.country;
        itemList[this.dldwName + "01"].value = allAdd.addressComponent.province;
        itemList[this.dldwName + "02"].value = allAdd.addressComponent.city;
        itemList[this.dldwName + "04"].value = allAdd.formattedAddress;
        itemList[this.dldwName + "longitude"] = obj[0].toString();
        itemList[this.dldwName + "latitude"] = obj[1].toString();
      } else {
        this.$nextTick(() => {
          let arrValue = allAdd.address_components;
          let itemList = this.dataLists[this.allInd][this.lineIndex];
          itemList.ggPointValue = allAdd.geometry.location;
          let allAddLength = "";
          allAdd.address_components.forEach((val, index) => {
            //如果是美国/加拿大/澳大利亚/墨西哥/英国/新加坡/法国/德国/的话找对应下标赋值
            if (
              val.short_name === "US" ||
              val.short_name === "CA" ||
              val.short_name === "AU" ||
              val.short_name === "MX" ||
              val.short_name === "GB" ||
              val.short_name === "SG" ||
              val.short_name === "FR" ||
              val.short_name === "DE"
            ) {
              allAddLength = index;
            }
          });
          this.subordinateFields[this.allInd].fieldList.forEach((val) => {
            if (val.name === this.dldwName + "00") {
              this.relatedControl(
                arrValue[allAddLength].long_name,
                this.lineIndex,
                val.id,
                this.allInd
              );
            }
          });
          itemList[this.dldwName + "00"].value =
            arrValue[allAddLength].long_name;
          itemList[this.dldwName + "01"].value =
            arrValue[allAddLength - 1].long_name;
          itemList[this.dldwName + "02"].value =
            arrValue[allAddLength - 2].long_name;
          itemList[this.dldwName + "04"].value = allAdd.formatted_address;
          itemList[this.dldwName + "longitude"] =
            allAdd.geometry.location.lat.toString();
          itemList[this.dldwName + "latitude"] =
            allAdd.geometry.location.lng.toString();
        });
      }
    },
    //清空地图信息
    clearLocation() {
      this.bigMapDialog = false;
      let itemList = this.dataLists[this.allInd][this.lineIndex];
      itemList.gdPointValue = [];
      itemList.ggPointValue = {};
      itemList[this.dldwName + "00"].value = "";
      itemList[this.dldwName + "01"].value = "";
      itemList[this.dldwName + "02"].value = "";
      itemList[this.dldwName + "04"].value = "";
    },
    //金额币种格式处理
    changeCurrencyAmount(data) {
      if (data.indexOf("(") !== -1) {
        let array = data.split("(");
        array.forEach((item, index) => {
          item = item.replace(")", "");
          item = item.replace(/,/g, "");
          array[index] = item;
          let res = array[index].split(" ");
          if (
            res[0] ===
            (this.$store.state.channel.languge ||
              this.$store.state.userInfoObj.currencyCode)
          ) {
            data = res[1];
          }
        });
      } else {
        data = data.replace(
          this.$store.state.channel.languge ||
            this.$store.state.userInfoObj.currencyCode,
          ""
        );
        data = data.replace(/,/g, "");
      }
      return data.trim();
    },
    //接收从录入模糊搜索数据
    async remoteMethodCell(val) {
      this.keywords = val;
      // this.searchProducts()
      let data = {
        pricebookId: this.pricSelect,
        conditonstr: "", //条件
        keywords: this.keywords, //关键字
        page: this.pageNumber,
        pageSize: 100,
        currency: this.currencyVal,
        vendorid: this.$refs.baseForm.formData.vendorname,
        fieldid: this.searchProductsId,
      };
      let res = await createApi.searchProducts(data);
      if (res.result) {
        this.arrayLength = Number(res.data.dataList.length);
        this.searchmanualData = res.data.dataList;
      }
    },
  },
  beforeDestroy() {
    Bus.$off("windowResize", this.ebFn);
    window.removeEventListener("resize", this.windowResizeFn);
    localStorage.setItem("relevantObjectLevel", 0);
  },
  computed: {
    createEventDialogInnerPageFormat() {
      let {
        createEventDialogObj: { pagesize, totalCount },
      } = this;
      return Math.ceil(totalCount / pagesize);
    },
    //判断是否是添加产品对象
    isZhuCong() {
      return (
        this.objectApi === "quote" ||
        this.objectApi === "cloudccorder" ||
        this.objectApi === "Invoices" ||
        this.objectApi === "cloudccpurchaseorder"
      );
    },
  },
  watch: {
    pricSelect(val) {
      if (val) {
        let name = "";
        this.pricList.forEach((item) => {
          if (item.id === val) {
            name = item.name;
            this.selectPrice = item.name;
          }
        });
        this.formAttr.forEach((attr) => {
          if (attr.apiname === "pricebook2id") {
            this.$refs.baseForm.formData["pricebook2id"] = val;
            this.$set(this.optionList, attr.prop, [
              { value: val, label: name },
            ]);
          }
        });
      } else {
        this.formAttr.forEach((attr) => {
          if (attr.apiname === "pricebook2id") {
            this.$refs.baseForm.formData["pricebook2id"] = "";
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
/* 新建编辑弹窗最小宽度600px */
.create-edit-dialog {
  min-width: 600px;
  // 层级最高
  // z-index: 99999 !important
}
</style>

<style lang="scss" scoped>
.pricBox {
  float: right;
}
.currencyBox {
  margin-left: 10px;
}
::v-deep .remoteBtn1 {
  cursor: pointer;
  position: absolute;
  right: 7px;
  color: #c0c4cc;
  z-index: 100;
  top: 5px;
  font-size: 14px;
}
::v-deep .el-dialog__header,
::v-deep .el-dialog__footer {
  padding: 15px 20px;
}
::v-deep .el-input {
  .el-input__inner {
    height: 32px !important;
    overflow-x: auto;
    color: #080707;
  }
}
::v-deep .el-select-dropdown__item {
  color: #080707;
}
.detailContentBox {
  margin-top: -30px;
  ::v-deep .el-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }
  ::v-deep .el-form-item__label {
    width: 100% !important;
    padding-left: 12px;
  }
  ::v-deep .el-form-item__content {
    margin-left: 12px !important;
    width: 90%;
    line-height: 32px;
  }
  ::v-deep .el-select {
    padding-left: 0px !important;
  }
  ::v-deep .el-form-item__error {
    // display: none;
    // top: 30%;
    left: 2%;
  }
  ::v-deep .groupTitle {
    margin: 12px 0px 0px 0px;
    // height: 36px;
    line-height: 36px;
    font-size: 14px;
    padding-left: 12px;
    background: #f3f7ff;
    color: #080707;
    font-weight: bold;
    border-radius: 4px;
    font {
      display: contents;
    }
  }
}
.setColumnsIcon {
  width: 16px;
  height: 28px;
  margin-left: 8px;
}

.autoColumnsIcon {
  width: 16px;
  margin-left: 7px;
}

.groupTitle {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 10px;
  background: #f3f7ff;
}

.selectnum {
  height: 12px;
  font-size: 12px;
  margin-top: -20px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}

.relatedListName {
  margin: 12px 0px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  padding-left: 12px;
  background: #f3f7ff;
  color: #080707;
  border-radius: 4px;
}

::v-deep .el-input-number {
  width: 100%;
  line-height: 32px;
  .el-input__inner {
    text-align: left;
  }
}

.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;

  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}

.editableCell {
  height: 40px;
}

::v-deep .changeColumnBtnGroup {
  position: absolute;
  top: 12px;
  right: 43px;
  height: 30px;
  line-height: 30px;
  float: left;
  border: 1px solid #dedcda;
  border-radius: 3px;

  li {
    float: left;
    width: 30px;
    height: 30px;
    padding-top: 1px;

    img {
      margin-top: -2px;
    }
  }
}

/*批量新增css*/
.tank {
  height: 480px;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}

.listulFir {
  height: 40px;
  min-width: 100%;
  color: #2a2a2a;
  border: 1px solid #d8d8d8;
  border-right: none;
  border-left: none;
  display: inline-flex;
  background: #fafaf9;
  position: relative;
}
.listulSec:nth-of-type(2n) {
  min-height: 40px;
  display: inline-flex;
  min-width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
.listulSec:nth-of-type(2n + 1) {
  min-height: 40px;
  display: inline-flex;
  background: white;
  min-width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
.firstLi {
  color: #006dcc;
  border-right: 1px solid#D8D8D8;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
}
.secondLi {
  border-right: 1px solid #d8d8d8;
  text-align: center;
  width: 180px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  ::v-deep .is-without-controls {
    margin-top: 0px;
  }
  .uploadFile {
    width: 95%;
    padding-top: 8px;
    color: #b6b6b6;
    cursor: pointer;
    text-align: left;
    padding-left: 10px;
  }
  .fileContent {
    width: 90%;
    height: 95%;
    color: #b6b6b6;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding-bottom: 0px;
    .fileItem {
      width: 150px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .itemFir {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .itemTitle {
          display: flex;
          color: #6d7f9a;
          align-items: center;
          .name {
            max-width: 83px;
            word-wrap: break-word;
            height: 16px;
            overflow: hidden;
          }
          .delIcon {
            width: 15px;
            height: 15px;
            position: absolute;
            right: 4px;
            cursor: pointer;
            margin-left: 4px;
          }
        }
      }
      .itemSec {
        cursor: pointer;
        margin-top: 5px;
        text-align: left;
        height: 20px;
        line-height: 20px;
      }
    }
  }
  ::v-deep .big_map {
    padding: 12px 10px;
    line-height: 1px;
    margin-top: 3px;
    width: 80px;
    span {
      font-size: 12px !important;
    }
  }

  // 从对象表格复选框样式
  ::v-deep .editableCell .el-checkbox {
    line-height: 30px;
    margin-left: 10px;
  }
}

::v-deep .el-input {
  // 这里不能设置最小高度
  // min-height: 40px;
  width: 100%;
  text-align: center;
}
::v-deep .el-input__inner {
  min-height: 32px;
  width: 100%;
  font-size: 12px;
}
::v-deep .el-button--mini {
  padding: 7px 7px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
  line-height: auto;
  line-height: 32px;
}
.al {
  width: 100%;
  // overflow: auto;
  border-top: none;
  border-bottom: none;
  overflow-y: auto;
}
// 此样式会影响日期图标位置，暂时注释
// ::v-deep .el-input__icon {
//   margin-top: -4px;
// }
.tianjia {
  margin-top: 20px;
  cursor: pointer;
  width: 32px;
  margin-bottom: 10px;
  margin-left: 16px;
}
ul {
  margin-bottom: 0;
}

.requiredIdentification {
  color: red;
  position: absolute;
  left: -1px;
  top: -3px;
}
.page_up {
  margin-top: 5px;
}
.overflow_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.font_weight {
  font-weight: 600;
}
.content_wrapper_title {
  margin-bottom: 5px;
}
.mainObjCount {
  float: right;
  margin-top: -270px;
  margin-right: 10px;
}
::v-deep .big-view-box {
  .el-dialog__header {
    padding: 0 !important;
    .el-dialog__headerbtn {
      display: none;
    }
  }
  .el-dialog__body {
    padding: 0 !important;
    .bm-view-big {
      width: 100%;
      height: 550px;
    }
    .btn_map {
      position: absolute;
      top: 16px;
      right: 90px;
      z-index: 9;
      button {
        height: 44px;
      }
    }
  }
}
</style>
